import React, {createRef} from "react";
import {Button, ButtonType, Dropdown, DropdownAlign, DropdownOrderBy, Form, IconSize, Inline, Modal, SelectListItem, Spinner, ToolbarContainer} from "@renta-apps/athenaeum-react-components";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import Warehouse from "@/models/server/Warehouse";
import {CalendarUnitTime, TaskStatusFilter} from "@/models/Enums";
import ConstructionSite from "@/models/server/ConstructionSite";
import BaseCalendarDataRequest from "@/pages/Calendar/BaseCalendarDataRequest";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import CalendarFiltersData from "@/pages/Calendar/Toolbar/CalendarFiltersData";
import WorkOrderSearchInput from "@/components/WorkOrderSearchInput/WorkOrderSearchInput";
import CostPool from "@/models/server/CostPool";
import User from "@/models/server/User";
import Debouncer from "@/helpers/Debouncer";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "@/localization/Localizer";
import UnleashHelper from "@/helpers/UnleashHelper";
import FeatureFlags from "@/helpers/FeatureFlags";

import styles from "./Toolbar.module.scss";

interface ICalendarToolbarProps {
    filtersData: CalendarFiltersData;
    request: BaseCalendarDataRequest;
    selectedCalendarTimeUnit: CalendarUnitTime;
    startDate: Date;

    /**
     * Invoked when the user wants to create a new Work Order
     */
    onAddNewWorkOrder(): Promise<void>;

    changeData(type: "prev" | "next" | "today"): Promise<void>;

    updateSelectedCalendarTimeUnit(selectedTimeUnit: CalendarUnitTime): Promise<void>

    onChange(request: BaseCalendarDataRequest): Promise<void>;

    // openAddTaskModal(sender: IBaseComponent): Promise<void>;
    // openTeamModal(sender: IBaseComponent, team: Team | null): Promise<void>;
}

interface ICalendarToolbarState {
}

export default class CalendarToolbar extends BaseComponent<ICalendarToolbarProps, ICalendarToolbarState> {

    // Fields

    public state: ICalendarToolbarState = {};

    private readonly _warehousesRef: React.RefObject<Dropdown<Warehouse>> = React.createRef();
    private readonly _constructionSitesRef: React.RefObject<Dropdown<ConstructionSite>> = React.createRef();
    private readonly _legendModalRef: React.RefObject<Modal> = React.createRef();
    private readonly _searchInputRef: React.RefObject<WorkOrderSearchInput> = createRef();
    private readonly _searchDebouncer: Debouncer = new Debouncer();

    // Properties

    private get request(): BaseCalendarDataRequest {
        return this.props.request;
    }

    private get warehouses(): Warehouse[] {
        return this.props.filtersData.warehouses;
    }

    private get costPools(): CostPool[] {
        return this.props.filtersData.costPools;
    }

    private get constructionSites(): ConstructionSite[] {
        return this.props.filtersData.constructionSites;
    }

    private get managers(): User[] {
        return this.props.filtersData.managers;
    }

    private get mounters(): User[] {
        return this.props.filtersData.mounters;
    }

    private get selectedWarehouses(): Warehouse[] {
        return this.request.warehouses;
    }

    private get selectedConstructionSites(): ConstructionSite[] {
        return this.request.constructionSites;
    }

    private get selectedMounterCostPools(): CostPool[] {
        return this.request.mounterCostPools;
    }

    private get selectedMounters(): User[] {
        return this.request.mounters;
    }

    private get selectedManagers(): User[] {
        return this.request.managers;
    }

    // Methods

    private async updateFiltersRequestAsync(
        warehouses: Warehouse[] | null,
        constructionSites: ConstructionSite[] | null,
        statuses: TaskStatusFilter[] | null,
        mounterCostPools: CostPool[] | null,
        search: string | null,
        managers: User[] | null,
        mounters: User[] | null): Promise<void> {
        const request: BaseCalendarDataRequest = {
            warehouses: warehouses ?? this.props.request.warehouses,
            constructionSites: constructionSites ?? this.props.request.constructionSites,
            statuses: statuses ?? this.props.request.statuses,
            mounterCostPools: mounterCostPools ?? this.props.request.mounterCostPools,
            search: search ?? this.props.request.search,
            managers: managers ?? this.props.request.managers,
            mounters: mounters ?? this.props.request.mounters
        };

        await this.props.onChange(request);
    }

    private async selectWarehousesAsync(warehouses: Warehouse[]): Promise<void> {
        await this.updateFiltersRequestAsync(warehouses, null, null, null, null, null, null);
    }

    private async selectConstructionSitesAsync(constructionSites: ConstructionSite[]): Promise<void> {
        await this.updateFiltersRequestAsync(null, constructionSites, null, null, null, null, null);
    }

    private async selectMounterCostPoolsAsync(costPools: CostPool[]): Promise<void> {
        const mounters: User[] | null = UnleashHelper.isEnabled(FeatureFlags.CostPoolMounterAutoSelect)
            ? User.getWithCostPools(this.mounters, costPools)
            : null;
        
        await this.updateFiltersRequestAsync(null, null, null, costPools, null, null, mounters);
    }


    private async setTaskStatusFilterAsync(sender: Dropdown<SelectListItem>): Promise<void> {
        const statuses: TaskStatusFilter[] = sender.selectedItems.map(item => parseInt(item.value));
        await this.updateFiltersRequestAsync(null, null, statuses, null, null, null, null);
    }

    private async onManagersChangeAsync(managers: User[]): Promise<void> {
        await this.updateFiltersRequestAsync(null, null, null, null, null, managers, null);
    }

    private async onMountersChangeAsync(mounters: User[]): Promise<void> {
        await this.updateFiltersRequestAsync(null, null, null, null, null, null, mounters);
    }

    private async setCalendarUnitTime(item: SelectListItem): Promise<void> {
        const unit: CalendarUnitTime = parseInt(item.value)
        await this.props.updateSelectedCalendarTimeUnit(unit);
    }

    private onSearchChangeAsync = async (search: string): Promise<void> => {
        this._searchDebouncer.debounce(
            async () => {
                await this.updateFiltersRequestAsync(null, null, null, null, search, null, null);
            },
            500
        );
    }

    private async clearAsync(): Promise<void> {
        await this.props.onChange(new BaseCalendarDataRequest());
    }

    private openLegendAsync = async (): Promise<void> => {
        await this._legendModalRef.current?.openAsync();
    }

    public hasSpinner(): boolean {
        return true;
    }

    public render(): React.ReactNode {
        return (
            <ToolbarContainer className={styles.toolbar}>
                {
                    (this.isSpinning()) && <Spinner/>
                }

                <Form>
                    {
                        (this.props.selectedCalendarTimeUnit == CalendarUnitTime.Week) ?
                            (
                                <h6>{Localizer.enumCalendarUnitTimeWeek}: {Utility.getISOWeekNumber(this.props.startDate)}</h6>
                            )
                            :
                            (
                                <h6>{Localizer.enumCalendarUnitTimeMonth}: {Utility.getMonth(this.props.startDate)}</h6>
                            )
                    }
                    <Inline>
                        <Button icon={{name: "arrow-left", size: IconSize.Large}}
                                type={ButtonType.Orange}
                                onClick={async () => this.props.changeData("prev")}
                        />

                        <Button icon={{name: "arrow-right", size: IconSize.Large}}
                                type={ButtonType.Orange}
                                onClick={async () => this.props.changeData("next")}
                        />

                        <Button id="calendarTodayButton"
                                label={Localizer.calendarPageToolbarTodayButtonLabel}
                                type={ButtonType.Orange}
                                onClick={async () => this.props.changeData("today")}
                        />

                        <Dropdown
                            className={styles.viewTopMargin}
                            required small noWrap noFilter
                            orderBy={DropdownOrderBy.None}
                            items={EnumProvider.getCalendarUnitTimeItems()}
                            label={Localizer.calendarPageToolbarViewLabel}
                            onChange={async (_, item) => await this.setCalendarUnitTime(item!)}
                        />

                        <Button small
                                icon={{name: "question", size: IconSize.Large}}
                                label={Localizer.genericLegend}
                                onClick={this.openLegendAsync}
                        />

                        <Button small
                                label={Localizer.genericWorkOrderNew}
                                title={Localizer.genericWorkOrderNew}
                                icon={{name: "plus", size: IconSize.Large}}
                                type={ButtonType.Primary}
                                onClick={this.props.onAddNewWorkOrder}
                        />
                    </Inline>
                    <Inline>
                        <WorkOrderSearchInput ref={this._searchInputRef}
                                              label={Localizer.tasksToolbarSearch}
                                              width={"40ch"}
                                              value={this.request.search ?? ""}
                                              onChange={this.onSearchChangeAsync}
                        />

                        <Dropdown small multiple autoGroupSelected
                                  ref={this._warehousesRef}
                                  minWidth={200}
                                  items={this.warehouses}
                                  label={Localizer.genericWarehouse}
                                  selectedItems={this.selectedWarehouses}
                                  onChange={async (sender) => await this.selectWarehousesAsync(sender.selectedItems)}
                        />

                        <Dropdown small multiple autoGroupSelected
                                  ref={this._constructionSitesRef}
                                  minWidth={200}
                                  items={this.constructionSites}
                                  label={Localizer.genericConstructionsite}
                                  selectedItems={this.selectedConstructionSites}
                                  onChange={async (sender) => await this.selectConstructionSitesAsync(sender.selectedItems)}
                        />

                        <Dropdown small noValidate noSubtext noWrap noFilter multiple
                                  align={DropdownAlign.Left}
                                  label={Localizer.tasksPageTaskStatus}
                                  minWidth={180}
                                  orderBy={DropdownOrderBy.None}
                                  items={EnumProvider.getTaskStatusFilterItems()}
                                  selectedItems={this.request.statuses ?? []}
                                  onChange={async (sender: Dropdown<SelectListItem>, _) => await this.setTaskStatusFilterAsync(sender)}
                        />

                        {/*<Button icon={{name: "plus", size: IconSize.Large}}*/}
                        {/*        label={Localizer.genericAddTeam}*/}
                        {/*        type={ButtonType.Orange}*/}
                        {/*        onClick={async (sender) => this.props.openTeamModal(sender, null)}*/}
                        {/*/>*/}

                        <Dropdown small multiple autoGroupSelected
                                  minWidth={200}
                                  items={this.costPools}
                                  label={Localizer.genericCostPools}
                                  selectedItems={this.selectedMounterCostPools}
                                  onChange={async (sender) => await this.selectMounterCostPoolsAsync(sender.selectedItems)}
                        />

                        <Dropdown multiple autoGroupSelected small noValidate noSubtext noWrap
                                  label={Localizer.genericManager}
                                  minWidth="180px"
                                  align={DropdownAlign.Left}
                                  orderBy={DropdownOrderBy.None}
                                  items={this.managers}
                                  selectedItems={this.selectedManagers}
                                  onChange={async (sender) => await this.onManagersChangeAsync(sender.selectedItems)}
                        />

                        <Dropdown multiple autoGroupSelected small noValidate noSubtext noWrap
                                  label={Localizer.genericMounters}
                                  minWidth="180px"
                                  align={DropdownAlign.Left}
                                  orderBy={DropdownOrderBy.None}
                                  items={this.mounters}
                                  selectedItems={this.selectedMounters}
                                  onChange={async (sender) => await this.onMountersChangeAsync(sender.selectedItems)}
                        />

                        <Button
                            className={styles.filtersTopMargin}
                            small
                            title={Localizer.tasksToolbarClearFilters}
                            icon={{name: "far history", size: IconSize.Large}}
                            type={ButtonType.Info}
                            onClick={async () => await this.clearAsync()}
                        />
                    </Inline>
                </Form>

                <Modal ref={this._legendModalRef}
                       className={styles.calendarLegendModal}
                       title={Localizer.genericLegend}
                >
                    <Inline>
                        <div className={styles.dotsContainer}>
                            <div className={styles.dotsColumn}>
                                <div className={styles.dot}>
                                    <div style={{backgroundColor: "gray"}}/>
                                    <span>{Localizer.enumTaskStatusFilterUnscheduled}</span>
                                </div>

                                <div className={styles.dot}>
                                    <div style={{backgroundColor: "yellow"}}/>
                                    <span>{Localizer.enumTaskStatusFilterUpcoming}</span>
                                </div>

                                <div className={styles.dot}>
                                    <div style={{backgroundColor: "lightblue"}}/>
                                    <span>{Localizer.enumTaskStatusFilterInProgress}</span>
                                </div>

                                <div className={styles.dot}>
                                    <div style={{backgroundColor: "blue"}}/>
                                    <span>{Localizer.enumTaskStatusFilterCompleted}</span>
                                </div>

                                <div className={styles.dot}>
                                    <div style={{backgroundColor: "violet"}}/>
                                    <span>{Localizer.enumTaskStatusFilterSentToCustomer}</span>
                                </div>

                                <div className={styles.dot}>
                                    <div style={{backgroundColor: "darkviolet"}}/>
                                    <span>{Localizer.enumTaskStatusFilterApprovedByCustomer}</span>
                                </div>

                                <div className={styles.dot}>
                                    <div style={{backgroundColor: "red"}}/>
                                    <span>{Localizer.enumTaskStatusFilterDeclinedByCustomer}</span>
                                </div>

                                <div className={styles.dot}>
                                    <div style={{backgroundColor: "lightgreen"}}/>
                                    <span>{Localizer.enumTaskStatusFilterReadyForInvoicing}</span>
                                </div>

                                <div className={styles.dot}>
                                    <div style={{backgroundColor: "green"}}/>
                                    <span>{Localizer.enumTaskStatusFilterInvoiced}</span>
                                </div>
                            </div>
                        </div>
                    </Inline>
                </Modal>
            </ToolbarContainer>
        );
    }
}