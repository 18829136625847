import React from "react";
import WorkOrderModel from "../../../models/server/WorkOrderModel";
import RentaTasksWizardPage from "../RentaTasksWizardPage";
import WorkOrderDistance from "@/models/server/WorkOrderDistance";
import UserSalaryHour from "@/models/server/UserSalaryHour";
import DailyHoursWidget from "@/pages/RentaTasks/HoursAndDistances/DailyHoursWidget/DailyHoursWidget";
import DistanceWidget from "@/pages/RentaTasks/HoursAndDistances/DistanceWidget/DistanceWidget";
import {TextInputWidget, TitleWidget} from "@renta-apps/athenaeum-react-components";
import RentaTaskConstants from "@/helpers/RentaTaskConstants";
import User from "@/models/server/User";
import GetEmployeesRequest from "@/models/server/requests/GetEmployeesRequest";
import RentaTasksController from "@/pages/RentaTasks/RentaTasksController";
import Localizer from "@/localization/Localizer";

import styles from "./HoursAndDistances.module.scss";

export interface IHoursAndDistancesProps {
}

interface IHoursAndDistancesState {
    mounters: User[];
}

export default class HoursAndDistances extends RentaTasksWizardPage<IHoursAndDistancesProps, IHoursAndDistancesState> {

    state: IHoursAndDistancesState = {
        mounters: [],
    };

    private get wizardContextWorkOrder(): WorkOrderModel {
        return RentaTasksController.wizardContextWorkOrder!;
    }

    private get myHours(): UserSalaryHour {
        return this.wizard.myHours;
    }

    private get mounters(): User[] {
        return this.state.mounters;
    }

    private async setAllHoursAsync(hours: UserSalaryHour[]): Promise<void> {
        this.wizardContextWorkOrder.userSalaryHours = hours;
        this.saveContext();
    }

    private async setDistances(distances: WorkOrderDistance[]): Promise<void> {
        this.wizardContextWorkOrder.distances = distances;
        this.saveContext();
    }

    private async setInvoiceReferenceAsync(invoiceReference: string): Promise<void> {
        if (invoiceReference !== this.wizardContextWorkOrder.invoiceReference) {
            this.wizardContextWorkOrder.invoiceReference = invoiceReference;
            this.saveContext();
        }
    }

    private async setJobSummaryAsync(jobSummary: string): Promise<void> {
        if (jobSummary !== this.wizardContextWorkOrder.jobSummary) {
            this.wizardContextWorkOrder.jobSummary = jobSummary;
            this.saveContext();
        }
    }

    public async nextAsync(): Promise<void> {
        await super.nextAsync();
    }

    public getManual(): string {
        return Localizer.taskGetManual;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const mounters: User[] = await this.postAsync("api/rentaTasks/getWorkOrderHoursData", new GetEmployeesRequest(this.wizard.workOrder!.constructionSiteId));
        
        await this.setState({ mounters });
    }

    public renderContent(): React.ReactNode {

        return (
            <React.Fragment>

                <TitleWidget model={this.title} wide />

                <TextInputWidget id="WorkOrderInvoiceReference" wide
                                 label={Localizer.workOrdersInvoiceReference}
                                 value={this.wizardContextWorkOrder.invoiceReference || ""}
                                 onChange={async (_, name) => await this.setInvoiceReferenceAsync(name)}
                />

                <TextInputWidget id="WorkOrderJobSummary" wide
                                 className={styles.hoursAndDistances}
                                 label={Localizer.workOrdersJobSummary}
                                 value={this.wizardContextWorkOrder.jobSummary || ""}
                                 maxLength={RentaTaskConstants.bigStringLength}
                                 onChange={async (_, description) => await this.setJobSummaryAsync(description)}
                />

                <DistanceWidget id="WorkOrderDistances" wide
                                distances={this.wizardContextWorkOrder.distances || []}
                                onChange={async (_, distances) => await this.setDistances(distances)}
                />

                <DailyHoursWidget id="WorkOrderHours" wide
                                  workOrderId={this.wizardContextWorkOrder.id}
                                  hours={this.wizardContextWorkOrder.userSalaryHours || []}
                                  myHours={this.myHours}
                                  mounters={this.mounters}
                                  onChange={async (_, hours) => await this.setAllHoursAsync(hours)}
                />

            </React.Fragment>
        );
    }
}