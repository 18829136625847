import {ApplicationContext, ch} from "@renta-apps/athenaeum-react-common";
import {UnleashClient} from "unleash-proxy-client";

class UnleashHelper {

    private _initialized: boolean = false;

    private _client: UnleashClient = new UnleashClient({
        // Add anything formally valid to make constructor happy.
        url: "https://example.com",
        appName: "name",
        clientKey: "key"
    });

    public async initialize(): Promise<void> {
        if (!this._initialized) {

            // Parse settings from key
            const context: ApplicationContext = ch.getContext();

            const parts: string[] = context.settings.unleashProxyKey.match("(.*)[:](.*)[.].*");
            const appName: string = parts[1];
            const environment: string = parts[2];

            this._client = new UnleashClient({
                clientKey: context.settings.unleashProxyKey,
                url: context.settings.unleashProxyUrl,
                appName: appName,
                environment: environment,
            });

            this.updateClientUserId();

            await this.client.start();

            this._initialized = true;
        }
    }

    /**
     * Set the {@link UnleashClient}s userId to the currently logged-in users {@link User.username}.
     */
    public updateClientUserId(): void {
        this._client.setContextField("userId", ch.findUser()?.username!);
    }

    public get client(): UnleashClient {
        return this._client;
    }

    /** Returns true if the flag is configured and enable for the current environment and country. */
    public isEnabled(flagName: string): boolean {
        const getCountry = function(): string {
            if (ch.isSweden) {
                return "SE";
            }
            if (ch.isFinland) {
                return "FI";
            }
            if (ch.isDenmark) {
                return "DK";
            }
            if (ch.isPoland) {
                return "PL";
            }
            if (ch.isNorway) {
                return "NO";
            }

            throw new Error("Not supported country: " + ch.country);
        }

        //To make sure NOR turns into => NO
        const fullFlagName: string = `TASK_${flagName}_${getCountry()}`;
        return this.client.isEnabled(fullFlagName);
    }
}

/** {@link UnleashHelper} singleton. */
export default new UnleashHelper();