import React from "react";
import {Link, PageContainer, PageHeader, PageRow} from "@renta-apps/athenaeum-react-components";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import PageDefinitions from "../../providers/PageDefinitions";
import UserContext from "@/models/server/UserContext";
import Localizer from "../../localization/Localizer";
import {FeatureSwitch} from "@/components/FeatureSwitch/FeatureSwitch";
import FeatureFlags from "@/helpers/FeatureFlags";

import styles from "./Admin.module.scss"

interface IAdminProps {
}

interface IAdminState {
}

export default class AdminPage extends AuthorizedPage<IAdminProps, IAdminState> {

    private get isAdminOrBusinessManager(): boolean {
        const context: UserContext = this.getContext();
        return context.isAdmin || context.isBusinessManager;
    }

    public render(): React.ReactNode {
        return (
            <PageContainer className={styles.admin}>
                <PageHeader title={Localizer.adminHeaderTitle} subtitle={this.getContext().username}/>
                <PageRow>
                    <div className="col">
                        <div id="adminPageLinks"
                             className={styles.menu}>
                            <Link className={styles.menuLink} route={PageDefinitions.userManagementRoute}>{Localizer.userManagementTitle}</Link>

                            {
                                (this.isAdminOrBusinessManager) &&
                                (
                                    <React.Fragment>
                                        <Link className={styles.menuLink} route={PageDefinitions.warehousesRoute}>{Localizer.warehousesPageTitle}</Link>
                                        <Link className={styles.menuLink} route={PageDefinitions.salesProductCatalogManagementRoute}>{Localizer.catalogManagementPageTitle}</Link>
                                        
                                        <FeatureSwitch flagName={FeatureFlags.RentalMassProducts}>
                                            <Link className={styles.menuLink} route={PageDefinitions.rentalMassProductCatalogManagementRoute}>{Localizer.catalogManagementRentalMassProductsPageTitle}</Link>
                                        </FeatureSwitch>
                                        
                                        <Link className={styles.menuLink} route={PageDefinitions.workOrderTypeManagementRoute}>{Localizer.workOrderTypeManagementPageTitle}</Link>
                                        
                                        <FeatureSwitch flagName={FeatureFlags.ExtraCharge}>
                                            <Link className={styles.menuLink} route={PageDefinitions.extraChargeTypeManagementRoute}>{Localizer.extraChargeTypeManagementPageTitle}</Link>
                                        </FeatureSwitch>
                                    </React.Fragment>
                                )
                            }

                            {
                                (this.isAdmin) &&
                                (
                                    <React.Fragment>
                                        <Link className={styles.menuLink} route={PageDefinitions.regionManagementRoute}>{Localizer.regionManagementPageTitle}</Link>
                                        <Link className={styles.menuLink} route={PageDefinitions.formDefinitionsRoute}>{Localizer.formDefinitionsPageTitle}</Link>
                                        <Link className={styles.menuLink} route={PageDefinitions.globalNotificationSettingsRoute}>{Localizer.globalNotificationSettingsPageTitle}</Link>
                                        <Link className={styles.menuLink} route={PageDefinitions.adminConsoleRoute}>{Localizer.adminConsolePageTitle}</Link>
                                    </React.Fragment>
                                )
                            }

                        </div>
                    </div>
                </PageRow>
            </PageContainer>
        );
    }
}