import WorkOrderEquipment from "../WorkOrderEquipment";
import UserSalaryHourItem from "@/models/server/UserSalaryHourItem";
import WorkOrderDistanceItem from "@/models/server/WorkOrderDistanceItem";
import BaseConcurrencyRequest from "@/models/server/requests/BaseConcurrencyRequest";
import WorkOrderExtraCharge from "@/models/server/WorkOrderExtraCharge";
import WorkOrderRentalItemModel from "@/models/server/WorkOrderRentalItemModel";
import {WorkOrderContractType} from "@/models/Enums";

export default class SaveWorkOrderDataRequest extends BaseConcurrencyRequest {
    public workOrderId: string = "";

    public contractType: WorkOrderContractType = WorkOrderContractType.Normal;
    
    public name: string = "";
    
    public description: string = "";

    public invoiceReference: string | null = null;

    public jobSummary: string | null = null;

    public activationDate: Date | null = null;

    public equipment: WorkOrderEquipment[] | null = null;

    public rentalEquipments: WorkOrderRentalItemModel[] | null = null;

    public distances: WorkOrderDistanceItem[] | null = null;

    public userSalaryHours: UserSalaryHourItem[] | null = null;
    
    public extraCharges: WorkOrderExtraCharge[] | null = null;

    public managerId: string | null = null;

    public customerOrdererId: string | null = null;
    
    public customerApproverId: string | null = null;
}