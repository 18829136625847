/**
 *  Keys for feature flags. There's a matching .cs file.
 */
export default class FeatureFlags {

    /**
     * Enables calendar planner in application UI
     */
    public static readonly Calendar = "CALENDAR" as const;

    /**
     * Controls Admin CRUD view of extra charge types, and adding extra charges on a work order
     */
    public static readonly ExtraCharge = "EXTRA_CHARGE" as const;

    /**
     * Enables Sync service
     */
    public static readonly Sync = "SYNC" as const;

    /**
     * Enables displaying, sorting and searching by project number in construction sites
     */
    public static readonly ProjectNumberInConstructionSites = "PROJECT_NUMBER_IN_CONSTRUCTION_SITES" as const;

    /**
     * Enables displaying and modification of users employee number in UserManagement page.
     */
    public static readonly EmployeeNumber = "EMPLOYEE_NUMBER" as const;

    /**
     * Enables option to add rental equipment to work order.
     */
    public static readonly RentalEquipment = "RENTAL_EQUIPMENT" as const;

    /**
     * Enables new full-text-search functionality for construction site page.
     */
    public static readonly ConstructionSiteFullTextSearch = "CS_FULLTEXT_SEARCH" as const;

    /**
     * Enables MySqlMatchSearchMode.Boolean for {@see ConstructionSiteFullTextSearch} 
     */
    public static readonly ConstructionSiteFullTextSearchBooleanMode = "CS_FULLTEXT_SEARCH_BOOLEAN_MODE" as const;

    /**
     * Enables and displays alarm job functions on the UI.
     */
    public static readonly AlarmJobs = "ALARM_JOBS" as const;

    /**
     * Enables and displays comment field for user salary hours on the UI.
     */
    public static readonly UserSalaryHoursComment = "HOURS_COMMENT" as const;

    /**
     * Displays rental mass products on the catalog and on work order pages.
     */
    public static readonly RentalMassProducts = "RENTAL_MASS_PRODUCTS" as const;

    /**
     * If enabled when a cost pool is selected from a dropdown in the related mounter dropdown all mounters
     * who have that cost pool set will be automatically selected.
     */
    public static readonly CostPoolMounterAutoSelect = "COST_POOL_MOUNTER_AUTO_SELECT" as const;

    /**
     * Enables sending work order notifications to external personnel
     */
    public static readonly ExternalNotifications = "EXTERNAL_NOTIFICATIONS" as const;

    /**
     * If enabled, the calendar page will lazy load work orders only for the current view (week, month), and load more if view moves.
     */
    public static readonly CalendarLazyLoading: string = "CALENDAR_LAZY_LOADING";

}