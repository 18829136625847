import {pageData} from "@/helpers/CypressHelper";

const formName = `Mounters signature required form ${new Date()}`
const workOrderName = "WorkOrderWithFormWhereMountersSignaturesAreRequired";

context("(Desktop) Forms", () => {

    beforeEach(() => {
        cy.init("Desktop");
    });

    it("Should work correctly for Admin", () => {
        cy.loginAsAdmin();
        test();
    });

    const addFormDefinitionSettings: AddFormDefinitionSettings = {
        formName: formName,
        customType: 'Undefined',
        description: `Test mounters signature required form definition ${new Date()} description`,
        reportingHeaders: ['Customer name'],
        workOrders: ['Enabled'],
        timeslot: 'Anytime',
        required: false,
        multiple: false,
        approvable: false,
        mounterSignaturesRequired: true,
        steps: [
            {
                name: "Test step name",
                typeDropdown: 'Question',
                stepTypeSettings: null
            }
        ]
    }

    function test(): void {
        describe("Create form definition with mounter signatures required", () => {
            cy.addFormDefinition(addFormDefinitionSettings);
        });

        describe("Create work order with form which requires mounter signatures", () => {
            const addWorkOrderSettings: AddWorkOrderSettings = {
                customerAndSite: "Customer.SubCompanyContractNoExternalId.ConstructionSite",
                status: "New",
                name: workOrderName,
                workOrderType: "Enabled",
                startDate: new Date(),
                managers: ["Renta.OperatorContract.Manager@automatic.test.rentatask.fi"],
                mounters: [
                    "Renta.OperatorContract.Mounter@automatic.test.rentatask.fi",
                    "Renta.OperatorContract.Mounter-1@automatic.test.rentatask.fi",
                    "Renta.OperatorContract.Mounter-2@automatic.test.rentatask.fi"
                ],
                ordererFullName: "ContactPerson Tester",
                approverFullName: "ContactPerson Tester",
            }

            cy.addWorkOrder(addWorkOrderSettings);
        });
    }
});


context("(Mobile) Forms", () => {

    beforeEach(() => {
        cy.init("Mobile");
    });

    it("Should work correctly for Mounter", () => {
        cy.loginAsMounter();
        test();
    });

    function test(): void {
        goToMountersSignatureStep();
        describe("Mounter signatures required form can't be completed without signatures", () => {
            pageData().mobileFormSteps.nextButton().should('have.class', 'athenaeum-navigation-widget-disabled');
        });

        const oneMounterOnly = ["Renta.OperatorContract.Mounter@automatic.test.rentatask.fi"];
        pageData().mobileFormSteps.mountersSignaturesStep.mountersSignaturesDropdown().selectMultiselectDropdownValue(oneMounterOnly);

        describe("Mounter signatures required form can't be completed with signature only", () => {
            addSignature();
            pageData().mobileFormSteps.nextButton().should('have.class', 'athenaeum-navigation-widget-disabled');
        });

        describe("Mounter signatures required form can't be completed with name clarification only", () => {
            addNameClarification();
            pageData().mobileFormSteps.nextButton().should('have.not.class', 'athenaeum-navigation-widget-disabled');
        });


        describe("Mounter signatures required form can be completed with name clarification and signature", () => {
            addSignature();
            addNameClarification();

            pageData().mobileFormSteps.nextButton().click();
        });
    }

    const goToMountersSignatureStep = (): void => {
        cy.navigateToMobileWorkOrdersPage();

        cy.get('#workOrdersList').within(() => {
            cy.contains(workOrderName).click();
        });

        cy.get('#workOrderFormsButton').click();

        cy.get('#activeForms').within(() => {
            cy.contains(formName).parent("tr").within(() => {
                cy.get('button[title=Fill]').click();
            })
        });

        pageData().mobileFormSteps.questionStep.okButton().click();
        pageData().mobileFormSteps.nextButton().click();
    }

    function addSignature() {
        pageData().common.signatureWidget.mountersSignaturePanel().click({force:true});

        pageData().common.signatureWidget.mountersSignaturePanel().within(() => {
            pageData().common.signatureWidget.signatureCanvas()
                .trigger("mousedown", 10, 50, { which: 1})
                .trigger('mousemove',50, 10)
                .trigger("mousemove")
                .trigger('mousemove',20, 50)
                .trigger("mousemove")
                .trigger("mouseup", { which: 1})

            pageData().common.signatureWidget.doneButton().click();
        })
    }
    
    function addNameClarification() {
        cy.get('.user-signature').within(() => {
            pageData().mobileFormSteps.mountersSignaturesStep.signatureNameClarification().type("Name clarification");
        });
}

});

// TODO finalize completion if possible (quite likely will fail on a call to IronPDF) once changes for RSW-21649 are implemented