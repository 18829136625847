import React from "react";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import {Button, ButtonType, DateInput, Dropdown, EmailInput, Form, Modal, OneColumn, PhoneInput, Spinner, TextAreaInput, TextInput, TwoColumns} from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";

import styles from "./ExternalNotificationModal.module.scss";
import User from "@/models/server/User";
import {SendWorkOrderExternalNotificationRequest} from "@/models/server/requests/SendWorkOrderExternalNotificationRequest";
import {WorkOrderExternalNotificationModel} from "@/models/server/WorkOrderExternalNotificationModel";

interface IExternalNotificationModalProps {
    onSend?(): Promise<void> | null;
}

interface IExternalNotificationModalState {
    readonly: boolean;
    workOrderId: string | null;
    recipientEmail?: string;
    requestDate?: Date;
    senderEmail?: string;
    senderPhone?: string;
    senderFirstName?: string;
    senderLastName?: string;
    senderId?: string;
    comment?: string;
    mounters?: User[] | null;
    selectedMountersIds: string[];
}

export default class ExternalNotificationModal extends BaseComponent<IExternalNotificationModalProps, IExternalNotificationModalState> {

    state: IExternalNotificationModalState = {
        workOrderId: null,
        selectedMountersIds: [],
        readonly: false,
    };

    private readonly _modalRef: React.RefObject<Modal> = React.createRef();
    private readonly _formRef: React.RefObject<Form> = React.createRef();

    private get modal(): Modal {
        return this._modalRef.current!;
    }

    private get title(): string {
        return (!this.state.readonly) 
            ? Localizer.externalNotificationModalTitle
            : Localizer.externalNotificationModalPreviewTitle;
    }

    private get subtitle(): string | undefined {
        return (!this.state.readonly)
            ? Localizer.externalNotificationModalSubtitle
            : undefined;
    }
    
    public async openAsync(user: User, workOrderId: string, mounters: User[]): Promise<void> {
        this.setState({
            workOrderId,
            senderPhone: user.phone,
            senderEmail: user.email,
            senderFirstName: user.firstname,
            senderLastName: user.lastName,
            senderId: user.id,
            comment: "",
            recipientEmail: "",
            requestDate: new Date(),
            mounters,
            selectedMountersIds: [],
            readonly: false,
        });
        await this.modal.openAsync();
    }

    public async openPreviewAsync(notificationModel: WorkOrderExternalNotificationModel): Promise<void> {
        const user: User = notificationModel.sender!;
        this.setState({
            workOrderId: notificationModel.workOrderId,
            senderPhone: user.phone,
            senderEmail: user.email,
            senderFirstName: user.firstname,
            senderLastName: user.lastName,
            comment: notificationModel.comment,
            recipientEmail: notificationModel.recipientEmail,
            requestDate: notificationModel.requestDate,
            mounters: [],
            selectedMountersIds: [],
            readonly: true
        });
        await this.modal.openAsync();
    }

    private async onRecipientEmailChangeAsync(recipientEmail: string): Promise<void> {
        this.setState({recipientEmail});
    }

    private async onRequestDateChangeAsync(requestDate: Date): Promise<void> {
        this.setState({requestDate});
    }

    private async onCommentChangeAsync(comment: string): Promise<void> {
        this.setState({comment});
    }
    
    private async send(): Promise<void> {
        if (this._modalRef.current) {
            await this.setSpinnerAsync(true);
            
            const request: SendWorkOrderExternalNotificationRequest = new SendWorkOrderExternalNotificationRequest();
            request.workOrderId = this.state.workOrderId!;
            request.comment = this.state.comment!;
            request.requestDate = this.state.requestDate!;
            request.email = this.state.recipientEmail!;
            request.userId = this.state.senderId!;
            request.selectedMountersIds = this.state.selectedMountersIds;

            await this.postAsync("/api/WorkOrders/sendWorkOrderExternalNotification", request);
            
            await this.setSpinnerAsync(false);
            
            await this.modal.closeAsync();
            
            if (this.props.onSend) {
                await this.props.onSend();
            }
        }
    }
    
    private async onSubmit(form: Form): Promise<void> {
        const isValid: boolean = await form.validateAsync();
        if (isValid) {
            await this.send();
        }
    }

    private async onMountersChangeAsync(mounters: User[]): Promise<void> {
        this.setState({selectedMountersIds: mounters.map(x => x.id)});
    }

    public static get modalId(): string {
        return "externalNotificationModal";
    }

    public hasSpinner(): boolean {
        return true;
    }

    public render(): React.ReactNode {
        return (
            <Modal id={ExternalNotificationModal.modalId}
                   ref={this._modalRef}
                   title={this.title}
                   subtitle={this.subtitle}
                   className={styles.statusMessageModal}>

                <div className="row">
                    {
                        (this.isSpinning()) && <Spinner/>
                    }
                    <div className="col">
                        <Form ref={this._formRef}
                              id={`${ExternalNotificationModal.modalId}Form`}
                              onSubmit={async (form) => await this.onSubmit(form)}>
                            
                            <TwoColumns>
                                <EmailInput
                                    id="recipientEmail"
                                    readonly={this.state.readonly}
                                    required
                                    label={Localizer.externalNotificationModalRecipientEmail}
                                    value={this.state.recipientEmail}
                                    onChange={async (_, item) => await this.onRecipientEmailChangeAsync(item!)}
                                />
                                <DateInput
                                    id="requestDate"
                                    readonly={this.state.readonly}
                                    required
                                    label={Localizer.externalNotificationModalRequestDate}
                                    value={this.state.requestDate}
                                    onChange={async (item) => await this.onRequestDateChangeAsync(item!)}
                                />
                            </TwoColumns>

                            <TwoColumns>
                                <TextInput
                                    readonly
                                    label={Localizer.externalNotificationModalSendersEmail}
                                    value={this.state.senderEmail}
                                />
                                <PhoneInput
                                    readonly
                                    label={Localizer.externalNotificationModalSendersPhone}
                                    value={this.state.senderPhone}
                                />
                            </TwoColumns>

                            <TwoColumns>
                                <TextInput
                                    readonly
                                    label={Localizer.externalNotificationModalSendersFirstName}
                                    value={this.state.senderFirstName}
                                />
                                <TextInput
                                    readonly
                                    label={Localizer.externalNotificationModalSendersLastName}
                                    value={this.state.senderLastName}
                                />
                            </TwoColumns>
                            
                            <OneColumn>
                                {
                                    (!this.state.readonly) &&
                                    (
                                        <Dropdown<User> multiple
                                                        className="workOrderMounters"
                                                        label={Localizer.genericMounters}
                                                        items={this.state.mounters ?? []}
                                                        selectedItems={this.state.selectedMountersIds}
                                                        onChange={async (sender) => await this.onMountersChangeAsync(sender.selectedItems)}
                                        />
                                    )
                                }
                                
                            </OneColumn>
                            
                            <OneColumn>
                                <TextAreaInput
                                    required
                                    readonly={this.state.readonly}
                                    id="comment"
                                    label={Localizer.genericComment}
                                    value={this.state.comment}
                                    onChange={async (_, item) => await this.onCommentChangeAsync(item!)}
                                />
                            </OneColumn>
                            
                            {
                                (!this.state.readonly) &&
                                (
                                    <OneColumn>
                                        <Button block
                                                title={Localizer.genericActionSend}
                                                submit
                                                className={styles.okButton}
                                                label={Localizer.genericActionSend}
                                                type={ButtonType.Orange}
                                        />
                                    </OneColumn>
                                )
                            }
                            
                        </Form>
                    </div>
                </div>
            </Modal>
        );
    }
}