import React from "react";
import {BaseComponent, ch, IBaseComponent} from "@renta-apps/athenaeum-react-common";
import {Button, ButtonContainer, ButtonType, Dropdown, DropdownOrderBy, EmailInput, EmailValidator, Form, Modal, ModalSize, SelectListItem, Spinner} from "@renta-apps/athenaeum-react-components";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import FormModel from "@/models/server/forms/FormModel";
import SaveFormRequest from "@/models/server/requests/SaveFormRequest";
import {CustomerApprovalType} from "@/models/Enums";
import GetWorkOrderFormsRequest from "@/models/server/requests/GetWorkOrderFormsRequest";
import User from "@/models/server/User";
import SendFormReportRequest from "@/models/server/requests/SendFormReportRequest";
import WorkOrderModel from "@/models/server/WorkOrderModel";
import StartFormResponse from "@/models/server/requests/StartFormResponse";
import StartManualFormRequest from "@/models/server/requests/StartManualFormRequest";
import UnlockFormResponse from "@/models/server/responses/UnlockFormResponse";
import FormItem from "@/components/Form/FormItem/FormItem";
import TransformProvider from "@/providers/TransformProvider";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "@/localization/Localizer";

import styles from "./FormModal.module.scss";

interface IFormModalProps {
    onSubmit(sender: IBaseComponent, request: SaveFormRequest): Promise<void>;

    /**
    * Called when unlocking the form with deleted form definition
    */
    reloadData(): Promise<void>;
    getWorkOrderForms(sender: IBaseComponent, request: GetWorkOrderFormsRequest): Promise<FormModel[]>;
}

interface IFormModalState {
    initialHashCode: number;
    forms: FormModel[];
    workOrder: WorkOrderModel;
    form: FormModel;
    showFormContent: boolean;
}

export default class FormModal extends BaseComponent<IFormModalProps, IFormModalState> {

    state: IFormModalState = {
        initialHashCode: 0,
        forms: [],
        workOrder: new WorkOrderModel(),
        form: new FormModel(),
        showFormContent: false
    };

    private readonly _modalRef: React.RefObject<Modal> = React.createRef();
    private readonly _formRef: React.RefObject<Form> = React.createRef();
    private readonly _formsDropdownRef: React.RefObject<Dropdown<FormModel>> = React.createRef();

    private get modal(): Modal {
        return this._modalRef.current!;
    }

    private get workOrder(): WorkOrderModel {
        return this.state.workOrder!;
    }

    private get forms(): FormModel[] {
        return this.state.forms;
    }

    private get form(): FormModel {
        return this.state.form;
    }

    private get showFormContent(): boolean {
        return this.state.showFormContent;
    }

    private get approverEmail(): string {
        return this.form.contactUser?.email
            ?? this.workOrder.customerApprover?.email
            ?? this.workOrder.customerOrderer?.email
            ?? "";
    }

    private get viewers(): User[] {
        const viewers: User[] = [];

        function addReviewer(user: User | null | undefined): void {
            if (user) {
                viewers.push(user);
            }
        }

        addReviewer(this.form.contactUser);
        addReviewer(this.workOrder.customerOrderer);
        addReviewer(this.workOrder.customerApprover);
        addReviewer(this.workOrder.manager);

        return viewers.distinct(user => user.email);
    }

    private get processed(): boolean {
        return this.form.processed;
    }

    private get inProgress(): boolean {
        return (this.form.startedAt != null) && (this.form.user != null) && (!this.processed);
    }

    private get canStart(): boolean {
        return (!this.inProgress) && (!this.processed) && (!this.form.locked);
    }

    private get canContinue(): boolean {
        const currentUserId: string = ch.getUserId();

        return (this.inProgress) && (currentUserId === this.form.userId);
    }

    private get canUnlock(): boolean {
        return (this.form.locked);
    }

    private get canApprove(): boolean {
        return (!this.processed && !this.form.approved);
    }

    private get canPreview(): boolean {
        return (this.form.processed);
    }

    private get canSend(): boolean {
        return FormModel.canBeSent(this.form);
    }

    private get isApprovable(): boolean {
        return (this.form.mapping.approvable);
    }

    private get isModified(): boolean {
        const hashCode: number = Utility.getHashCode(this.form);
        return hashCode != this.state.initialHashCode;
    }

    private getEmailValidationError(value: string): string | null {
        return EmailValidator.validator(value);
    }

    private async updateHash(): Promise<void> {
        await this.setState({initialHashCode: Utility.getHashCode(this.form)})
    }

    private async startFormAsync(form: FormModel): Promise<void> {
        if (!form.processed) {

            let response: StartFormResponse;

            if (form.id) {
                // Before/After Form
                response = await this.postAsync("api/constructionSiteManagement/startForm", form.id);
            }
            else {
                // Anytime ("Manual") Form

                const request: StartManualFormRequest = {
                    workOrderId: this.workOrder.id,
                    formDefinitionId: form.formDefinitionId,
                };

                response = await this.postAsync("api/constructionSiteManagement/startManualForm", request);
            }

            if (response.lockedByAnotherUser) {
                await ch.alertErrorAsync(Localizer.rentaTasksControllerAlertErrorFormProcessedByAnotherUser);
                return;
            }

            if (response.form) {
                await this.updateFormsDataAsync(response.form, true);
            }
        }
    }

    private async continueFormAsync(form: FormModel): Promise<void> {
        if (!form.processed && form.id) {
            await this.setState({showFormContent: true});
        }
    }

    private async updateFormsDataAsync(form: FormModel, showFormContent: boolean): Promise<void> {

        const initialForm: FormModel = this.form;

        const index: number = this.forms.indexOf(initialForm);

        if (index != -1) {
            const updatedForms = this.state.forms;
            updatedForms[index] = form;

            await this.setState({
                forms: updatedForms,
                form: form,
                showFormContent: showFormContent,
            });

            await this.updateHash();
        }
    }

    private async onSubmitAsync(): Promise<void> {
        const form: FormModel = this.form;

        form.passed = FormModel.isPassed(form);
        form.processed = FormModel.isValid(form);
        form.processedAt = Utility.utcNow();

        if (this.isApprovable && !form.approverEmail) {
            form.approverEmail = this.approverEmail;
        }
        
        const request = new SaveFormRequest();
        request.form = form;
        request.completeAction = true;

        await this.props.onSubmit(this, request);

        await this.updateHash();

        await this.modal.closeAsync();
    }

    private async cancelAsync(): Promise<void> {
        await this.modal.closeAsync();
    }

    private async unlockAsync(form: FormModel): Promise<void> {
        const confirm: boolean = await ch.confirmAsync(Localizer.formModalConfirmationUnlock);
        if (confirm) {
            const response: UnlockFormResponse = await this.postAsync("api/constructionSiteManagement/unlockForm", form.id);
            if (response.form) {
                await this.updateFormsDataAsync(form, false);
            } else {
                await this.props.reloadData();

                const request = new GetWorkOrderFormsRequest();
                request.workOrderId = this.workOrder.id;

                const forms: FormModel[] = await this.props.getWorkOrderForms(this, request);

                await this.setState({ forms });
            }
        }
    }

    private async sendFormReportAsync(): Promise<void> {
        if (this.canSend) {
            const request = new SendFormReportRequest();
            request.id = this.form.id;
            request.emailReceivers = this.form.emailReceivers;

            await this.postAsync("api/constructionSiteManagement/sendFormReport", request);
            await this.getPage().alertMessageAsync(Localizer.formReceiversPageReportSentToCustomer, true, false);
        }
    }

    private async showFormContentAsync(value: boolean): Promise<void> {
        await this.setState({showFormContent: value});
    }

    private async setApprovalTypeAsync(approvalType: CustomerApprovalType): Promise<void> {
        this.form.approvalType = approvalType;

        await this.reRenderAsync();
    }

    private async setApproverAsync(value: string): Promise<void> {
        this.form.approverEmail = value;
    }

    private async setViewersAsync(sender: Dropdown<User>): Promise<void> {
        this.form.emailReceivers = sender.selectedListItems
            .where(item => (!!item.subtext && (this.getEmailValidationError(item.subtext) == null)))
            .map(item => item.subtext)
            .join(FormModel.emailReceiversSeparator);

        await this.reRenderAsync();
    }

    public getApprovalTypes(): SelectListItem[] {
        return (this.isApprovable && this.canApprove)
            ? [EnumProvider.getCustomerApprovalTypeItem(CustomerApprovalType.Phone), EnumProvider.getCustomerApprovalTypeItem(CustomerApprovalType.Email)]
            : EnumProvider.getCustomerApprovalTypeItems();
    }

    public getDescription(): string {
        return this.form.description || this.form.formDefinition?.description || "";
    }

    public getConfirmation(): string | null {
        if (this.isModified) {
            return Localizer.formModalConfirmationOnClose;
        }

        return null;
    }

    public async selectFormAsync(form: FormModel): Promise<void> {
        if (this.state.form != form) {
            await this.setState({
                form: form,
                showFormContent: false,
            })

            await this.updateHash();
        }
    }

    public async openAsync(workOrder: WorkOrderModel, forms: FormModel[]): Promise<void> {

        await this.setState({
            workOrder: workOrder,
            forms: forms,
            form: forms[0],
            showFormContent: false
        })

        await this.updateHash();

        await this.modal.openAsync();
    }

    public hasSpinner(): boolean {
        return true;
    }

    public static get modalId(): string {
        return "formModal";
    }

    public render(): React.ReactNode {
        const description: string = this.getDescription();
        const form: FormModel = this.form;

        const processedOrInProgressBy: string = (this.processed)
            ? Localizer.formModalTextProcessedBy
            : (this.inProgress)
                ? Localizer.formModalTextInProgressBy
                : "";

        return (
            <Modal id={FormModal.modalId} preventEsc
                   ref={this._modalRef}
                   title={form.name || "..."}
                   subtitle={Localizer.get(Localizer.formModalSubtitle,FormModel.getFormStatus(form))}
                   size={ModalSize.Large}
                   className={styles.form}
                   closeConfirm={() => this.getConfirmation()}
            >

                <div className="row">
                    <div className="col">

                        {
                            (form.approved) && (form.approvedBy) &&
                            (
                                <span>
                                    {Localizer.get(Localizer.formModalTextApprovedBy, TransformProvider.userToString(form.approvedBy!))}

                                    <br/>
                                </span>
                            )
                        }

                        {
                            (this.inProgress) || (this.processed) &&
                            (
                                <span>
                                    {Localizer.get("{0} {1}", processedOrInProgressBy, TransformProvider.userToString(form.user!))}

                                    <br/>
                                </span>
                            )
                        }

                        {
                            (form.formDefinition) &&
                            (
                                <span>
                                    {Localizer.get(Localizer.formModalTextFormConductionTime, EnumProvider.getFormTimeslotTypeText(form.formDefinition.mapping.timeslot))}
                                </span>
                            )
                        }

                        <hr/>

                        <Dropdown id="formsList"
                                  required
                                  ref={this._formsDropdownRef}
                                  disabled={this.isModified}
                                  orderBy={DropdownOrderBy.None}
                                  label={Localizer.formModalDropdownForms}
                                  items={this.forms}
                                  selectedItem={form}
                                  onChange={async (_, form: FormModel) => await this.selectFormAsync(form)}
                        />

                        {
                            (description) &&
                            (
                                <p className={"pt-3"}>
                                    {Localizer.formDefinitionPageDescription}:
                                    <br/>
                                    <span>{description}</span>
                                </p>
                            )
                        }

                        {
                            (!this.showFormContent)
                                ?
                                (
                                    <div className={(description.length == 0) ? "pt-3" : ""}>

                                        {
                                            (this.canStart) &&
                                            (
                                                <Button id="fillButton"
                                                        block
                                                        type={ButtonType.Success}
                                                        className={"justify-content-center"}
                                                        icon={{name: "pen"}}
                                                        label={Localizer.formsListButtonTitleFill}
                                                        onClick={async () => await this.startFormAsync(form)}
                                                />
                                            )
                                        }

                                        {
                                            (this.canContinue) &&
                                            (
                                                <Button block
                                                        type={ButtonType.Success}
                                                        icon={{name: "pen"}}
                                                        label={Localizer.formsListButtonTitleContinue}
                                                        onClick={async () => this.continueFormAsync(form)}
                                                />
                                            )
                                        }

                                        {
                                            (this.canUnlock) &&
                                            (
                                                <Button block
                                                        type={ButtonType.Danger}
                                                        icon={{name: "unlock-alt"}}
                                                        label={Localizer.formsListButtonTitleUnlock}
                                                        onClick={async () => await this.unlockAsync(form)}
                                                />
                                            )
                                        }

                                        {
                                            (this.canPreview) &&
                                            (
                                                <Button block
                                                        type={ButtonType.Info}
                                                        icon={{name: "eye"}}
                                                        label={Localizer.genericActionPreview}
                                                        onClick={async () => await this.showFormContentAsync(true)}
                                                />
                                            )
                                        }

                                    </div>
                                )
                                :
                                (
                                    <Form ref={this._formRef} id="form" onSubmit={async () => await this.onSubmitAsync()}>

                                        <>
                                            {
                                                form.items.map((formItem) => (
                                                    <React.Fragment key={formItem.id}>
                                                        <FormItem formItem={formItem}
                                                                  readonly={this.processed}
                                                                  onChange={async () => await this.reRenderAsync()}
                                                        />

                                                        <hr/>
                                                    </React.Fragment>
                                                ))
                                            }
                                        </>

                                        {
                                            (this.isApprovable) &&
                                            (
                                                <>

                                                    <Dropdown required noFilter
                                                              disabled={!this.canApprove}
                                                              label={Localizer.signatureApprovalType}
                                                              orderBy={DropdownOrderBy.None}
                                                              items={this.getApprovalTypes()}
                                                              selectedItem={EnumProvider.getCustomerApprovalTypeItem(form.approvalType)}
                                                              onChange={async (_, item) => await this.setApprovalTypeAsync(parseInt(item!.value))}
                                                    />

                                                    {
                                                        (form.approvalType == CustomerApprovalType.Email) &&
                                                        (
                                                            <EmailInput id={`receiver_email`} required
                                                                        readonly={!this.canApprove}
                                                                        label={Localizer.formEmailInputReceiver}
                                                                        value={this.approverEmail}
                                                                        onChange={async (_, value) => await this.setApproverAsync(value)}
                                                            />
                                                        )
                                                    }

                                                    <hr/>

                                                </>
                                            )
                                        }

                                        <p>{Localizer.formModalDropdownShareTo}</p>
                                        <Dropdown multiple autoGroupSelected
                                                  items={this.viewers}
                                                  nothingSelectedText={Localizer.formSummaryTableDropdownNothingSelectedText}
                                                  onChange={async (sender) => this.setViewersAsync(sender)}
                                        />

                                        {
                                            (this.processed) &&
                                            (
                                                <Button block
                                                        className={"mb-3"}
                                                        disabled={!this.canSend}
                                                        type={ButtonType.Orange}
                                                        label={Localizer.formSend}
                                                        onClick={async () => await this.sendFormReportAsync()}
                                                />
                                            )
                                        }

                                        <ButtonContainer>
                                            <Button id="cancelFormChangesButton"
                                                    label={Localizer.genericActionCancel}
                                                    disabled={this.processed}
                                                    type={ButtonType.Blue}
                                                    onClick={() => this.cancelAsync()}
                                            />
                                            <Button submit
                                                    disabled={this.processed}
                                                    label={Localizer.formSave}
                                                    type={ButtonType.Orange}
                                                    icon={{name: "far save"}}
                                            />
                                        </ButtonContainer>

                                    </Form>
                                )
                        }

                    </div>
                </div>

                {
                    (this.isSpinning()) && <Spinner/>
                }

            </Modal>
        )
    }
}