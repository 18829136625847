import React from "react";
import {FileModel, IPagedList, SortDirection, Utility} from "@renta-apps/athenaeum-toolkit";
import {BaseComponent, ch, IBaseComponent, Justify, TextAlign} from "@renta-apps/athenaeum-react-common";
import {
    ButtonType,
    CellAction,
    CellModel, ColumnActionType,
    ColumnDefinition,
    ColumnType,
    DropdownAlign,
    DropdownRequiredType,
    Grid,
    GridHoveringType,
    GridModel,
    GridOddType,
    IconSize,
    ImageModal,
    Inline,
    JustifyContent,
    ModalSize,
    RowModel,
    Tab,
    TabContainer,
    ToolbarButton,
    ToolbarContainer
} from "@renta-apps/athenaeum-react-components";
import Product from "@/models/server/Product";
import {ActionType, AttachmentType, CatalogType, EquipmentType, ProductUnit} from "@/models/Enums";
import WorkOrderModel, {IWorkOrderEditability} from "@/models/server/WorkOrderModel";
import SaveWorkOrderEquipmentRequest from "@/models/server/requests/SaveWorkOrderEquipmentRequest";
import WorkOrderEquipment from "@/models/server/WorkOrderEquipment";
import UserSalaryHour from "@/models/server/UserSalaryHour";
import SaveUserSalaryHourRequest from "@/models/server/requests/SaveUserSalaryHourRequest";
import UserContext from "@/models/server/UserContext";
import {DefaultPrices} from "@/models/server/DefaultPrices";
import RentaTaskConstants from "@/helpers/RentaTaskConstants";
import ConstructionSiteOrWarehouse from "@/models/server/ConstructionSiteOrWarehouse";
import WorkOrderDistance from "@/models/server/WorkOrderDistance";
import SaveWorkOrderDistanceRequest from "@/models/server/requests/SaveWorkOrderDistanceRequest";
import Comparator from "@/helpers/Comparator";
import WorkReportAttachment from "@/models/server/WorkReportAttachment";
import ListWorkOrderAttachmentsRequest from "@/models/server/requests/ListWorkOrderAttachmentsRequest";
import WorkOrderDetailsPanelToolbar from "@/pages/WorkOrders/WorkOrderDetailsPanel/Toolbar/WorkOrderDetailsPanelToolbar";
import ToolbarModel from "@/pages/WorkOrders/WorkOrderDetailsPanel/Toolbar/ToolbarModel";
import SaveWorkOrderAttachmentRequest from "@/models/server/requests/SaveWorkOrderAttachmentRequest";
import {WorkOrderAttachmentsGrid} from "@/components/WorkOrderAttachmentsGrid/WorkOrderAttachmentsGrid";
import AddWorkOrderAttachmentsRequest from "@/models/server/requests/AddWorkOrderAttachmentsRequest";
import FormsData from "@/pages/ConstructionSiteManagement/FormsData/FormsData";
import ExtraChargeType from "@/models/server/ExtraChargeType";
import User from "@/models/server/User";
import FeatureFlags from "@/helpers/FeatureFlags";
import WorkOrderExtraCharge from "@/models/server/WorkOrderExtraCharge";
import SaveWorkOrderExtraChargeRequest from "@/models/server/requests/SaveWorkOrderExtraChargeRequest";
import AddMounterHoursRequest from "@/models/server/requests/AddMounterHoursRequest";
import WorkOrderRentalItemModel from "@/models/server/WorkOrderRentalItemModel";
import GetWorkOrderEquipmentRequest from "@/models/server/requests/GetWorkOrderEquipmentRequest";
import WorkOrderStatusModel from "@/models/server/WorkOrderStatusModel";
import ListWorkOrderStatusesRequest from "@/models/server/requests/ListWorkOrderStatusesRequest";
import UserInteractionDataStorage from "@/providers/UserInteractionDataStorage";
import TransformProvider from "@/providers/TransformProvider";
import EnumProvider from "@/providers/EnumProvider";
import UnleashHelper from "@/helpers/UnleashHelper";
import Localizer from "../../../localization/Localizer";
import AddRentalEquipmentModal from "@/components/Catalog/AddRentalEquipmentModal/AddRentalEquipmentModal";
import {FeatureSwitch} from "@/components/FeatureSwitch/FeatureSwitch";

import styles from "./WorkOrderDetailsPanel.module.scss";
import {PostAsync} from "@/types/PostAsync";
import {WorkOrderExternalNotificationModel} from "@/models/server/WorkOrderExternalNotificationModel";
import ListWorkOrderExternalNotificationsRequest from "@/models/server/requests/ListWorkOrderExternalNotificationsRequest";
import ExternalNotificationModal from "@/components/ExternalNotificationModal/ExternalNotificationModal";

enum WorkOrderEditability {
    Editable,

    PriceEditable,

    Readonly,
}

interface IWorkOrderDetailsProps {

    /**
     * NOTE: The value is mutated
     */
    workOrder: WorkOrderModel;
    products: Product[];
    mounters: User[];
    defaultPrices: DefaultPrices;
    onChange(updateBlockingForms: boolean): Promise<void>;
    readonly?: boolean;
}

interface IWorkOrderDetailsPanelState {
    selectedTabIndex: number,
    managerUserIds: string[];
    mounterUserIds: string[];
    extraChargeTypes: ExtraChargeType[];
    filters: ToolbarModel;
}

export default class WorkOrderDetailsPanel extends BaseComponent<IWorkOrderDetailsProps, IWorkOrderDetailsPanelState> {

    public state: IWorkOrderDetailsPanelState = {
        selectedTabIndex: 0,
        extraChargeTypes: [],
        managerUserIds: [],
        mounterUserIds: [],
        filters: WorkOrderDetailsPanel.initializeFilters(),
    };

    private readonly _hoursGridRef: React.RefObject<Grid<UserSalaryHour>> = React.createRef();
    private readonly _salesEquipmentGridRef: React.RefObject<Grid<WorkOrderEquipment>> = React.createRef();
    private readonly _rentalMassEquipmentGridRef: React.RefObject<Grid<WorkOrderEquipment>> = React.createRef();
    private readonly _attachmentsGridRef: React.RefObject<WorkOrderAttachmentsGrid> = React.createRef();
    private readonly _distancesGridRef: React.RefObject<Grid<WorkOrderDistance>> = React.createRef();
    private readonly _extraChargesGridRef: React.RefObject<Grid<WorkOrderExtraCharge>> = React.createRef();
    private readonly _rentalEquipmentsGridRef: React.RefObject<Grid<WorkOrderRentalItemModel>> = React.createRef();
    private readonly _formsDataRef: React.RefObject<FormsData> = React.createRef();
    private readonly _workOrderStatusesGridRef: React.RefObject<Grid<WorkOrderStatusModel>> = React.createRef();
    private readonly _externalNotificationsGridRef: React.RefObject<Grid<WorkOrderExternalNotificationModel>> = React.createRef();
    private readonly _previewPictureDocumentRef: React.RefObject<ImageModal> = React.createRef();
    private _rentalEquipmentModal: AddRentalEquipmentModal | null = null;
    private readonly _externalNotificationModalRef: React.RefObject<ExternalNotificationModal> = React.createRef();

    private readonly _hoursColumns: ColumnDefinition[] = [
        {
            header: "#",
            accessor: "#",
            textAlign: TextAlign.Left,
            minWidth: 40,
            stretch: false
        },
        {
            header: Localizer.workOrderDetailsHoursGridDayLanguageItemName,
            accessor: "day",
            format: "D",
            textAlign: TextAlign.Left,
            type: ColumnType.Date,
            editable: true,
            minWidth: 90,
            sorting: true,
            settings: {
                max: Utility.today()
            },
            init: (cell) => this.initHoursDayAndUserColumn(cell),
        },
        {
            header: Localizer.workOrderDetailsHoursGridUserLanguageItemName,
            accessor: "user",
            type: ColumnType.Dropdown,
            settings: {
                fetchItems: async () => this.props.mounters,
                align: DropdownAlign.Left,
                multiple: false,
                groupSelected: true,
                autoCollapse: true,
                descriptionAccessor: nameof<UserSalaryHour>(o => o.comment),
                descriptionJustify: Justify.Right,
                descriptionMaxLength: RentaTaskConstants.bigStringLength,
                descriptionCallback: (cell: CellModel<UserSalaryHour>) => this.onUserSalaryHourDescriptionChangeAsync(cell)
            },
            editable: true,
            minWidth: 150,
            sorting: true,
            init: (cell) => this.initHoursDayAndUserColumn(cell),
        },
        {
            group: Localizer.workOrderDetailsHoursGridHoursHeaderLanguageItemName,
            header: Localizer.workOrderDetailsHoursGridNormalHoursLanguageItemName,
            accessor: "normalHours",
            type: ColumnType.Number,
            format: "0.0",
            editable: true,
            minWidth: 85,
            sorting: true,
            settings: {
                min: 0,
                max: RentaTaskConstants.maxHoursPerDay,
                step: 0.5,
                infoAccessor: "autoHours",
                infoTitle: Localizer.taskHoursPanelHoursInfoLanguageItemName,
                infoBoldNotEqual: true
            },
            init: (cell) => this.initColumn(cell),
            callback: async (cell) => await this.calcCostAsync(cell)
        },
        {
            group: Localizer.workOrderDetailsHoursGridHoursHeaderLanguageItemName,
            header: Localizer.workOrderDetailsHoursGridOverTime50LanguageItemName,
            accessor: "overtime50Hours",
            type: ColumnType.Number,
            format: "0.0",
            editable: true,
            minWidth: 85,
            sorting: true,
            settings: {
                min: 0,
                max: RentaTaskConstants.maxHoursPerDay,
                step: 0.5,
                hideZero: true
            },
            init: (cell) => this.initColumn(cell),
            callback: async (cell) => await this.calcCostAsync(cell)
        },
        {
            group: Localizer.workOrderDetailsHoursGridHoursHeaderLanguageItemName,
            header: Localizer.workOrderDetailsHoursGridOverTime100LanguageItemName,
            accessor: "overtime100Hours",
            type: ColumnType.Number,
            format: "0.0",
            editable: true,
            minWidth: 85,
            sorting: true,
            settings: {
                min: 0,
                max: RentaTaskConstants.maxHoursPerDay,
                step: 0.5,
                hideZero: true
            },
            init: (cell) => this.initColumn(cell),
            callback: async (cell) => await this.calcCostAsync(cell)
        },
        {
            group: Localizer.workOrderDetailsHoursGridHoursHeaderLanguageItemName,
            header: Localizer.workOrderDetailsHoursGridPriceLanguageItemName,
            accessor: "hoursPrice",
            type: ColumnType.Number,
            format: "0.0",
            editable: true,
            visible: this.isAdminOrBusinessManager,
            sorting: true,
            settings: {
                infoAccessor: (workOrder) => ConstructionSiteOrWarehouse.getHoursPrice(workOrder.owner, this.props.defaultPrices),
                infoTitle: Localizer.workOrderDetailsPanelGridHoursHoursPriceInfoTitleLanguageItemName,
                infoHideEqual: true,
            },
            init: (cell) => this.initColumn(cell, true),
            callback: async (cell) => await this.calcCostAsync(cell),
        },
        {
            group: Localizer.genericAlarmJobLanguageItemName,
            header: Localizer.workOrderDetailsHoursGridAddLanguageItemName,
            accessor: "isAlarmJob",
            type: ColumnType.Boolean,
            sorting: true,
            textAlign: TextAlign.Center,
            editable: true,
            init: (cell) => this.initColumn(cell, true),
            callback: async (cell) => await this.onIsAlarmJobChangeAsync(cell),
            transform: (_, value) => value ? "✓" : "",
            visible: UnleashHelper.isEnabled(FeatureFlags.AlarmJobs),
        },
        {
            group: Localizer.genericAlarmJobLanguageItemName,
            header: Localizer.workOrderDetailsHoursGridPriceLanguageItemName,
            accessor: "alarmJobPrice",
            type: ColumnType.Number,
            format: "0.0",
            editable: true,
            visible: (UnleashHelper.isEnabled(FeatureFlags.AlarmJobs) && this.isAdminOrBusinessManager),
            sorting: true,
            settings: {
                infoAccessor: (workOrder) => ConstructionSiteOrWarehouse.getAlarmJobPrice(workOrder.owner, this.props.defaultPrices),
                infoHideEqual: true,
            },
            init: (cell) => this.initColumn(cell, true),
            callback: async (cell) => await this.calcCostAsync(cell),
        },
        {
            header: Localizer.tasksPanelCostLanguageItemName,
            accessor: "cost",
            format: "C",
            minWidth: 75,
            sorting: true,
            visible: this.isAdminOrBusinessManager
        },
        {
            header: Localizer.tasksPanelActionsLanguageItemName,
            minWidth: 100,
            stretch: false,
            removable: false,
            sorting: true,
            init: (cell) => this.initHoursOperations(cell),
            actions: [
                {
                    name: "save",
                    title: Localizer.tasksPanelCommitChangesLanguageItemName,
                    icon: "far save",
                    type: ActionType.Create,
                    callback: async (cell, action) => await this.processHourOperationAsync(cell, action)
                },
                {
                    name: "cancel",
                    title: Localizer.tasksPanelCancelChangesLanguageItemName,
                    icon: "far ban",
                    type: ActionType.Delete,
                    callback: async (cell, action) => await this.processHourOperationAsync(cell, action)
                },
                {
                    name: "delete",
                    title: Localizer.tasksPanelDeleteHoursLanguageItemName,
                    icon: "far trash-alt",
                    type: ActionType.Delete,
                    right: true,
                    callback: async (cell, action) => await this.processHourOperationAsync(cell, action)
                },
                {
                    name: "restore",
                    title: Localizer.taskHoursPanelRestoreDeletedHoursLanguageItemName,
                    icon: "far undo-alt",
                    type: ActionType.Create,
                    right: true,
                    callback: async (cell, action) => await this.processHourOperationAsync(cell, action)
                }
            ]
        },
    ];

    private readonly _salesEquipmentColumns: ColumnDefinition[] = [
        {
            header: "#",
            accessor: "#",
            textAlign: TextAlign.Left,
            minWidth: 40,
            stretch: false
        },
        {
            header: Localizer.workOrderEquipmentNameLanguageItemName,
            accessor: "product",
            name: "product",
            type: ColumnType.Dropdown,
            reRenderRow: true,
            settings: {
                align: DropdownAlign.Left,
                groupSelected: true,
                fetchItems: async () => this.props.products.filter(product => product.category!.catalogType === CatalogType.SalesProduct),
            },
            minWidth: 300,
            sorting: true,
            callback: async (cell) => await this.onSalesProductChangeAsync(cell),
            init: (cell) => this.initEquipmentProductOrUnitColumn(cell),
        },
        {
            header: Localizer.workOrderEquipmentDescriptionLanguageItemName,
            accessor: "description",
            type: ColumnType.Text,
            minWidth: 280,
            sorting: true,
            className: styles.equipmentDescription,
            settings: {
                // Description feature allows multi-line text editing.
                descriptionAccessor: nameof<WorkOrderEquipment>(o => o.description),
                descriptionTitle: Localizer.workOrderEquipmentDescriptionLanguageItemName,
                descriptionJustify: Justify.Right,
                descriptionMaxLength: RentaTaskConstants.bigStringLength,
                descriptionCallback: (cell: CellModel<WorkOrderEquipment>) => this.onDescriptionChangeAsync(cell)
            },
        },
        {
            header: Localizer.workOrderEquipmentAmountLanguageItemName,
            accessor: "amount",
            type: ColumnType.Number,
            reRenderRow: true,
            minWidth: 100,
            sorting: true,
            editable: true,
            format: "0.00",
            init: (cell) => this.initColumn(cell),
            callback: async (cell) => await this.calcEquipmentCostAsync(cell),
        },
        {
            header: Localizer.workOrderDetailsPanelGridProductUnitLanguageItemName,
            accessor: "unit",
            name: "unit",
            format: "ProductUnit",
            minWidth: 110,
            sorting: true,
            settings: {
                required: true,
                requiredType: DropdownRequiredType.AutoSelect,
                align: DropdownAlign.Left,
                nothingSelectedText: "-",
                fetchItems: async () => EnumProvider.getProductUnitItems(),
            },
            init: (cell) => this.initEquipmentProductOrUnitColumn(cell),
        },
        {
            header: Localizer.workOrderEquipmentPriceLanguageItemName,
            name: "price",
            accessor: "price",
            type: ColumnType.Number,
            textAlign: TextAlign.Left,
            format: "C",
            reRenderRow: true,
            sorting: true,
            editable: true,
            visible: this.isAdminOrBusinessManager,
            init: (cell) => this.initColumn(cell, true, false),
            callback: async (cell) => await this.calcEquipmentCostAsync(cell)
        },
        {
            header: Localizer.tasksPanelCostLanguageItemName,
            accessor: "cost",
            sorting: true,
            textAlign: TextAlign.Center,
            format: "C",
            minWidth: 75,
            visible: this.isAdminOrBusinessManager
        },
        {
            header: Localizer.tasksPanelActionsLanguageItemName,
            minWidth: 100,
            init: (cell) => this.initSalesEquipmentOperations(cell),
            actions: [
                {
                    name: "save",
                    title: Localizer.tasksPanelCommitChangesLanguageItemName,
                    icon: "far save",
                    type: ActionType.Create,
                    callback: async (cell, action) => await this.processEquipmentOperationAsync(cell, action)
                },
                {
                    name: "cancel",
                    title: Localizer.tasksPanelCancelChangesLanguageItemName,
                    icon: "far ban",
                    type: ActionType.Delete,
                    callback: async (cell, action) => await this.processEquipmentOperationAsync(cell, action)
                },
                {
                    name: "delete",
                    title: Localizer.tasksPanelDeleteEquipmentLanguageItemName,
                    icon: "far trash-alt",
                    type: ActionType.Delete,
                    right: true,
                    confirm: (cell: CellModel<WorkOrderEquipment>) => cell.model.id && Localizer.workOrderDetailsPanelGridEquipmentConfirmDelete.format(cell.model.product),
                    callback: async (cell, action) => await this.processEquipmentOperationAsync(cell, action)
                }
            ]
        },
    ];

    private readonly _rentalMassEquipmentColumns: ColumnDefinition[] = [
        {
            header: "#",
            accessor: "#",
            textAlign: TextAlign.Left,
            minWidth: 40,
            stretch: false
        },
        {
            header: Localizer.workOrderEquipmentNameLanguageItemName,
            accessor: "product",
            name: "product",
            type: ColumnType.Dropdown,
            minWidth: 220,
            maxWidth: 220,
            reRenderRow: true,
            settings: {
                align: DropdownAlign.Left,
                groupSelected: true,
                fetchItems: async () => this.props.products.filter(product => product.category!.catalogType === CatalogType.RentalMassProduct),
            },
            sorting: true,
            callback: async (cell) => await this.onRentalMassProductChangeAsync(cell),
            init: (cell) => this.initRentalMassEquipmentProductOrUnitColumn(cell),
        },
        {
            header: Localizer.workOrderDetailsPanelGridProductUnitLanguageItemName,
            accessor: "product.unit",
            name: "unit",
            format: "ProductUnit",
            minWidth: 110,
            maxWidth: 110,
            sorting: true,
            editable: false,
        },
        {
            header: Localizer.workOrderEquipmentDescriptionLanguageItemName,
            accessor: "description",
            type: ColumnType.Text,
            minWidth: 290,
            maxWidth: 290,
            sorting: true,
            className: styles.equipmentDescription,
            settings: {
                // Description feature allows multi-line text editing.
                descriptionAccessor: nameof<WorkOrderEquipment>(o => o.description),
                descriptionTitle: Localizer.workOrderEquipmentDescriptionLanguageItemName,
                descriptionJustify: Justify.Right,
                descriptionMaxLength: RentaTaskConstants.bigStringLength,
                descriptionCallback: (cell: CellModel<WorkOrderEquipment>) => this.onDescriptionChangeAsync(cell)
            },
        },
        {
            header: Localizer.workOrderEquipmentAmountLanguageItemName,
            accessor: "amount",
            type: ColumnType.Number,
            reRenderRow: true,
            minWidth: 75,
            sorting: true,
            editable: true,
            format: "0.00",
            init: (cell) => this.initColumn(cell),
        },
        {
            header: Localizer.genericDate,
            accessor: "rentDate",
            type: ColumnType.Date,
            minWidth: 100,
            maxWidth: 100,
            format: "D",
            sorting: true,
        },
        {
            header: Localizer.rentalEquipmentActionType,
            format: "RentalItemActionType",
            accessor: "actionType",
            type: ColumnType.Enum,
            minWidth: 110,
            maxWidth: 110,
            sorting: true,
        },
        {
            header: Localizer.tasksPanelActionsLanguageItemName,
            minWidth: 100,
            init: (cell) => this.initRentalMassEquipmentOperations(cell),
            actions: [
                {
                    name: "save",
                    title: Localizer.tasksPanelCommitChangesLanguageItemName,
                    icon: "far save",
                    type: ActionType.Create,
                    callback: async (cell, action) => await this.processEquipmentOperationAsync(cell, action)
                },
                {
                    name: "cancel",
                    title: Localizer.tasksPanelCancelChangesLanguageItemName,
                    icon: "far ban",
                    type: ActionType.Delete,
                    callback: async (cell, action) => await this.processEquipmentOperationAsync(cell, action)
                },
                {
                    name: "delete",
                    title: Localizer.tasksPanelDeleteEquipmentLanguageItemName,
                    icon: "far trash-alt",
                    type: ActionType.Delete,
                    right: true,
                    confirm: (cell: CellModel<WorkOrderEquipment>) => cell.model.id && Localizer.workOrderDetailsPanelGridEquipmentConfirmDelete.format(cell.model.product),
                    callback: async (cell, action) => await this.processEquipmentOperationAsync(cell, action)
                }
            ]
        },
    ];

    private readonly _distancesColumns: ColumnDefinition[] = [
        {
            header: "#",
            accessor: "#",
            textAlign: TextAlign.Left,
            minWidth: 40,
            stretch: false
        },
        {
            header: Localizer.workOrderDetailsPanelGridDistancesDayLanguageItemName,
            accessor: "day",
            format: "D",
            textAlign: TextAlign.Left,
            type: ColumnType.Date,
            sorting: true,
            isDefaultSorting: true,
            editable: true,
            minWidth: 90,
            init: (cell) => this.initDistanceDayColumn(cell),
        },
        {
            header: Localizer.workOrderDetailsPanelGridDistancesVehiclesLanguageItemName,
            accessor: "vehicles",
            type: ColumnType.Number,
            reRenderRow: true,
            minWidth: 100,
            editable: true,
            format: "0",
            sorting: true,
            init: (cell) => this.initColumn(cell),
        },
        {
            header: Localizer.workOrderDetailsPanelGridDistancesDistanceLanguageItemName,
            accessor: "value",
            type: ColumnType.Number,
            reRenderRow: true,
            minWidth: 100,
            editable: true,
            format: "0.0",
            sorting: true,
            init: (cell) => this.initColumn(cell),
            callback: async (cell) => await this.calcDistanceCostAsync(cell),
        },
        {
            header: Localizer.workOrderDetailsPanelGridDistancesPriceLanguageItemName,
            accessor: () => WorkOrderModel.getDistancesPrice(this.workOrder),
            textAlign: TextAlign.Left,
            format: "C",
            reRenderRow: true,
            visible: this.isAdminOrBusinessManager,
            sorting: true
        },
        {
            header: Localizer.workOrderDetailsPanelGridDistancesCostLanguageItemName,
            accessor: (model: WorkOrderDistance) => model.value * WorkOrderModel.getDistancesPrice(this.workOrder),
            textAlign: TextAlign.Center,
            format: "C",
            minWidth: 75,
            visible: this.isAdminOrBusinessManager,
            sorting: true,
        },
        {
            header: Localizer.tasksPanelActionsLanguageItemName,
            minWidth: 100,
            stretch: false,
            init: (cell) => this.initDistanceOperations(cell),
            actions: [
                {
                    name: "save",
                    title: Localizer.tasksPanelCommitChangesLanguageItemName,
                    icon: "far save",
                    type: ActionType.Create,
                    callback: async (cell, action) => await this.processDistanceOperationAsync(cell, action)
                },
                {
                    name: "cancel",
                    title: Localizer.tasksPanelCancelChangesLanguageItemName,
                    icon: "far ban",
                    type: ActionType.Delete,
                    callback: async (cell, action) => await this.processDistanceOperationAsync(cell, action)
                },
                {
                    name: "delete",
                    title: Localizer.tasksPanelDeleteDistanceLanguageItemName,
                    icon: "far trash-alt",
                    type: ActionType.Delete,
                    right: true,
                    confirm: (cell: CellModel<WorkOrderDistance>) => cell.model.id && Localizer.workOrderDetailsPanelGridDistancesConfirmDelete.format(cell.model.value, cell.model.day),
                    callback: async (cell, action) => await this.processDistanceOperationAsync(cell, action)
                }
            ]
        },
    ];

    private readonly _extraChargesColumns: ColumnDefinition[] = [
        {
            header: "#",
            accessor: "#",
            textAlign: TextAlign.Left,
            minWidth: 40,
            stretch: false
        },
        {
            header: Localizer.workOrderDetailsPanelGridExtraChargesTypeLanguageItemName,
            accessor: "extraChargeType",
            type: ColumnType.Dropdown,
            transform: WorkOrderDetailsPanel.transformExtraChargeType,
            reRenderRow: true,
            sorting: true,
            settings: {
                align: DropdownAlign.Left,
                groupSelected: true,
                fetchItems: async (cell) => this.getExtraChargeTypes(cell),
            },
            minWidth: 235,
            maxWidth: 235,
            callback: async (cell) => await this.onExtraChargeTypeChangeAsync(cell),
            init: (cell) => this.initExtraChargeTypeColumn(cell),
        },
        {
            header: Localizer.workOrderDetailsPanelGridExtraChargesUnitLanguageItemName,
            format: "ExtraChargeTypeUnit",
            accessor: "extraChargeType.unit",
            type: ColumnType.Enum,
            minWidth: 110,
            maxWidth: 110,
            sorting: true,
            init: (cell) => this.initExtraChargeUnitColumn(cell),
        },
        {
            header: Localizer.genericCommentLanguageItemName,
            accessor: "description",
            type: ColumnType.Text,
            wordBreak: true,
            minWidth: 280,
            maxWidth: 280,
            sorting: true,
        },
        {
            header: Localizer.genericDateLanguageItemName,
            accessor: "extraChargeDate",
            type: ColumnType.Date,
            minWidth: 75,
            format: "D",
            sorting: true,
            init: (cell) => this.initExtraChargeDateColumn(cell),
        },
        {
            header: Localizer.workOrderDetailsPanelGridExtraChargesAmountLanguageItemName,
            accessor: "amount",
            type: ColumnType.Number,
            reRenderRow: true,
            minWidth: 75,
            editable: true,
            format: "0.00",
            sorting: true,
            init: (cell) => this.initColumn(cell),
            callback: async (cell) => await this.calcExtraChargeCostAsync(cell),
        },
        {
            header: Localizer.workOrderEquipmentPriceLanguageItemName,
            name: "price",
            accessor: "price",
            type: ColumnType.Number,
            format: "C",
            reRenderRow: true,
            editable: true,
            visible: this.isAdminOrBusinessManager,
            minWidth: 75,
            sorting: true,
            init: (cell) => this.initColumn(cell, true, false),
            callback: async (cell) => await this.calcExtraChargeCostAsync(cell)
        },
        {
            header: Localizer.tasksPanelCostLanguageItemName,
            accessor: "cost",
            textAlign: TextAlign.Center,
            format: "C",
            minWidth: 75,
            sorting: true,
            visible: this.isAdminOrBusinessManager
        },
        {
            header: Localizer.tasksPanelActionsLanguageItemName,
            minWidth: 100,
            init: (cell) => this.initExtraChargeOperations(cell),
            actions: [
                {
                    name: "save",
                    title: Localizer.tasksPanelCommitChangesLanguageItemName,
                    icon: "far save",
                    type: ActionType.Create,
                    callback: async (cell, action) => await this.processExtraChargeOperationAsync(cell, action)
                },
                {
                    name: "cancel",
                    title: Localizer.tasksPanelCancelChangesLanguageItemName,
                    icon: "far ban",
                    type: ActionType.Delete,
                    callback: async (cell, action) => await this.processExtraChargeOperationAsync(cell, action)
                },
                {
                    name: "delete",
                    title: Localizer.tasksPanelDeleteExtraChargeLanguageItemName,
                    icon: "far trash-alt",
                    type: ActionType.Delete,
                    right: true,
                    callback: async (cell, action) => await this.processExtraChargeOperationAsync(cell, action)
                }
            ]
        },
    ];

    private readonly _rentalEquipmentsColumns: ColumnDefinition[] = [
        {
            header: "#",
            accessor: "#",
            textAlign: TextAlign.Left,
            minWidth: 40,
            stretch: false
        },
        {
            header: Localizer.genericName,
            accessor: "rentalItemName",
            type: ColumnType.Text,
            reRenderRow: true,
            sorting: true,
            minWidth: 220,
            maxWidth: 220,
            // setting "editable" does nothing.
            init: (cell: CellModel<any>) => {cell.readonly = ch.isFinland;},
        },
        {
            header: Localizer.genericExternalId,
            accessor: "rentalItemExternalId",
            type: ColumnType.Text,
            reRenderRow: true,
            sorting: true,
            minWidth: 110,
            maxWidth: 110,
            // setting "editable" does nothing.
            init: (cell: CellModel<any>) => {cell.readonly = ch.isFinland;},
        },
        {
            header: Localizer.genericCommentLanguageItemName,
            accessor: "comment",
            type: ColumnType.Text,
            wordBreak: true,
            minWidth: 365,
            maxWidth: 365,
            sorting: true,
        },
        {
            header: Localizer.genericDate,
            accessor: "rentDate",
            type: ColumnType.Date,
            minWidth: 100,
            maxWidth: 100,
            format: "D",
            sorting: true,
        },
        {
            header: Localizer.rentalEquipmentActionType,
            format: "RentalItemActionType",
            accessor: "actionType",
            type: ColumnType.Enum,
            minWidth: 110,
            maxWidth: 110,
            sorting: true,
        },
        {
            header: Localizer.tasksPanelActionsLanguageItemName,
            minWidth: 100,
            init: (cell) => this.initRentalEquipmentsOperations(cell),
            actions: [
                {
                    name: "save",
                    title: Localizer.tasksPanelCommitChangesLanguageItemName,
                    icon: "far save",
                    type: ActionType.Create,
                    callback: async (cell, action) => await this.processRentalEquipmentOperationAsync(cell, action)
                },
                {
                    name: "cancel",
                    title: Localizer.tasksPanelCancelChangesLanguageItemName,
                    icon: "far ban",
                    type: ActionType.Delete,
                    callback: async (cell, action) => await this.processRentalEquipmentOperationAsync(cell, action)
                },
                {
                    name: "delete",
                    title: Localizer.tasksPanelDeleteExtraChargeLanguageItemName,
                    icon: "far trash-alt",
                    type: ActionType.Delete,
                    right: true,
                    callback: async (cell, action) => await this.processRentalEquipmentOperationAsync(cell, action)
                }
            ]
        },
    ];

    private readonly _workOrderStatusesColumns: ColumnDefinition[] = [
        {
            header: "#",
            accessor: "#",
            textAlign: TextAlign.Left,
            minWidth: 40,
            stretch: false
        },
        {
            header: Localizer.genericStatusLanguageItemName,
            accessor: nameof.full<WorkOrderStatusModel>(workOrderStatus => workOrderStatus.status),
            format: "WorkOrderStatus",
            type: ColumnType.Enum,
            minWidth: 150,
            maxWidth: 250,
            sorting: true
        },
        {
            header: Localizer.workOrderDetailsPanelWorkOrderStatusesChangedAtLanguageItemName,
            accessor: nameof.full<WorkOrderStatusModel>(workOrderStatus => workOrderStatus.createdAt),
            format: "D",
            minWidth: 80,
            sorting: true,
        },
        {
            header: Localizer.workOrderDetailsPanelWorkOrderStatusesChangedByLanguageItemName,
            accessor: nameof.full<WorkOrderStatusModel>(workOrderStatus => workOrderStatus.createdBy),
            minWidth: 150,
            noWrap: true,
            sorting: true,
            init: (cell: CellModel<WorkOrderStatusModel>) => this.initCreatedByColumn(cell),
            transform: (cell): string => this.transformCreatedByCell(cell),
            settings: {
                descriptionAccessor: nameof<WorkOrderStatusModel>(o => o.comment),
                descriptionTitle: Localizer.genericCommentLanguageItemName,
                descriptionJustify: Justify.Right,
                descriptionMaxLength: 1024,
            },
        },
    ];

    private readonly _workOrderExternalNotificationsColumns: ColumnDefinition[] = [
        {
            header: "#",
            accessor: "#",
            textAlign: TextAlign.Left,
            minWidth: 40,
            stretch: false
        },
        {
            header: Localizer.workOrderDetailsPanelGridExternalNotificationsSender,
            accessor: nameof.full<WorkOrderExternalNotificationModel>(notification => notification.sender),
            minWidth: 150,
            maxWidth: 250,
            sorting: true,
            transform: (cell): string => this.transformSenderCell(cell),
        },
        {
            header: Localizer.externalNotificationModalRecipientEmail,
            accessor: nameof.full<WorkOrderExternalNotificationModel>(notification => notification.recipientEmail),
            minWidth: 150,
            maxWidth: 250,
            sorting: true
        },
        {
            header: Localizer.externalNotificationModalRequestDate,
            accessor: nameof.full<WorkOrderExternalNotificationModel>(notification => notification.requestDate),
            format: "D",
            minWidth: 80,
        },
        {
            header: Localizer.genericComment,
            accessor: nameof.full<WorkOrderExternalNotificationModel>(notification => notification.comment),
            minWidth: 150,
            noWrap: false,
        },
        {
            header: Localizer.tasksPanelActionsLanguageItemName,
            minWidth: 170,
            init: (cell) => this.initExternalNotificationOperations(cell),
            actions: [
                {
                    name: "download",
                    title: Localizer.genericActionDownload,
                    type: ColumnActionType.Download,
                    callback: async (cell, action) => {
                        await this.processExternalNotificationOperation(cell, action)
                    }
                },
                {
                    name: "preview",
                    title: Localizer.genericActionPreview,
                    type: ColumnActionType.Preview,
                    callback: async (cell, action) => this.processExternalNotificationOperation(cell, action),
                },
            ],
        },
    ];

    private initExternalNotificationOperations(cell: CellModel<WorkOrderExternalNotificationModel>): void {
        const downloadAction: CellAction<WorkOrderExternalNotificationModel> = cell.actions[0];
        const previewAction: CellAction<WorkOrderExternalNotificationModel> = cell.actions[1];
        downloadAction.visible = true;
        previewAction.visible = true;
    }

    private async processExternalNotificationOperation(
        cell: CellModel<WorkOrderExternalNotificationModel>,
        action: CellAction<WorkOrderExternalNotificationModel>,
    ): Promise<void> {
        switch (action.action.name) {
            case "download":
                const downloadFile: FileModel = await this.postAsync("api/workOrders/getWorkOrderAttachmentFile", cell.model.fileId);
                ch.download(downloadFile);
                break;
            case "preview":
                await this.externalNotificationModal.openPreviewAsync(cell.model);
                break;
        }
    }

    private static initializeFilters(): ToolbarModel {
        return UserInteractionDataStorage.getFilters(new ToolbarModel());
    }

    private get workOrder(): WorkOrderModel {
        return this.props.workOrder;
    }

    private get extraChargeGridModel(): GridModel<WorkOrderExtraCharge> {
        return this._extraChargesGridRef.current!.model;
    }

    private get rentalEquipmentGridModel(): GridModel<WorkOrderRentalItemModel> {
        return this._rentalEquipmentsGridRef.current!.model;
    }

    private get salesEquipmentGridModel(): GridModel<WorkOrderEquipment> {
        return this._salesEquipmentGridRef.current!.model;
    }

    private get rentalMassEquipmentGridModel(): GridModel<WorkOrderEquipment> {
        return this._rentalMassEquipmentGridRef.current!.model;
    }

    private get hoursGridModel(): GridModel<UserSalaryHour> {
        return this._hoursGridRef.current!.model;
    }

    private get distancesGridModel(): GridModel<WorkOrderDistance> {
        return this._distancesGridRef.current!.model;
    }

    private get attachmentsGridModel(): GridModel<WorkReportAttachment> {
        return this._attachmentsGridRef.current!.gridRef!.model;
    }

    private get externalNotificationsGridModel(): GridModel<WorkOrderExternalNotificationModel> {
        return this._externalNotificationsGridRef.current!.model;
    }

    private get isAdminOrBusinessManager(): boolean {
        const context: UserContext = ch.getContext() as UserContext;
        return context.isAdmin || context.isBusinessManager;
    }

    private get editability(): WorkOrderEditability {
        if ((this.props.readonly === true) || (this.isSpinning())) {
            return WorkOrderEditability.Readonly;
        }

        const editability: IWorkOrderEditability = WorkOrderModel.getEditability(this.workOrder);

        return (editability.editable)
            ? WorkOrderEditability.Editable
            : (this.isAdminOrBusinessManager) && (editability.pricesEditable)
                ? WorkOrderEditability.PriceEditable
                : WorkOrderEditability.Readonly;
    }

    public get hasNewDistancesRow(): boolean {
        return (this.distancesGridModel.rows.some(row => !row.deleted && !row.model.id));
    }

    public get hasNewHoursRow(): boolean {
        return (this.hoursGridModel.rows.some(row => !row.deleted && !row.model.id));
    }

    public get hasNewEquipmentRow(): boolean {
        return (this.salesEquipmentGridModel.rows.some(row => !row.deleted && !row.model.id));
    }

    public get hasNewRentalMassEquipmentRow(): boolean {
        return (this.rentalMassEquipmentGridModel.rows.some(row => !row.deleted && !row.model.id));
    }

    public get hasNewExtraChargeRow(): boolean {
        return (this.extraChargeGridModel.rows.some(row => !row.deleted && !row.model.id));
    }

    private get creatingNew(): boolean {
        return (!this.workOrder.id);
    }

    // Methods

    private static transformExtraChargeType(cell: CellModel<unknown>, value: any): string {
        return (cell.readonly)
            ? TransformProvider.extraChargeTypeToString(value)
            // Grid types only allow the returned value to be a string, but it has been like this forever.
            : TransformProvider.toSelectListItem(value) as any;
    }

    // Returns existing extra charge types and marked as deleted that are assigned to a work order.
    private getExtraChargeTypes(cell: CellModel<WorkOrderExtraCharge>): ExtraChargeType[] {
        return this.state.extraChargeTypes.where(type => !type.deleted || (cell.model.extraChargeTypeId == type.id && type.deleted))
    }

    private initColumn(
        cell: CellModel<UserSalaryHour> | CellModel<WorkOrderDistance>,
        priceColumn: boolean = false,
        onlyNewIsEditable: boolean = false
    ): void {
        const model: UserSalaryHour | WorkOrderEquipment | WorkOrderDistance = cell.row.model;
        const isNew: boolean = (!model.id);
        const editable: boolean = (
            ((this.editability == WorkOrderEditability.Editable) || ((priceColumn) && (this.editability == WorkOrderEditability.PriceEditable))) &&
            ((!onlyNewIsEditable) || (isNew))
        );
        cell.readonly = (cell.isDeleted) || (!editable);
    }

    private initHoursDayAndUserColumn(cell: CellModel<UserSalaryHour>): void {
        const model: UserSalaryHour = cell.model;
        const isNew: boolean = (!model.id);
        const editable: boolean = (this.editability === WorkOrderEditability.Editable);
        
        this.initColumn(cell, false, true);

        const isCommentEnabled: boolean = UnleashHelper.isEnabled(FeatureFlags.UserSalaryHoursComment);

        if (cell.descriptionAction) {
            cell.descriptionAction.visible = !isNew && isCommentEnabled;
            cell.descriptionReadonly = (cell.isDeleted) || (!editable) || (isNew);
        }
        
        cell.valid = this.isHoursDayAndUserValid(cell);
    }

    private initEquipmentProductOrUnitColumn(cell: CellModel<WorkOrderEquipment>): void {
        const model: WorkOrderEquipment = cell.row.model;
        const isNew: boolean = (!model.id);
        const customProduct: boolean = (model.type == EquipmentType.Custom);
        const editable: boolean = (this.editability == WorkOrderEditability.Editable) && ((customProduct) || (isNew));
        cell.readonly = (cell.isDeleted) || (!editable);
        cell.valid = this.isEquipmentProductValid(cell);
    }

    private initRentalMassEquipmentProductOrUnitColumn(cell: CellModel<WorkOrderEquipment>): void {
        const model: WorkOrderEquipment = cell.row.model;
        const isNew: boolean = (!model.id);
        const editable: boolean = (this.editability === WorkOrderEditability.Editable) && (isNew);
        cell.readonly = (cell.isDeleted) || (!editable);
    }

    private initExtraChargeTypeColumn(cell: CellModel<WorkOrderExtraCharge>): void {
        const model: WorkOrderExtraCharge = cell.row.model;
        const isNew: boolean = (!model.id);
        const editable: boolean = (this.editability == WorkOrderEditability.Editable) || ((isNew));
        cell.readonly = (cell.isDeleted) || (!editable);
    }

    private initExtraChargeUnitColumn(cell: CellModel<WorkOrderExtraCharge>): void {
        cell.readonly = true;
    }

    private initExtraChargeDateColumn(cell: CellModel<WorkOrderExtraCharge>): void {
        const model: WorkOrderExtraCharge = cell.row.model;

        const isNew: boolean = (!model.id);

        const editable: boolean = (this.editability == WorkOrderEditability.Editable) || ((isNew));
        cell.readonly = (cell.isDeleted) || (!editable);
    }

    private initDistanceDayColumn(cell: CellModel<WorkOrderDistance>): void {
        this.initColumn(cell, false, true);
        cell.valid = this.isDistanceDayValid(cell);
    }

    private initRow(row: RowModel<WorkOrderModel | WorkOrderEquipment>): void {
        row.className = WorkOrderModel.getBackgroundStyle(this.workOrder);
    }

    private iniEquipmentRow(row: RowModel<WorkOrderEquipment>): void {
        row.className = WorkOrderModel.getBackgroundStyle(this.workOrder);

        const model: WorkOrderEquipment = row.model;
        const isCustomProduct: boolean = (model.type == EquipmentType.Custom);

        const productCell: CellModel<WorkOrderEquipment> = row.get("product");
        const unitCell: CellModel<WorkOrderEquipment> = row.get("unit");
        const priceCell: CellModel<WorkOrderEquipment> = row.get("price");

        if (isCustomProduct) {
            productCell.type = ColumnType.Text;
            productCell.editable = true;
            productCell.accessor = "name";

            unitCell.type = ColumnType.Dropdown;
            unitCell.editable = true;
            unitCell.accessor = "unit";

            priceCell.column.settings.infoAccessor = null;
        } else {
            productCell.type = ColumnType.Dropdown;
            productCell.editable = true;
            productCell.accessor = "product";

            unitCell.type = ColumnType.Custom;
            unitCell.editable = false;
            unitCell.accessor = "product.unit";

            priceCell.column.settings.infoAccessor = "product.price";
        }
    }

    private initCreatedByColumn(cell: CellModel<WorkOrderStatusModel>): void {
        const model: WorkOrderStatusModel = cell.model;
        
        cell.descriptionReadonly = true;

        if (cell.descriptionAction && !model.comment) {
            cell.descriptionAction.visible = false;
        }
    }

    private transformCreatedByCell(cell: CellModel<WorkOrderStatusModel>): string {
        const model: WorkOrderStatusModel = cell.model;

        return (model.createdBy.email === "package.console@renta.fi" || model.createdBy.email === "migration.console@renta.fi")
            ? Localizer.genericSystem
            : TransformProvider.userToString(model.createdBy);
    }

    private transformSenderCell(cell: CellModel<WorkOrderExternalNotificationModel>): string {
        const model: WorkOrderExternalNotificationModel = cell.model;
        return (model.sender) ? TransformProvider.userToString(model.sender) : "";
    }

    private async invokeChangeAsync(): Promise<void> {
        // sync equipment, userSalaryHours & distances
        this.workOrder.userSalaryHours = this.hoursGridModel.rows.where(item => !item.deleted).map(item => item.model);
        this.workOrder.distances = this.distancesGridModel.rows.where(item => !item.deleted).map(item => item.model);
        this.workOrder.attachments = this.attachmentsGridModel.rows.where(item => !item.deleted).map(item => item.model);

        const salesEquipments: WorkOrderEquipment[] = this.salesEquipmentGridModel.rows.where(item => !item.deleted).map(item => item.model);
        const rentalMassEquipments: WorkOrderEquipment[] = UnleashHelper.isEnabled(FeatureFlags.RentalMassProducts)
            ? this.rentalMassEquipmentGridModel.rows.where(item => !item.deleted).map(item => item.model)
            : [];
        this.workOrder.equipments = salesEquipments.concat(rentalMassEquipments);
        
        if (UnleashHelper.isEnabled(FeatureFlags.RentalEquipment)) {
            this.workOrder.rentalEquipments = this.rentalEquipmentGridModel.rows.where(item => !item.deleted).map(item => item.model);
        }
        
        if (UnleashHelper.isEnabled(FeatureFlags.ExtraCharge)) {
            this.workOrder.extraCharges = this.extraChargeGridModel.rows.where(item => !item.deleted).map(item => item.model);
        }

        await this.props.onChange(false);
    }

    private async onIsAlarmJobChangeAsync(cell: CellModel<UserSalaryHour>): Promise<void> {
        const model: UserSalaryHour = cell.model;

        model.isAlarmJob = cell.value;

        await this.calcCostAsync(cell);
    }

    private async calcCostAsync(cell: CellModel<UserSalaryHour>): Promise<void> {
        const model: UserSalaryHour = cell.model;
        const cost: number = UserSalaryHour.calcCost(model);
        if (cost != model.cost) {
            model.cost = cost;
            await cell.row.reRenderAsync();
            await this.invokeChangeAsync();
        }
    }

    private async calcEquipmentCostAsync(cell: CellModel<WorkOrderEquipment>): Promise<void> {
        const model: WorkOrderEquipment = cell.model;
        const cost: number = model.amount * model.price;
        if (cost != model.cost) {
            model.cost = cost;
            await cell.row.reRenderAsync();
            await this.invokeChangeAsync();
        }
    }

    private async calcExtraChargeCostAsync(cell: CellModel<WorkOrderExtraCharge>): Promise<void> {
        const model: WorkOrderExtraCharge = cell.model;
        const cost: number = model.amount * model.price;
        if (cost != model.cost) {
            model.cost = cost;
            await cell.row.reRenderAsync();
            await this.invokeChangeAsync();
        }
    }

    private async calcDistanceCostAsync(cell: CellModel<WorkOrderDistance>): Promise<void> {
        await cell.row.reRenderAsync();
        await this.invokeChangeAsync();
    }

    private async onFiltersChange(filters: ToolbarModel): Promise<void> {
        await this.setState({filters});

        UserInteractionDataStorage.setFilters(filters);

        await this.attachmentsGridModel.reloadAsync();
    }

    private async onDescriptionChangeAsync(cell: CellModel<WorkOrderEquipment>): Promise<void> {
        // re-render after description was edited
        await cell.reRenderAsync();
    }

    private async onUserSalaryHourDescriptionChangeAsync(cell: CellModel<UserSalaryHour>): Promise<void> {
        const model: UserSalaryHour = cell.model;
        
        if (model.id) {
            const request = new SaveUserSalaryHourRequest();
            request.userSalaryHourId = model.id;
            request.constructionSiteOrWarehouseId = model.ownerId;
            request.workOrderId = model.workOrderId!;
            request.normalHours = model.normalHours;
            request.overtime50Hours = model!.overtime50Hours!;
            request.overtime100Hours = model!.overtime100Hours!;
            request.userSalaryDayId = model.userSalaryDayId;
            request.userSalaryHourId = model.id;
            request.hoursPrice = model.hoursPrice;
            request.isAlarmJob = model.isAlarmJob;
            request.AlarmJobPrice = model.alarmJobPrice;
            request.comment = model.comment?.trim() || null;

            await cell.grid.postAsync("api/employees/saveUserSalaryHour", request);

            await cell.reRenderAsync();
        }
    }

    private async fetchAttachments(pageNumber: number,
                                   pageSize: number,
                                   sortColumnName: string | null,
                                   sortDirection: SortDirection | null): Promise<IPagedList<WorkReportAttachment>> {

        UserInteractionDataStorage.setFilters(sortColumnName, "WorkReportAttachments.SortColumn");
        UserInteractionDataStorage.setFilters(sortDirection, "WorkReportAttachments.SortDirection");

        const request = new ListWorkOrderAttachmentsRequest();
        request.workOrderId = this.workOrder.id;
        request.public = this.state.filters.public;
        request.types = this.state.filters.types;
        request.pageNumber = pageNumber;
        request.pageSize = pageSize;
        request.sortColumnName = sortColumnName;
        request.sortDirection = sortDirection;

        const attachmentsPagedList: IPagedList<WorkReportAttachment> = await this.attachmentsGridModel.postAsync("/api/WorkOrders/listWorkOrderAttachmentFiles", request);

        this.workOrder.attachments = attachmentsPagedList.items;

        return attachmentsPagedList;
    }

    private async fetchEquipmentAsync(
        sender: IBaseComponent,
        sortColumnName: string | null,
        sortDirection: SortDirection | null) : Promise<WorkOrderEquipment[]> {

        UserInteractionDataStorage.setFilters(sortColumnName, "WorkOrderEquipment.SortColumn");
        UserInteractionDataStorage.setFilters(sortDirection, "WorkOrderEquipment.SortDirection");

        const request = new GetWorkOrderEquipmentRequest();
        request.workOrderId = this.workOrder.id;
        request.sortColumnName = sortColumnName;
        request.sortDirection = sortDirection;

        const equipment: WorkOrderEquipment[] = await sender.postAsync("/api/WorkOrders/getWorkOrderEquipment", request);
        this.workOrder.equipments = equipment;
        return equipment.filter(x => x.product?.category?.catalogType === CatalogType.SalesProduct || x.type === EquipmentType.Custom);
    }

    private async fetchRentalMassEquipmentAsync(
        sender: IBaseComponent,
        sortColumnName: string | null,
        sortDirection: SortDirection | null) : Promise<WorkOrderEquipment[]> {

        UserInteractionDataStorage.setFilters(sortColumnName, "WorkOrderRentalMassEquipment.SortColumn");
        UserInteractionDataStorage.setFilters(sortDirection, "WorkOrderRentalMassEquipment.SortDirection");

        const request = new GetWorkOrderEquipmentRequest();
        request.workOrderId = this.workOrder.id;
        request.sortColumnName = sortColumnName;
        request.sortDirection = sortDirection;

        const equipment: WorkOrderEquipment[] = await sender.postAsync("/api/WorkOrders/getWorkOrderEquipment", request);
        this.workOrder.equipments = equipment;
        return equipment.filter(x => x.product?.category?.catalogType === CatalogType.RentalMassProduct);
    }

    private async fetchUserSalaryHoursAsync(
        sender: IBaseComponent,
        sortColumnName: string | null,
        sortDirection: SortDirection | null): Promise<UserSalaryHour[]> {

        UserInteractionDataStorage.setFilters(sortColumnName, "WorkOrderHours.SortColumn");
        UserInteractionDataStorage.setFilters(sortDirection, "WorkOrderHours.SortDirection");

        const userSalaryHours: UserSalaryHour[] = await sender.postAsync("/api/WorkOrders/getWorkOrderHours", this.workOrder.id);
        this.workOrder.userSalaryHours = userSalaryHours;
        return userSalaryHours;
    }

    private async fetchDistancesAsync(
        sender: IBaseComponent,
        sortColumnName: string | null,
        sortDirection: SortDirection | null): Promise<WorkOrderDistance[]> {

        UserInteractionDataStorage.setFilters(sortColumnName, "WorkOrderDistances.SortColumn");
        UserInteractionDataStorage.setFilters(sortDirection, "WorkOrderDistances.SortDirection");

        if (this.workOrder.distances) {
            return this.workOrder.distances;
        } else {
            const distances: WorkOrderDistance[] = await sender.postAsync("/api/WorkOrders/getWorkOrderDistances", this.workOrder.id);
            this.workOrder.distances = distances;
            return distances;
        }
    }

    private async fetchExtraChargesAsync(
        sender: IBaseComponent,
        sortColumnName: string | null,
        sortDirection: SortDirection | null): Promise<WorkOrderExtraCharge[]> {

        UserInteractionDataStorage.setFilters(sortColumnName, "WorkReportExtraCharges.SortColumn");
        UserInteractionDataStorage.setFilters(sortDirection, "WorkReportExtraCharges.SortDirection");

        const extraChargeTypes: ExtraChargeType[] = await sender.postAsync("api/ExtraChargeType/getExtraChargeTypes", true);
        this.setState({extraChargeTypes})

        if (this.workOrder.extraCharges) {
            return this.workOrder.extraCharges;
        } else {
            const extraCharges: WorkOrderExtraCharge[] = await sender.postAsync("/api/workOrderExtraCharge/getWorkOrderExtraCharges", this.workOrder.id);

            this.workOrder.extraCharges = extraCharges;

            return extraCharges;
        }
    }

    private async fetchRentalEquipmentsAsync(
        sender: IBaseComponent,
        sortColumnName: string | null,
        sortDirection: SortDirection | null): Promise<WorkOrderRentalItemModel[]> {

        UserInteractionDataStorage.setFilters(sortColumnName, "WorkReportExtraCharges.SortColumn");
        UserInteractionDataStorage.setFilters(sortDirection, "WorkReportExtraCharges.SortDirection");

        if (this.workOrder.rentalEquipments) {
            return this.workOrder.rentalEquipments;
        } else {
            const rentalEquipments: WorkOrderRentalItemModel[] = await sender.postAsync("/api/workOrders/getWorkOrderRentalItems", this.workOrder.id);

            this.workOrder.rentalEquipments = rentalEquipments;

            return rentalEquipments;
        }
    }

    private async processHourOperationAsync(cell: CellModel<UserSalaryHour>, action: CellAction<UserSalaryHour>): Promise<void> {

        const model: UserSalaryHour = cell.model;

        switch (action.action.name) {
            case "save":
                if (!model.id) {

                    const request = new AddMounterHoursRequest();
                    request.workOrderId = model.workOrderId!;
                    request.normalHours = model.normalHours;
                    request.overtime50Hours = model!.overtime50Hours!;
                    request.overtime100Hours = model!.overtime100Hours!;
                    request.userId = model.user!.id;
                    request.hoursPrice = model.hoursPrice;
                    request.day = model.day;
                    request.isAlarmJob = model.isAlarmJob;
                    request.alarmJobPrice = model.alarmJobPrice;
                    request.comment = model.comment?.trim() || null;

                    cell.row.model = await cell.grid.postAsync("api/employees/addMounterHours", request);

                    await cell.row.bindAsync();

                } else {

                    const request = new SaveUserSalaryHourRequest();
                    request.userSalaryHourId = model.id;
                    request.constructionSiteOrWarehouseId = model.ownerId;
                    request.workOrderId = model.workOrderId!;
                    request.normalHours = model.normalHours;
                    request.overtime50Hours = model!.overtime50Hours!;
                    request.overtime100Hours = model!.overtime100Hours!;
                    request.userSalaryDayId = model.userSalaryDayId;
                    request.userSalaryHourId = model.id;
                    request.hoursPrice = model.hoursPrice;
                    request.isAlarmJob = model.isAlarmJob;
                    request.AlarmJobPrice = model.alarmJobPrice;
                    request.comment = model.comment?.trim() || null;

                    await cell.grid.postAsync("api/employees/saveUserSalaryHour", request);

                    await cell.row.saveAsync();
                }

                await this.invokeChangeAsync();
                break;
            case "cancel":
                await cell.row.cancelAsync();
                break;
            case "delete":
                const deletePermanently: boolean = (!model.id);
                if (deletePermanently) {
                    await cell.grid.deleteAsync(cell.row.index);
                } else {
                    await cell.grid.postAsync("api/employees/deleteUserSalaryHour", model.id);

                    await cell.row.setDeletedAsync(true);
                }

                await this.invokeChangeAsync();
                break;
            case "restore":
                await cell.row.setDeletedAsync(false);

                const restoreOnServer = (model.id != "");

                if (restoreOnServer) {
                    await cell.grid.postAsync("api/employees/restoreUserSalaryHour", model.id);
                }

                await cell.row.setDeletedAsync(false);

                await this.invokeChangeAsync();
                break;
        }
    }

    private async processEquipmentOperationAsync(cell: CellModel<WorkOrderEquipment>, action: CellAction<WorkOrderEquipment>): Promise<void> {

        const model: WorkOrderEquipment = cell.model;

        switch (action.action.name) {
            case "save":
                const request = new SaveWorkOrderEquipmentRequest();
                request.id = (model.id) ? model.id : null;
                request.workOrderId = model.workOrderId;
                request.productId = (model.product) ? model.product.id : null;
                request.unit = model.unit;
                request.customUnit = model.customUnit;
                request.name = model.name;
                request.description = model.description;
                request.amount = model.amount;
                request.price = model.price;
                request.rentDate = model.rentDate;
                request.actionType = model.actionType;
                

                cell.row.model = await cell.grid.postAsync("api/workOrders/SaveWorkOrderEquipment", request);

                await cell.row.bindAsync();
                await this.invokeChangeAsync();
                break;
            case "cancel":
                await cell.row.cancelAsync();
                break;
            case "delete":
                const isNew: boolean = !model.id;

                if (!isNew) {
                    await cell.grid.postAsync("api/workOrders/DeleteWorkOrderEquipment", model.id);
                }

                await cell.grid.deleteAsync(cell.row.index);

                await this.invokeChangeAsync();
                break;
        }
    }

    private async processExtraChargeOperationAsync(cell: CellModel<WorkOrderExtraCharge>, action: CellAction<WorkOrderExtraCharge>): Promise<void> {

        const model: WorkOrderExtraCharge = cell.model;

        switch (action.action.name) {
            case "save":
                const request = new SaveWorkOrderExtraChargeRequest();
                request.id = (model.id) ? model.id : null;
                request.workOrderId = model.workOrderId;
                request.extraChargeTypeId = model.extraChargeTypeId;
                request.description = model.description;
                request.amount = model.amount;
                request.price = model.price;
                request.extraChargeDate = model.extraChargeDate;

                cell.row.model = await cell.grid.postAsync("api/workOrderExtraCharge/saveWorkOrderExtraCharge", request);

                await cell.row.bindAsync();
                await this.invokeChangeAsync();
                break;
            case "cancel":
                await cell.row.cancelAsync();
                break;
            case "delete":
                const isNew: boolean = !model.id;

                if (!isNew) {
                    await cell.grid.postAsync("api/workOrderExtraCharge/deleteWorkOrderExtraCharge", model.id);
                }

                await cell.grid.deleteAsync(cell.row.index);

                await this.invokeChangeAsync();
                break;
        }
    }

    private readonly saveWorkOrderRentalItemAsync = async (sender: PostAsync<WorkOrderRentalItemModel, WorkOrderRentalItemModel>, model: WorkOrderRentalItemModel): Promise<WorkOrderRentalItemModel> => {
        return await sender.postAsync("api/workOrders/saveWorkOrderRentalItem", model);
    };

    private async processRentalEquipmentOperationAsync(cell: CellModel<WorkOrderRentalItemModel>, action: CellAction<WorkOrderRentalItemModel>): Promise<void> {

        const model: WorkOrderRentalItemModel = cell.model;

        switch (action.action.name) {
            case "save":
                cell.row.model = await this.saveWorkOrderRentalItemAsync(cell.grid, model);
                await cell.row.bindAsync();
                await this.invokeChangeAsync();
                break;
            case "cancel":
                await cell.row.cancelAsync();
                break;
            case "delete":
                const isNew: boolean = !model.id;

                if (!isNew) {
                    await cell.grid.postAsync("api/workOrders/deleteWorkOrderRentalItem", model.id);
                }

                await cell.grid.deleteAsync(cell.row.index);

                await this.invokeChangeAsync();
                break;
        }
    }

    private async processDistanceOperationAsync(cell: CellModel<WorkOrderDistance>, action: CellAction<WorkOrderDistance>): Promise<void> {

        const model: WorkOrderDistance = cell.model;

        switch (action.action.name) {
            case "save":
                const request = new SaveWorkOrderDistanceRequest();
                request.id = (model.id) ? model.id : null;
                request.workOrderId = model.workOrderId;
                request.day = model.day;
                request.vehicles = model.vehicles;
                request.value = model.value;

                cell.row.model = await cell.grid.postAsync("api/workOrders/SaveWorkOrderDistance", request);

                await cell.row.bindAsync();
                await this.invokeChangeAsync();
                break;
            case "cancel":
                await cell.row.cancelAsync();
                break;
            case "delete":
                const isNew: boolean = !model.id;

                if (!isNew) {
                    await cell.grid.postAsync("api/workOrders/DeleteWorkOrderDistance", model.id);
                }

                await cell.grid.deleteAsync(cell.row.index);

                await this.invokeChangeAsync();
                break;
        }
    }

    private async processAttachmentOperationAsync(cell: CellModel<WorkReportAttachment>, action: CellAction<WorkReportAttachment>): Promise<void> {

        const model: WorkReportAttachment = cell.model;

        switch (action.action.name) {
            case "save":
                const request = new SaveWorkOrderAttachmentRequest();
                request.id = model.id;
                request.workOrderId = this.workOrder.id;
                request.public = model.public;
                request.type = AttachmentType.WorkOrderAttachment;
                request.file = model.file;

                await cell.grid.postAsync("api/workOrders/saveWorkOrderAttachment", request);

                await cell.row.bindAsync();
                await this.invokeChangeAsync();
                break;
            case "cancel":
                await cell.row.cancelAsync();
                break;
            case "delete":
                const isNew: boolean = !model.id;

                if (!isNew) {
                    await cell.grid.postAsync("api/workOrders/removeWorkOrderAttachmentByFileId", model.fileId);
                }

                await cell.grid.deleteAsync(cell.row.index);

                await this.invokeChangeAsync();
                break;
            case "preview":
                const previewFile: FileModel = await this.postAsync("api/workOrders/getWorkOrderAttachmentFile", model.fileId);

                if (RentaTaskConstants.imageFileTypes.includes(previewFile.type)) {
                    await this._previewPictureDocumentRef.current?.openAsync(previewFile);
                } else {
                    await ch.documentPreviewAsync("api/workOrders/getWorkOrderAttachmentFile", model.fileId);
                }
                break;
            case "download":
                const downloadFile: FileModel = await this.postAsync("api/workOrders/getWorkOrderAttachmentFile", model.fileId);
                ch.download(downloadFile);
                break;
        }
    }

    private async addWorkReportAttachmentsAsync(sender: IBaseComponent, request: AddWorkOrderAttachmentsRequest): Promise<void> {
        await sender.postAsync("api/workOrders/addWorkOrderAttachments", request);

        await this.attachmentsGridModel.reloadAsync();
    }

    private async onTabSelect(index: number): Promise<void> {
        if (this.state.selectedTabIndex != index) {
            await this.setState({selectedTabIndex: index});
        }
    }

    private async addHourAsync(): Promise<void> {
        const canAdd: boolean = (!this.hasNewHoursRow);
        if (canAdd) {
            const salaryHour = new UserSalaryHour();
            salaryHour.hoursPrice = WorkOrderModel.getHoursPrice(this.workOrder, this.props.defaultPrices);
            salaryHour.alarmJobPrice = WorkOrderModel.getAlarmJobPrice(this.workOrder, this.props.defaultPrices);
            salaryHour.workOrderId = this.workOrder.id;
            salaryHour.normalHours = 8;
            salaryHour.cost = UserSalaryHour.calcCost(salaryHour);

            await this.hoursGridModel.addAsync(salaryHour);

            await this.invokeChangeAsync();
        }
    }

    private async addSalesProductAsync(type: EquipmentType = EquipmentType.Product): Promise<void> {
        const canAdd: boolean = (!this.hasNewEquipmentRow);
        if (canAdd) {
            const equipmentData = new WorkOrderEquipment();
            equipmentData.workOrderId = this.workOrder.id;
            equipmentData.type = type;
            equipmentData.unit = (type == EquipmentType.Custom) ? ProductUnit.Piece : null;

            await this.salesEquipmentGridModel.addAsync(equipmentData);

            await this.invokeChangeAsync();
        }
    }

    private async addRentalMassProductAsync(): Promise<void> {
        const canAdd: boolean = (!this.hasNewRentalMassEquipmentRow);
        if (canAdd) {
            const equipmentData: WorkOrderEquipment = new WorkOrderEquipment();
            equipmentData.workOrderId = this.workOrder.id;

            await this.rentalMassEquipmentGridModel.addAsync(equipmentData);

            await this.invokeChangeAsync();
        }
    }

    private async addDistanceAsync(): Promise<void> {
        const canAdd: boolean = (!this.hasNewDistancesRow);
        if (canAdd) {
            const distance = new WorkOrderDistance();
            distance.workOrderId = this.workOrder.id;
            distance.vehicles = 1;
            distance.value = 1;

            await this.distancesGridModel.addAsync(distance);

            await this.invokeChangeAsync();
        }
    }

    private async addExtraCharge(): Promise<void> {
        const canAdd: boolean = (!this.hasNewExtraChargeRow);
        if (canAdd) {
            const extraCharge = new WorkOrderExtraCharge();
            extraCharge.workOrderId = this.workOrder.id;

            await this.extraChargeGridModel.addAsync(extraCharge);

            await this.invokeChangeAsync();
        }
    }

    private readonly openAddRentalEquipmentModalAsync = async (): Promise<void> => {
        await this._rentalEquipmentModal?.openAsync();
    };

    private readonly addRentalEquipmentAsync = async (rentalEquipment: WorkOrderRentalItemModel): Promise<void> => {
        rentalEquipment.workOrderId = this.workOrder.id;
        const updatedRentalEquipment: WorkOrderRentalItemModel = await this.saveWorkOrderRentalItemAsync(this, rentalEquipment);
        await this.rentalEquipmentGridModel.addAsync(updatedRentalEquipment);

        await this.invokeChangeAsync();
    };

    private async onSalesProductChangeAsync(cell: CellModel<WorkOrderEquipment>): Promise<void> {
        const model: WorkOrderEquipment = cell.model;
        if (model.product) {
            model.price = model.product.price;
            model.productId = model.product.id;
            await cell.row.reRenderAsync();
        }
        await this.calcEquipmentCostAsync(cell);
    }

    private async onRentalMassProductChangeAsync(cell: CellModel<WorkOrderEquipment>): Promise<void> {
        const model: WorkOrderEquipment = cell.model;
        if (model.product) {
            model.productId = model.product.id;
            await cell.row.reRenderAsync();
        }
    }

    private async onExtraChargeTypeChangeAsync(cell: CellModel<WorkOrderExtraCharge>): Promise<void> {
        const model: WorkOrderExtraCharge = cell.model;
        if (model.extraChargeType) {
            model.extraChargeTypeId = model.extraChargeType.id;
            model.price = model.extraChargeType.price;
            await cell.row.reRenderAsync();
        }
        await this.calcExtraChargeCostAsync(cell);
    }

    private isHoursDayAndUserValid(cell: CellModel<UserSalaryHour>): boolean {
        const model: UserSalaryHour = cell.model;
        return !cell.someRows(item => (item.day.equals(model.day)) && (Comparator.isEqual(item.user, model.user)), true, false);
    }

    private isHoursValid(cell: CellModel<UserSalaryHour>): boolean {
        const model: UserSalaryHour = cell.model;
        return (!model.day.inFuture()) && (this.isHoursDayAndUserValid(cell)) && (model.user != null);
    }

    private initHoursOperations(cell: CellModel<UserSalaryHour>): void {
        const model: UserSalaryHour = cell.model;
        const isNew: boolean = !model.id;
        const modified: boolean = cell.row.modified;
        const deleted: boolean = cell.row.deleted;
        const canDelete: boolean = (this.editability == WorkOrderEditability.Editable);
        const isValid: boolean = this.isHoursValid(cell);

        const saveAction: CellAction<UserSalaryHour> = cell.actions[0];
        const cancelAction: CellAction<UserSalaryHour> = cell.actions[1];
        const deleteAction: CellAction<UserSalaryHour> = cell.actions[2];
        const restoreAction: CellAction<UserSalaryHour> = cell.actions[3];

        saveAction.visible = (isValid) && (modified) && (!deleted);
        cancelAction.visible = (modified) && (!deleted) && (!isNew);
        deleteAction.visible = (canDelete) && (!deleted);
        restoreAction.visible = (canDelete) && (deleted);
    }

    private isEquipmentProductValid(cell: CellModel<WorkOrderEquipment>): boolean {
        const model: WorkOrderEquipment = cell.model;
        const customProduct: boolean = (model.type == EquipmentType.Custom);
        return (customProduct) || (!cell.someRows(item => Comparator.isEqual(item.product, model.product)));
    }

    private isSalesEquipmentValid(cell: CellModel<WorkOrderEquipment>): boolean {
        const model: WorkOrderEquipment = cell.model;
        const isCustomProduct: boolean = (model.type == EquipmentType.Custom);
        return (isCustomProduct)
            ? ((!!model.name) && (model.unit != null))
            : (model.product != null) && (this.isEquipmentProductValid(cell));
    }

    private initSalesEquipmentOperations(cell: CellModel<WorkOrderEquipment>): void {
        const model: WorkOrderEquipment = cell.model;
        const isNew: boolean = !model.id;
        const modified: boolean = cell.row.modified;
        const deleted: boolean = cell.row.deleted;
        const canDelete: boolean = (this.editability == WorkOrderEditability.Editable);
        const isValid: boolean = this.isSalesEquipmentValid(cell);

        const saveAction: CellAction<WorkOrderEquipment> = cell.actions[0];
        const cancelAction: CellAction<WorkOrderEquipment> = cell.actions[1];
        const deleteAction: CellAction<WorkOrderEquipment> = cell.actions[2];

        saveAction.visible = (isValid) && (modified) && (!deleted);
        cancelAction.visible = (modified) && (!deleted) && (!isNew);
        deleteAction.visible = (canDelete) && (!deleted);
    }

    private isRentalMassEquipmentValid(cell: CellModel<WorkOrderEquipment>): boolean {
        const model: WorkOrderEquipment = cell.model;
        return (model.product != null);
    }

    private initRentalMassEquipmentOperations(cell: CellModel<WorkOrderEquipment>): void {
        const model: WorkOrderEquipment = cell.model;
        const isNew: boolean = !model.id;
        const modified: boolean = cell.row.modified;
        const deleted: boolean = cell.row.deleted;
        const canDelete: boolean = (this.editability == WorkOrderEditability.Editable);
        const isValid: boolean = this.isRentalMassEquipmentValid(cell);

        const saveAction: CellAction<WorkOrderEquipment> = cell.actions[0];
        const cancelAction: CellAction<WorkOrderEquipment> = cell.actions[1];
        const deleteAction: CellAction<WorkOrderEquipment> = cell.actions[2];

        saveAction.visible = (isValid) && (modified) && (!deleted);
        cancelAction.visible = (modified) && (!deleted) && (!isNew);
        deleteAction.visible = (canDelete) && (!deleted);
    }


    private isExtraChargeValid(cell: CellModel<WorkOrderExtraCharge>): boolean {
        const model: WorkOrderExtraCharge = cell.model;
        return (model.extraChargeType != null) && (!cell.someRows(item => Comparator.isEqual(item.extraChargeType, model.extraChargeType)));
    }

    private isRentalEquipmentValid(cell: CellModel<WorkOrderRentalItemModel>): boolean {
        const model: WorkOrderRentalItemModel = cell.model;
        return model.rentalItemExternalId != null
            && model.rentDate != null
            && model.actionType != null
    }

    private initExtraChargeOperations(cell: CellModel<WorkOrderExtraCharge>): void {
        const model: WorkOrderExtraCharge = cell.model;
        const isNew: boolean = !model.id;
        const modified: boolean = cell.row.modified;
        const deleted: boolean = cell.row.deleted;
        const canDelete: boolean = (this.editability == WorkOrderEditability.Editable);
        const isValid: boolean = this.isExtraChargeValid(cell);

        const saveAction: CellAction<WorkOrderExtraCharge> = cell.actions[0];
        const cancelAction: CellAction<WorkOrderExtraCharge> = cell.actions[1];
        const deleteAction: CellAction<WorkOrderExtraCharge> = cell.actions[2];

        saveAction.visible = (isValid) && (modified) && (!deleted);
        cancelAction.visible = (modified) && (!deleted) && (!isNew);
        deleteAction.visible = (canDelete) && (!deleted);
    }

    private initRentalEquipmentsOperations(cell: CellModel<WorkOrderRentalItemModel>): void {
        const model: WorkOrderRentalItemModel = cell.model;
        const isNew: boolean = !model.id;
        const modified: boolean = cell.row.modified;
        const deleted: boolean = cell.row.deleted;
        const canDelete: boolean = (this.editability == WorkOrderEditability.Editable);
        const isValid: boolean = this.isRentalEquipmentValid(cell);

        const saveAction: CellAction<WorkOrderRentalItemModel> = cell.actions[0];
        const cancelAction: CellAction<WorkOrderRentalItemModel> = cell.actions[1];
        const deleteAction: CellAction<WorkOrderRentalItemModel> = cell.actions[2];

        saveAction.visible = (isValid) && (modified) && (!deleted);
        cancelAction.visible = (modified) && (!deleted) && (!isNew);
        deleteAction.visible = (canDelete) && (!deleted);
    }

    private isDistanceDayValid(cell: CellModel<WorkOrderDistance>): boolean {
        const model: WorkOrderDistance = cell.model;
        return !cell.someRows(item => item.day.equals(model.day));
    }

    private isDistanceValid(cell: CellModel<WorkOrderDistance>): boolean {
        const model: WorkOrderDistance = cell.model;
        return (model.value > 0) && (this.isDistanceDayValid(cell));
    }

    private initDistanceOperations(cell: CellModel<WorkOrderDistance>): void {
        const model: WorkOrderDistance = cell.model;
        const isNew: boolean = !model.id;
        const modified: boolean = cell.row.modified;
        const deleted: boolean = cell.row.deleted;
        const canDelete: boolean = (this.editability == WorkOrderEditability.Editable);
        const isValid: boolean = this.isDistanceValid(cell);

        const saveAction: CellAction<WorkOrderDistance> = cell.actions[0];
        const cancelAction: CellAction<WorkOrderDistance> = cell.actions[1];
        const deleteAction: CellAction<WorkOrderDistance> = cell.actions[2];

        saveAction.visible = (isValid) && (!deleted) && ((modified) || (isNew));
        cancelAction.visible = (modified) && (!deleted) && (!isNew);
        deleteAction.visible = (canDelete) && (!deleted);
    }

    private async onUpdateWorkOrderBlockingForms(hasBlockingForms: boolean): Promise<void> {
        this.workOrder.hasBlockingForms = hasBlockingForms;

        await this.props.onChange(true);
    }

    private async fetchWorkOrderStatusesAsync(
        pageNumber: number,
        pageSize: number,
        sortColumnName: string | null,
        sortDirection: SortDirection | null): Promise<IPagedList<WorkOrderStatusModel>> {

        UserInteractionDataStorage.setFilters(sortColumnName, "WorkOrderStatusModel.SortColumn");
        UserInteractionDataStorage.setFilters(sortDirection, "WorkOrderStatusModel.SortDirection");

        const request = new ListWorkOrderStatusesRequest();
        request.workOrderId = this.workOrder.id;
        request.pageNumber = pageNumber;
        request.pageSize = pageSize;
        request.sortColumnName = sortColumnName;
        request.sortDirection = sortDirection;

        return await this.attachmentsGridModel.postAsync("/api/WorkOrders/listWorkOrderStatuses", request);
    }

    private async fetchWorkOrderExternalNotificationsAsync(): Promise<WorkOrderExternalNotificationModel[]> {
        const request: ListWorkOrderExternalNotificationsRequest = new ListWorkOrderExternalNotificationsRequest();
        request.workOrderId = this.workOrder.id;

        return await this.externalNotificationsGridModel.postAsync("/api/notification/listExternalNotifications", request);
    }
    
    private async sendExternalNotification(): Promise<void> {
        await this.externalNotificationModal.openAsync(ch.getUser(), this.workOrder.id, this.workOrder.mounters);
    }

    private get equipmentSortColumn(): string {
        return UserInteractionDataStorage.getFilters("product", "WorkOrderEquipment.SortColumn");
    }

    private get equipmentSortDirection(): SortDirection {
        return UserInteractionDataStorage.getFilters(SortDirection.Asc, "WorkOrderEquipment.SortDirection");
    }

    private get rentalMassEquipmentSortColumn(): string {
        return UserInteractionDataStorage.getFilters("product", "WorkOrderRentalMassEquipment.SortColumn");
    }

    private get rentalMassEquipmentSortDirection(): SortDirection {
        return UserInteractionDataStorage.getFilters(SortDirection.Asc, "WorkOrderRentalMassEquipment.SortDirection");
    }

    private get hoursSortColumn(): string {
        return UserInteractionDataStorage.getFilters("day", "WorkOrderHours.SortColumn");
    }

    private get hoursSortDirection(): SortDirection {
        return UserInteractionDataStorage.getFilters(SortDirection.Asc, "WorkOrderHours.SortDirection");
    }

    private get distancesSortColumn(): string {
        return UserInteractionDataStorage.getFilters("", "WorkOrderDistances.SortColumn");
    }

    private get distancesSortDirection(): SortDirection {
        return UserInteractionDataStorage.getFilters(SortDirection.Asc, "WorkOrderDistances.SortDirection");
    }

    private get attachmentsSortColumn(): string {
        return UserInteractionDataStorage.getFilters("", "WorkReportAttachments.SortColumn");
    }

    private get attachmentsSortDirection(): SortDirection {
        return UserInteractionDataStorage.getFilters(SortDirection.Asc, "WorkReportAttachments.SortDirection");
    }

    private get extraChargesSortColumn(): string {
        return UserInteractionDataStorage.getFilters("", "WorkReportExtraCharges.SortColumn");
    }

    private get extraChargesSortDirection(): SortDirection {
        return UserInteractionDataStorage.getFilters(SortDirection.Asc, "WorkReportExtraCharges.SortDirection");
    }

    private get rentalEquipmentSortColumn(): string {
        return UserInteractionDataStorage.getFilters("", "WorkOrderRentalEquipmentModel.SortColumn");
    }

    private get rentalEquipmentSortDirection(): SortDirection {
        return UserInteractionDataStorage.getFilters(SortDirection.Asc, "WorkOrderRentalEquipmentModel.SortDirection");
    }

    private get workOrderStatusSortColumn(): string {
        return UserInteractionDataStorage.getFilters("", "WorkOrderStatusModel.SortColumn");
    }

    private get workOrderStatusDirection(): SortDirection {
        return UserInteractionDataStorage.getFilters(SortDirection.Asc, "WorkOrderStatusModel.SortDirection");
    }

    public async reloadWorkReportAttachmentsAsync(): Promise<void> {
        await this.attachmentsGridModel.reloadAsync();
    }

    public getTitle(): string {
        return Localizer.topNavWorkOrders;
    }

    private get externalNotificationModal(): ExternalNotificationModal {
        return this._externalNotificationModalRef.current!;
    }

    public async reloadAsync(): Promise<void> {
        await this._salesEquipmentGridRef.current?.reloadAsync();
        await this._hoursGridRef.current?.reloadAsync();
        await this._distancesGridRef.current?.reloadAsync();
        await this._attachmentsGridRef.current?.reloadAsync();
        await this._formsDataRef.current?.reloadAsync();
        await this._workOrderStatusesGridRef.current?.reloadAsync();
    }

    private renderTabContainer(): React.ReactNode {
        const isExtraChargesEnabled: boolean = UnleashHelper.isEnabled(FeatureFlags.ExtraCharge);
        const areRentalFeaturesEnabled: boolean = 
            UnleashHelper.isEnabled(FeatureFlags.RentalEquipment) ||
            UnleashHelper.isEnabled(FeatureFlags.RentalMassProducts);
        const areExternalNotificationsEnabled: boolean = UnleashHelper.isEnabled(FeatureFlags.ExternalNotifications);

        return (
            <TabContainer id="TaskManagementTabs"
                          key={`${this.workOrder.id}-tabs`}
                          onSelect={async (tab) => await this.onTabSelect(tab.index)}
            >

                <Tab id="equipments"
                     title={Localizer.workOrdersEquipmentsTabTitle}
                >
                    {
                        (this.editability === WorkOrderEditability.Editable) &&
                        (
                            <Inline justify={JustifyContent.Start}>

                                <ToolbarButton icon={{name: "plus", size: IconSize.Large}}
                                               label={Localizer.workOrderEquipmentAddProduct}
                                               type={ButtonType.Orange}
                                               disabled={this.editability !== WorkOrderEditability.Editable}
                                               onClick={() => this.addSalesProductAsync()}
                                />

                                <ToolbarButton icon={{name: "plus", size: IconSize.Large}}
                                               label={Localizer.workOrderEquipmentAddCustomProduct}
                                               type={ButtonType.Orange}
                                               disabled={(this.editability !== WorkOrderEditability.Editable)}
                                               onClick={() => this.addSalesProductAsync(EquipmentType.Custom)}
                                />

                            </Inline>
                        )
                    }

                    <Grid ref={this._salesEquipmentGridRef}
                          columns={this._salesEquipmentColumns}
                          readonly={this.editability === WorkOrderEditability.Readonly}
                          minWidth="auto"
                          className={"mr-auto"}
                          noDataText={Localizer.workOrderDetailsPanelGridEquipmentNoDataText}
                          hovering={GridHoveringType.Row}
                          odd={GridOddType.None}
                          initRow={(row) => this.iniEquipmentRow(row)}
                          fetchData={async (sender, _, __, sortColumnName, sortDirection) => {
                              return await this.fetchEquipmentAsync(sender, sortColumnName, sortDirection);
                          }}
                          defaultSortColumn={this.equipmentSortColumn}
                          defaultSortDirection={this.equipmentSortDirection}
                    />
                </Tab>

                {
                    (areRentalFeaturesEnabled) &&
                    (
                        <Tab id="rentalEquipment"
                             title={Localizer.rentalEquipmentRentalEquipment}
                        >
                            <FeatureSwitch flagName={FeatureFlags.RentalEquipment}>
                                {
                                    (this.editability === WorkOrderEditability.Editable) &&
                                    (
                                        <Inline justify={JustifyContent.Start}>
                                            <ToolbarButton id="workOrderAddRentalEquipment"
                                                           icon={{name: "plus", size: IconSize.Large}}
                                                           label={Localizer.rentalEquipmentAddRentalEquipment}
                                                           type={ButtonType.Orange}
                                                           onClick={this.openAddRentalEquipmentModalAsync}
                                            />
                                        </Inline>
                                    )
                                }
    
                                <Grid id="rentalEquipmentGrid"
                                      ref={this._rentalEquipmentsGridRef}
                                      columns={this._rentalEquipmentsColumns}
                                      readonly={this.editability === WorkOrderEditability.Readonly}
                                      minWidth="auto"
                                      className={"mr-auto"}
                                      noDataText={Localizer.genericNoData}
                                      hovering={GridHoveringType.Row}
                                      odd={GridOddType.None}
                                      initRow={(row) => this.initRow(row)}
                                      fetchData={async (sender, _, __, sortColumnName, sortDirection) => await this.fetchRentalEquipmentsAsync(sender, sortColumnName, sortDirection)}
                                      defaultSortColumn={this.rentalEquipmentSortColumn}
                                      defaultSortDirection={this.rentalEquipmentSortDirection}
                                />
    
                                <AddRentalEquipmentModal ref={(modal) => this._rentalEquipmentModal = modal}
                                                         addRentalEquipmentItem={this.addRentalEquipmentAsync}
                                />
                            </FeatureSwitch>
                            
                            <FeatureSwitch flagName={FeatureFlags.RentalMassProducts}>
                                <React.Fragment>
                                    {
                                        (this.editability === WorkOrderEditability.Editable) &&
                                        (
                                            <Inline justify={JustifyContent.Start}>
                                                <ToolbarButton id="workOrderAddRentalMassEquipment"
                                                               icon={{name: "plus", size: IconSize.Large}}
                                                               label={Localizer.rentalEquipmentAddRentalMassEquipment}
                                                               type={ButtonType.Orange}
                                                               onClick={async () => await this.addRentalMassProductAsync()}
                                                />
                                            </Inline>
                                        )
                                    }
                                    
                                    <Grid ref={this._rentalMassEquipmentGridRef}
                                          id="rentalMassEquipmentGrid"
                                          columns={this._rentalMassEquipmentColumns}
                                          readonly={this.editability === WorkOrderEditability.Readonly}
                                          minWidth="auto"
                                          className={"mr-auto"}
                                          noDataText={Localizer.genericNoData}
                                          hovering={GridHoveringType.Row}
                                          odd={GridOddType.None}
                                          initRow={(row) => this.initRow(row)}
                                          fetchData={async (sender, _, __, sortColumnName, sortDirection) => {
                                              return await this.fetchRentalMassEquipmentAsync(sender, sortColumnName, sortDirection);
                                          }}
                                          defaultSortColumn={this.rentalMassEquipmentSortColumn}
                                          defaultSortDirection={this.rentalMassEquipmentSortDirection}
                                    />
                                </React.Fragment>
                            </FeatureSwitch>
                        </Tab>
                    )
                }

                <Tab id="hours"
                     title={Localizer.workOrdersHoursTabTitle}
                >
                    {
                        (this.editability === WorkOrderEditability.Editable) &&
                        (
                            <Inline justify={JustifyContent.Start}>
                                <ToolbarButton icon={{name: "plus", size: IconSize.Large}}
                                               label={Localizer.workOrderDetailsAddHours}
                                               type={ButtonType.Orange}
                                               onClick={() => this.addHourAsync()}
                                />
                            </Inline>
                        )
                    }

                    <Grid ref={this._hoursGridRef}
                          columns={this._hoursColumns}
                          readonly={this.editability === WorkOrderEditability.Readonly}
                          minWidth="auto"
                          className={"mr-auto"}
                          noDataText={Localizer.workOrderDetailsPanelGridHoursNoDataText}
                          hovering={GridHoveringType.Row}
                          odd={GridOddType.None}
                          initRow={(row) => this.initRow(row)}
                          fetchData={async (sender, _, __, sortColumnName, sortDirection) => await this.fetchUserSalaryHoursAsync(sender, sortColumnName, sortDirection)}
                          defaultSortColumn={this.hoursSortColumn}
                          defaultSortDirection={this.hoursSortDirection}
                    />
                </Tab>

                <Tab id="distance"
                     title={Localizer.workOrderDetailsDistancesTabTitle}
                >
                    {
                        (this.editability === WorkOrderEditability.Editable) &&
                        (
                            <Inline justify={JustifyContent.Start}>
                                <ToolbarButton icon={{name: "plus", size: IconSize.Large}}
                                               label={Localizer.workOrderDetailsAddDistance}
                                               type={ButtonType.Orange}
                                               onClick={() => this.addDistanceAsync()}
                                />
                            </Inline>
                        )
                    }

                    <Grid ref={this._distancesGridRef}
                          columns={this._distancesColumns}
                          readonly={this.editability === WorkOrderEditability.Readonly}
                          minWidth="auto"
                          className={"mr-auto"}
                          noDataText={Localizer.workOrderDetailsPanelGridDistancesNoDataText}
                          hovering={GridHoveringType.Row}
                          odd={GridOddType.None}
                          initRow={(row) => this.initRow(row)}
                          fetchData={async (sender, _, __, sortColumnName, sortDirection) => await this.fetchDistancesAsync(sender, sortColumnName, sortDirection)}
                          defaultSortColumn={this.distancesSortColumn}
                          defaultSortDirection={this.distancesSortDirection}
                    />
                </Tab>

                {
                    (isExtraChargesEnabled) &&
                    (
                        <Tab id="extraCharge"
                             title={Localizer.workOrderDetailsPanelExtraChargesTabTitle}
                        >
                            {
                                (this.editability === WorkOrderEditability.Editable) &&
                                (
                                    <Inline justify={JustifyContent.Start}>
                                        <ToolbarButton id="addExtraCharge"
                                                       icon={{name: "plus", size: IconSize.Large}}
                                                       label={Localizer.workOrderDetailsPanelExtraChargesToolbarButtonLabel}
                                                       type={ButtonType.Orange}
                                                       onClick={() => this.addExtraCharge()}
                                        />
                                    </Inline>
                                )
                            }

                            <Grid ref={this._extraChargesGridRef}
                                  id="extraCharges"
                                  columns={this._extraChargesColumns}
                                  readonly={this.editability === WorkOrderEditability.Readonly}
                                  minWidth="auto"
                                  className={"mr-auto"}
                                  noDataText={Localizer.genericNoData}
                                  hovering={GridHoveringType.Row}
                                  odd={GridOddType.None}
                                  initRow={(row) => this.initRow(row)}
                                  fetchData={async (sender, _, __, sortColumnName, sortDirection) => await this.fetchExtraChargesAsync(sender, sortColumnName, sortDirection)}
                                  defaultSortColumn={this.extraChargesSortColumn}
                                  defaultSortDirection={this.extraChargesSortDirection}
                            />
                        </Tab>
                    )
                }

                <Tab id="attachments"
                     title={Localizer.addWorkReportModalAttachments}
                >
                    {
                        (this.editability === WorkOrderEditability.Editable) &&
                        (
                            <ToolbarContainer>
                                <div>
                                    <WorkOrderDetailsPanelToolbar model={this.state.filters}
                                                                  onChange={async (model) => this.onFiltersChange(model)}
                                                                  workOrderId={this.workOrder.id}
                                                                  addWorkReportAttachments={async (sender, request) => await this.addWorkReportAttachmentsAsync(sender, request)}
                                                                  reloadWorkReportAttachments={async () => await this.reloadWorkReportAttachmentsAsync()}
                                    />
                                </div>
                            </ToolbarContainer>
                        )
                    }

                    <div>
                        <WorkOrderAttachmentsGrid displayPublicity
                                                  readonly={(this.editability === WorkOrderEditability.Readonly)}
                                                  ref={this._attachmentsGridRef}
                                                  processAttachmentOperation={async (cell, action) => await this.processAttachmentOperationAsync(cell, action)}
                                                  fetchAttachments={async (pageNumber, pageSize, sortColumnName, sortDirection) => await this.fetchAttachments(pageNumber, pageSize, sortColumnName, sortDirection)}
                                                  attachmentsSortColumn={this.attachmentsSortColumn}
                                                  attachmentsSortDirection={this.attachmentsSortDirection}
                        />
                    </div>
                </Tab>

                {
                    (!this.creatingNew) &&
                    (
                        <Tab id="formsTab"
                             title={Localizer.formsTabTitle}
                        >
                            <FormsData ref={this._formsDataRef}
                                       showFiltersToolbar={false}
                                       workOrderId={this.workOrder.id}
                                       onUpdateWorkOrderBlockingForms={(hasBlockingForms) => this.onUpdateWorkOrderBlockingForms(hasBlockingForms)}
                            />
                        </Tab>
                    )
                }

                <Tab id="workOrderStatusHistory"
                     title={Localizer.workOrderDetailsPanelTabHistory}
                >

                    <Grid readonly
                          id="workOrderStatusesGrid"
                          ref={this._workOrderStatusesGridRef}
                          columns={this._workOrderStatusesColumns}
                          pagination={10}
                          minWidth="auto"
                          className={"mr-auto"}
                          noDataText={Localizer.genericNoData}
                          hovering={GridHoveringType.Row}
                          odd={GridOddType.None}
                          fetchData={async (_, pageNumber, pageSize, sortColumnName, sortDirection) => await this.fetchWorkOrderStatusesAsync(pageNumber, pageSize, sortColumnName, sortDirection)}
                          defaultSortColumn={this.workOrderStatusSortColumn}
                          defaultSortDirection={this.workOrderStatusDirection}
                    />
                </Tab>
                
                {
                    (areExternalNotificationsEnabled) && 
                    (
                        <Tab id="externalNotificationsTab"
                             title={Localizer.workOrderDetailsPanelTabExternalNotifications}
                        >
                            <Inline justify={JustifyContent.Start}>
                                <ToolbarButton id="workOrderSendExternalNotification"
                                               icon={{name: "plus", size: IconSize.Large}}
                                               label={Localizer.externalNotificationModalTitle}
                                               type={ButtonType.Orange}
                                               onClick={async () => await this.sendExternalNotification()}
                                />
                            </Inline>
                            
                            <Grid id="externalNotificationsGrid"
                                  ref={this._externalNotificationsGridRef}
                                  columns={this._workOrderExternalNotificationsColumns}
                                  pagination={10}
                                  minWidth="auto"
                                  className={"mr-auto"}
                                  noDataText={Localizer.genericNoData}
                                  hovering={GridHoveringType.Row}
                                  odd={GridOddType.None}
                                  fetchData={async () => await this.fetchWorkOrderExternalNotificationsAsync()}
                            />

                            <ExternalNotificationModal ref={this._externalNotificationModalRef}
                                                       onSend={() => this.externalNotificationsGridModel.reloadAsync()}/>
                        </Tab>
                    )
                }

            </TabContainer>
        )
    }

    public render(): React.ReactNode {
        return (
            <div className={styles.workOrderDetailsPanel}>
                {this.renderTabContainer()}

                <ImageModal id={`${this.id}_previewPictureDocument`}
                            ref={this._previewPictureDocumentRef}
                            size={ModalSize.Large}
                            title={Localizer.formInputFilePreview}
                />
            </div>
        );
    }
}