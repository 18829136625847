import React from "react";
import {Alert, Button, ButtonType, DateInput, Dropdown, DropdownAlign, DropdownOrderBy, DropdownRequiredType, Form, Icon, Modal, OneColumn, Spinner, TextAreaInput, TextInput, TwoColumns} from "@renta-apps/athenaeum-react-components";
import WorkOrderRentalItemModel from "@/models/server/WorkOrderRentalItemModel";
import {AlertModel, AlertType, ApiProvider} from "@renta-apps/athenaeum-react-common";
import RentalItem from "@/models/connect/RentalItem";
import ProductProvider from "@/providers/ProductProvider";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "@/localization/Localizer";

import styles from "./AddRentalEquipmentModal.module.scss";

export interface IAddRentalEquipmentModalProps {
    addRentalEquipmentItem(rentalEquipment: WorkOrderRentalItemModel): Promise<void>;
}

interface IAddRentalEquipmentModalState {
    alert: AlertModel | null;
    externalId: string | null;
    loading: boolean;
    rentalEquipment: WorkOrderRentalItemModel | null;
}

export default class AddRentalEquipmentModal extends React.Component<IAddRentalEquipmentModalProps, IAddRentalEquipmentModalState> {

    public state: IAddRentalEquipmentModalState = AddRentalEquipmentModal.defaultState;

    private _modal: Modal | null = null;

    private static get defaultState(): IAddRentalEquipmentModalState {
        return {
            alert: null,
            externalId: null,
            loading: false,
            rentalEquipment: null,
        };
    }

    private readonly onSubmitAsync = async (): Promise<void> => {
        const {rentalEquipment} = this.state;

        if (!rentalEquipment) {
            return;
        }

        await this.props.addRentalEquipmentItem(rentalEquipment);

        await this.closeAsync();
    }

    private readonly closeAsync = async (): Promise<void> => {
        await this._modal?.closeAsync();
    }

    private readonly onExternalIdChangeAsync = async (
        _: TextInput,
        externalId: string,
        __: boolean,
        done: boolean
    ): Promise<void> => {
        externalId = externalId.trim();
        await this.setState({externalId});

        if (done) {
            await this.searchAsync();
        }
    };

    private readonly searchAsync = async (): Promise<void> => {
        const {externalId} = this.state;

        if (!externalId?.trim()) {
            return;
        }

        await this.setState({
            loading: true,
        });

        let alert: AlertModel | null;
        let rentalEquipment: WorkOrderRentalItemModel | null = null;

        const rentalProduct: RentalItem | null = await ProductProvider.getRentalProductAsync(ApiProvider, externalId);

        if (!rentalProduct) {
            alert = new AlertModel(Localizer.rentalEquipmentProductWithIdNotAvailableForRent.format(externalId), AlertType.Danger, true);
        } else {
            alert = new AlertModel(Localizer.rentalEquipmentProductInfoRetrievedSuccessfully, AlertType.Success, true);
            rentalEquipment = new WorkOrderRentalItemModel(rentalProduct);
        }

        await this.setState({
            loading: false,
            alert,
            rentalEquipment,
        });
    };

    public async openAsync(): Promise<void> {
        await this.setState(AddRentalEquipmentModal.defaultState);
        await this._modal?.openAsync();
    }

    public render(): React.ReactNode {
        const {alert, externalId, loading, rentalEquipment} = this.state;

        const equipmentDetailsReadonly: boolean = (loading) || (!rentalEquipment);

        return (
            <Modal id={"addRentalItemsModal"}
                   className={styles.addRentalEquipmentModal}
                   ref={(modal) => this._modal = modal}
                   title={Localizer.rentalEquipmentRentalEquipment}
                   subtitle={Localizer.rentalEquipmentAddRentalEquipmentToWorkOrder}
            >
                {
                    (alert) && (
                        <Alert model={alert}/>
                    )
                }

                {
                    (loading) && (
                        <Spinner global/>
                    )
                }

                <div className="row">

                    <div className="col">

                        <Form onSubmit={this.onSubmitAsync}>
                            <OneColumn>
                                <TextInput readonly={loading}
                                           id="rentalEquipmentExternalId"
                                           label={Localizer.genericExternalId}
                                           value={externalId}
                                           onChange={this.onExternalIdChangeAsync}
                                           append={(
                                               <Icon name="search"
                                                     className={styles.searchIcon}
                                                     tooltip={Localizer.genericSearch}
                                                     onClick={this.searchAsync}
                                               />
                                           )}
                                />
                            </OneColumn>
                            
                            <hr/>

                            {
                                (rentalEquipment?.rentalItemExternalId) &&
                                (
                                    <div className={styles.searchResult}>
                                        <span>{`${Localizer.rentalEquipmentRentalEquipment} ${Localizer.genericExternalId}: ${rentalEquipment?.rentalItemExternalId}`}</span>
                                    </div>
                                )
                            }

                            <OneColumn>

                                <TextInput readonly
                                           id="rentalEquipmentName"
                                           label={Localizer.genericName}
                                           value={(this.state.rentalEquipment?.rentalItemName)}
                                />

                            </OneColumn>

                            <TwoColumns>

                                <DateInput required
                                           readonly={equipmentDetailsReadonly}
                                           id="rentalEquipmentRentDate"
                                           label={Localizer.genericDate}
                                           value={rentalEquipment?.rentDate}
                                           onChange={async (value) => {
                                               rentalEquipment!.rentDate = value
                                           }}
                                />

                                <Dropdown required autoGroupSelected
                                          requiredType={DropdownRequiredType.Restricted}
                                          disabled={equipmentDetailsReadonly}
                                          id="rentalEquipmentActionType"
                                          label={Localizer.addExtraChargeModalExtraChargeTypeDropdownLabel}
                                          minWidth="105px"
                                          align={DropdownAlign.Right}
                                          orderBy={DropdownOrderBy.None}
                                          items={EnumProvider.getRentalItemActionTypeItems()}
                                          selectedItem={rentalEquipment?.actionType}
                                          onChange={async (_, item) => {
                                              rentalEquipment!.actionType = parseInt(item!.value)
                                          }}
                                />

                            </TwoColumns>

                            <OneColumn>

                                <TextAreaInput id="rentalEquipmentComment"
                                               readonly={equipmentDetailsReadonly}
                                               label={Localizer.genericComment}
                                               value={rentalEquipment?.comment}
                                               onChange={async (_, value) => {
                                                   rentalEquipment!.comment = value
                                               }}
                                />

                            </OneColumn>

                            <div>

                                <Button id="cancelAddRentalEquipment"
                                        type={ButtonType.Light}
                                        label={Localizer.genericActionCancel}
                                        onClick={this.closeAsync}
                                />

                                <Button submit
                                        disabled={!rentalEquipment}
                                        id="saveAddRentalEquipment"
                                        className={"float-right"}
                                        type={ButtonType.Orange}
                                        label={Localizer.genericActionSave}
                                />

                            </div>

                        </Form>

                    </div>

                </div>

            </Modal>
        )
    }
}