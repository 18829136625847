import {onRedirect, pageData} from "@/helpers/CypressHelper";

// const baseAddWorkOrderSettings: Omit<AddWorkOrderSettings, "name"> = {
//     customerAndSite: "Customer.SubCompanyContractNoExternalId.ConstructionSite",
//     status: "New",
//     workOrderType: "Enabled",
//     managers: ["Renta.OperatorContract.Manager@automatic.test.rentatask.fi"],
//     mounters: ["Renta.OperatorContract.Mounter@automatic.test.rentatask.fi"],
//     ordererFullName: "ContactPerson Tester",
//     approverFullName: "ContactPerson Tester",
// }
//
// const addWorkOrderSettingsApprovedByEmail: AddWorkOrderSettings = {
//     ...baseAddWorkOrderSettings,
//     name: `Work Order Mobile Completion test - approved by email - ${new Date().toISOString()}`
// }
//
// const addWorkOrderSettingsApprovedByPhone: AddWorkOrderSettings = {
//     ...baseAddWorkOrderSettings,
//     name: `Work Order Mobile Completion test - approved by phone - ${new Date().toISOString()}`
// }
//
// const addWorkOrderSettingsApprovedBySignature: AddWorkOrderSettings = {
//     ...baseAddWorkOrderSettings,
//     name: `Work Order Mobile Completion test - approved by signature - ${new Date().toISOString()}`
// }
//
// TODO: Commenting out tests related to approving to IronPDF issue in PR environment
//
// context("(Mobile) Complete Work Order by email", () => {
//     beforeEach(() => {
//         cy.init("Desktop");
//         cy.loginAsAdmin();
//         cy.addWorkOrder(addWorkOrderSettingsApprovedByEmail);
//         cy.logout();
//         cy.init("Mobile");
//     });
//
//     it("Should complete Work Order approved by email", () => {
//         cy.loginAsMounter();
//         goToCompleteWizard(addWorkOrderSettingsApprovedByEmail.name);
//
//         pageData().common.mobile.wizardNextButton().click().wait(500);
//         addColleagueHours("Renta.OperatorContract.Mounter-2@automatic.test.rentatask.fi");
//         pageData().common.mobile.wizardNextButton().click().wait(500);
//         pageData().common.mobile.wizardNextButton().click().wait(500);
//         pageData().common.mobile.wizardNextButton().click().wait(500);
//         pageData().common.mobile.wizardNextButton().click();
//         cy.wait(1000);
//
//         assertWorkOrderStatusChangedToSentToCustomer();
//         assertButtonsDisabled();
//     });
// });
//
// context("(Mobile) Complete Work Order by phone", () => {
//     beforeEach(() => {
//         cy.init("Desktop");
//         cy.loginAsAdmin();
//         cy.addWorkOrder(addWorkOrderSettingsApprovedByPhone);
//         cy.logout();
//         cy.init("Mobile");
//     });
//
//     it("Should complete Work Order approved by phone", () => {
//         cy.loginAsMounter();
//         goToCompleteWizard(addWorkOrderSettingsApprovedByPhone.name);
//
//         pageData().common.mobile.wizardNextButton().click().wait(500);
//         addColleagueHours("Renta.OperatorContract.Mounter-2@automatic.test.rentatask.fi");
//         pageData().common.mobile.wizardNextButton().click().wait(500);
//         pageData().common.mobile.wizardNextButton().click().wait(500);
//         pageData().common.mobile.wizardNextButton().click().wait(1000);
//
//         pageData().mobileWorkOrder.completeWizard.approvalTypePanel().click();
//         cy.get('.athenaeum-dropdown-item').contains("By phone").click();
//         pageData().common.mobile.wizardNextButton().click();
//         cy.wait(1000);
//
//         assertWorkOrderStatusChangedToApprovedByCustomer();
//         assertButtonsDisabled();
//     });
// });
//
//
// context("(Mobile) Complete Work Order by signature", () => {
//     beforeEach(() => {
//         cy.init("Desktop");
//         cy.loginAsAdmin();
//         cy.addWorkOrder(addWorkOrderSettingsApprovedBySignature);
//         cy.logout();
//         cy.init("Mobile");
//     });
//
//     it("Should complete Work Order approved by signature", () => {
//         cy.loginAsMounter();
//         goToCompleteWizard(addWorkOrderSettingsApprovedBySignature.name);
//
//         pageData().common.mobile.wizardNextButton().click().wait(500);
//         addColleagueHours("Renta.OperatorContract.Mounter-2@automatic.test.rentatask.fi");
//         pageData().common.mobile.wizardNextButton().click().wait(500);
//         pageData().common.mobile.wizardNextButton().click().wait(500);
//         pageData().common.mobile.wizardNextButton().click().wait(500);
//
//         pageData().mobileWorkOrder.completeWizard.approvalTypePanel().click();
//         cy.get('.athenaeum-dropdown-item').contains("Customer signature").click();
//         pageData().mobileWorkOrder.completeWizard.approvalTypePanel().blur();
//
//         addSignature();
//
//         pageData().common.mobile.wizardNextButton().click();
//         cy.wait(1000);
//
//         assertWorkOrderStatusChangedToApprovedByCustomer();
//         assertButtonsDisabled();
//     });
// });
//
// function goToCompleteWizard(taskName: string) {
//     cy.navigateToMobileWorkOrdersPage();
//     cy.wait(500);
//
//     pageData().mobileWorkOrder.workOrdersList().within(() => {
//         cy.contains(taskName).click();
//     });
//
//     pageData().mobileWorkOrder.workOrderCompleteButton().click();
// }
//
// function addColleagueHours(mounterEmail: string) {
//     pageData().mobileWorkOrder.completeWizard.workOrderHoursPanel().click();
//     cy.focused().click({force: true});
//     pageData().mobileWorkOrder.completeWizard.addUserHours().click();
//     pageData().addHoursModal.mountersDropdown().selectDropdownValue(mounterEmail);
//     pageData().addHoursModal.saveButton().click();
// }
//
// function assertButtonsDisabled() {
//     pageData().mobileWorkOrder.workOrderEditButton().should('have.class', 'athenaeum-button-disabled');
//     pageData().mobileWorkOrder.workOrderAddEquipmentButton().should('have.class', 'athenaeum-button-disabled');
//     pageData().mobileWorkOrder.workOrderAddHoursAndDistancesButton().should('have.class', 'athenaeum-button-disabled');
//     pageData().mobileWorkOrder.workOrderCompleteButton().should('have.class', 'athenaeum-button-disabled');
// }
//
// function assertWorkOrderStatusChangedToSentToCustomer() {
//     pageData().mobileWorkOrder.workOrderInfo().should('contain.text', 'Status: Sent to customer');
// }
//
// function assertWorkOrderStatusChangedToApprovedByCustomer() {
//     pageData().mobileWorkOrder.workOrderInfo().should('contain.text', 'Status: Approved by customer');
// }
//
// function addSignature() {
//     pageData().common.signatureWidget.panel().click({force:true});
//
//     pageData().common.signatureWidget.panel().within(() => {
//         pageData().common.signatureWidget.signatureCanvas()
//             .trigger("mousedown", 50, 100, { which: 1})
//             .trigger('mousemove',200, 100)
//             .trigger("mousemove")
//             .trigger('mousemove',200, 50)
//             .trigger("mousemove")
//             .trigger("mouseup", { which: 1})
//
//         pageData().common.signatureWidget.doneButton().click();
//     })
//
//     pageData().common.nameClarificationWidget.panel().click({force:true});
//
//     pageData().common.nameClarificationWidget.panel().within(() => {
//         pageData().common.nameClarificationWidget.inputNameClarification().type("Name clarification");
//     })
//
//     pageData().common.nameClarificationWidget.panel().click();
// }

context("(Mobile) Subcontractor mounter tests.", () => {

    beforeEach(() => {
        cy.init("Mobile");
    });

    // TODO: Commenting out tests related to approving to IronPDF issue in PR environment
    // it("Should complete Work Order approved by phone", () => {
    //     cy.loginAsSubcontractorMounterCanCompleteWorkOrder();
    //     onRedirect(() => pageData().dashboard.widgets.mobile.workOrders().click());
    //     pageData().dashboard.workOrders.workOrdersList().first().click();
    //
    //     pageData().mobileWorkOrder.workOrderCompleteButton().click();
    //
    //     pageData().common.mobile.wizardNextButton().click().wait(500);
    //     addColleagueHours("SubContractorOrganization.SubCompanyContract1.SubcontractorMounter-1@automatic.test.rentatask.fi");
    //     pageData().common.mobile.wizardNextButton().click().wait(500);
    //     pageData().common.mobile.wizardNextButton().click().wait(500);
    //     pageData().common.mobile.wizardNextButton().click().wait(1000);
    //
    //     pageData().mobileWorkOrder.completeWizard.approvalTypePanel().click();
    //     cy.get('.athenaeum-dropdown-item').contains("By phone").click();
    //     pageData().common.mobile.wizardNextButton().click();
    //     cy.wait(1000);
    //
    //     assertWorkOrderStatusChangedToApprovedByCustomer();
    //     assertButtonsDisabled();
    // });

    it("Subcontractor mounter without permissions cannot complete a work order.", () => {
        cy.loginAsSubcontractorMounterCanCreateWorkOrder();
        onRedirect(() => pageData().dashboard.widgets.mobile.workOrders().click());

        // Use seed data work orders
        pageData().dashboard.workOrders.workOrdersList().first().click();

        pageData().mobileWorkOrder.workOrderCompleteButton().should('not.exist');
    });
});
