import {pageData} from "@/helpers/CypressHelper";

context("(Mobile) Work Order", () => {
    const workOrderName = `Test mobile work order ${new Date().toISOString()}`;

    beforeEach(() => {
        createWorkOrder(workOrderName);
        cy.init("Mobile");
    });

    it('Should work for Mounter', () => {
        cy.loginAsMounter();
        test(workOrderName);
    });
});

context("(Desktop) Work Order", () => {
    const workOrderName = `Test desktop work order ${new Date().toISOString()}`;

    beforeEach(() => {
        createWorkOrder(workOrderName);
        cy.init("Desktop");
    });

    it('Should work for Mounter', () => {
        cy.loginAsMounter();
        test(workOrderName);
    });
});

function createWorkOrder(workOrderName: string): void  {
    cy.init("Desktop");
    cy.loginAsAdmin();
    cy.addWorkOrder({
        name: workOrderName,
        workOrderType: "Enabled",
        managers: ["Renta.OperatorContract.Manager@automatic.test.rentatask.fi"],
        status: "New",
        ordererFullName: "ContactPerson Tester",
        approverFullName: "ContactPerson Tester",
        customerAndSite: "Customer.SubCompanyContractNoExternalId.ConstructionSite",
    });
    cy.logout();
}
function test(workOrderName: string): void {
    cy.navigateToMobileWorkOrdersPage();
    cy.contains(workOrderName).click();

    describe("Sign in", () => {
        cy.contains('button', 'Sign in').trigger('click');

        cy.contains('Are you sure you want to activate and sign in?').should('be.visible')
            .parent().contains('Confirm').should('be.visible').trigger('click');

        cy.contains('Status:')
            .within(() => {
                cy.contains('In progress');
            });
    });

    describe("Add hours", () => {
        cy.contains('Add hours and distances').click();

        cy.contains('span', 'All hours').click();

        cy.contains('Add user hours').trigger('click');

        cy.get('#input_normalHours')
            .should('be.visible')
            .clear()
            .type('5');
        cy.scrollTo('bottom');
        cy.contains('span', 'Save').click();

        pageData().mobileFormSteps.nextButton().click();

        // check if the added hours are visible
        cy.contains('Working hours').parent().within(() => {
            cy.contains('5.0 h (5.0/0.0/0.0)')
                .should('be.visible');
        });

        // go to details
        cy.contains('Show details').click();

        // check if the hours are visible in the details
        cy.contains('Normal hours').parent().within(() => {
            cy.contains('td', '5')
                .should('be.visible');
        });
    });
}