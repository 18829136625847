export default class AddConstructionSiteRequest {

    public name: string = "";

    public externalId: string = "";
    
    public customerInvoiceReference: string = "";

    public organizationContractId: string = "";

    public formattedAddress: string = "";
}