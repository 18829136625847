import React from "react";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import {Button, ButtonContainer, ButtonType, Modal, ModalSize,} from "@renta-apps/athenaeum-react-components";
import WorkOrderModel from "@/models/server/WorkOrderModel";
import GetWorkOrderRequest from "@/models/server/requests/GetWorkOrderRequest";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "@/localization/Localizer";

import styles from "./ApproveWorkOrderModal.module.scss"


interface ISendWorkOrderData {
    workOrder: WorkOrderModel;

}

interface IApproveWorkOrderModalProps {
    onApproveAsync(workOrderId: string): Promise<void>;
}

interface IApproveWorkOrderModalState {
    initialized: boolean;
}

export default class ApproveWorkOrderModal extends BaseComponent<IApproveWorkOrderModalProps, IApproveWorkOrderModalState> {
    state: IApproveWorkOrderModalState = {
        initialized: false
    };

    private readonly _modalRef: React.RefObject<Modal<ISendWorkOrderData>> = React.createRef();
    private _resolver: ((successfully: boolean) => void) | null = null;
    private _successfully: boolean = false;

    private transformData(data: any): ISendWorkOrderData {
        return ((data instanceof WorkOrderModel) || ((data as WorkOrderModel).isWorkOrderModel))
            ? {workOrder: data as WorkOrderModel}
            : data as ISendWorkOrderData;
    }


    private async onOpenAsync(): Promise<void> {
        this._successfully = false;

        await this.setState({initialized: true});
    }

    private async onCloseAsync(): Promise<void> {

        if (this._resolver) {
            this._resolver(this._successfully);
            this._resolver = null;
        }
        await this.setState({initialized: false});
    }

    private async approveWorkOrderAsync(): Promise<void> {
        await this.closeAsync();
        await this.props.onApproveAsync(this.workOrderId)
    }

    private get modal(): Modal<ISendWorkOrderData> {
        return this._modalRef.current!;
    }

    private get hasData(): boolean {
        return ((this._modalRef.current != null) && (this.modal.data != null));
    }

    private get data(): ISendWorkOrderData {
        return this.modal.data!;
    }

    private get initialized(): boolean {
        return this.state.initialized;
    }

    private get title(): string {
        return (this.initialized)
            ? Localizer.get(Localizer.workOrderApprovalModalTitle, this.workOrder.name)
            : "";
    }

    private get subtitle(): string {
        return (this.initialized) ?
            this.workOrder.invoiceReference!
            : "";
    }

    public get workOrder(): WorkOrderModel {
        return this.data.workOrder;
    }

    private get workOrderId(): string {
        return this.workOrder.id;
    }

    public get successfully(): boolean {
        return this._successfully;
    }

    public async closeAsync(): Promise<void> {
        await this.modal.closeAsync();
    }

    public async openAsync(workOrder: WorkOrderModel): Promise<void> {
        const request = new GetWorkOrderRequest();
        request.workOrderId = workOrder.id;
        request.excludeDeletedUserSalaryHours = true;

        workOrder = await this.postAsync("/api/constructionSiteManagement/getWorkOrder", request);

        const data: ISendWorkOrderData = {workOrder};

        await this.modal.openAsync(data);
    }

    public async showAsync(workOrder: WorkOrderModel): Promise<boolean> {
        await this.openAsync(workOrder);

        return new Promise((resolve) => {
            this._resolver = resolve;
        });
    }

    public static get modalId(): string {
        return "approveWorkReportModal";
    }

    public render(): React.ReactNode {
        return (
            <Modal id={ApproveWorkOrderModal.modalId} ref={this._modalRef}
                   title={this.title}
                   size={ModalSize.Large}
                   subtitle={this.subtitle}
                   transform={this.transformData}
                   onOpen={async () => await this.onOpenAsync()}
                   onClose={async () => await this.onCloseAsync()}
            >

                {
                    (this.hasData) &&
                    (
                        <>
                            {
                                (this.workOrder.equipments && this.workOrder.equipments.length > 0) && (
                                    <div className={styles.approveWorkOrderModal}>
                                        <h5>{Localizer.workOrderApprovalEquipmentsTableName}</h5>
                                        <table className={styles.list}>
                                            <thead>
                                            <tr>
                                                <th>{Localizer.workOrderApprovalEquipmentDescription}</th>
                                                <th>{Localizer.workOrderApprovalEquipmentInfo}</th>
                                                <th>{Localizer.workOrderEquipmentAmount}</th>
                                                <th>{Localizer.workOrderApprovalEquipmentUnit}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {

                                                this.workOrder.equipments.map(equipment => {
                                                    return (
                                                        <tr className={styles.content}>
                                                            <td>{equipment.product?.name ?? equipment.name}</td>
                                                            <td>{equipment.description}</td>
                                                            <td>{equipment.amount}</td>
                                                            <td>{equipment.customUnit ?? EnumProvider.getProductUnitText(equipment.unit!)}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                )
                            }
                            {
                                (this.workOrder.userSalaryHours && this.workOrder.userSalaryHours.length > 0) && (
                                    <div className={styles.approveWorkOrderModal}>
                                        <h5>{Localizer.workOrderApprovalHoursTableName}</h5>
                                        <table className={styles.list}>
                                            <thead>
                                            <tr>
                                                <th>{Localizer.workOrderApprovalHoursDay}</th>
                                                <th>{Localizer.workOrderApprovalHoursUser}</th>
                                                <th>{Localizer.workOrderApprovalHoursNormalHours}</th>
                                                <th>{Localizer.workOrderApprovalHoursOvertime50Hours}</th>
                                                <th>{Localizer.workOrderApprovalHoursOvertime100Hours}</th>
                                            </tr>
                                            </thead>

                                            <tbody>
                                            {

                                                this.workOrder.userSalaryHours.map(salaryHour => {
                                                    return (
                                                        <tr className={styles.content}>
                                                            <td>{Utility.format(salaryHour.day.toISODateString(), "D")}</td>
                                                            <td>{salaryHour.user?.email}</td>
                                                            <td>{salaryHour.normalHours}</td>
                                                            <td>{salaryHour.overtime50Hours}</td>
                                                            <td>{salaryHour.overtime100Hours}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            </tbody>
                                        </table>

                                    </div>

                                )
                            }
                            {
                                (this.workOrder.distances && this.workOrder.distances.length > 0) && (
                                    <div className={styles.approveWorkOrderModal}>
                                        <h5>{Localizer.workOrderApprovalMileagesTableName}</h5>
                                        <table className={styles.list}>
                                            <thead>
                                            <tr>
                                                <th>{Localizer.workOrderApprovalMileagesDate}</th>
                                                <th>{Localizer.workOrderApprovalMileagesVehicleAmount}</th>
                                                <th>{Localizer.workOrderApprovalMileagesTotalMileages}</th>
                                            </tr>
                                            </thead>

                                            <tbody>
                                            {

                                                this.workOrder.distances.map(distance => {
                                                    return (
                                                        <tr className={styles.content}>
                                                            <td>{Utility.format(distance.day.toISODateString(), "D")}</td>
                                                            <td>{distance.vehicles}</td>
                                                            <td>{distance.value}</td>
                                                        </tr>
                                                    )
                                                })


                                            }
                                            </tbody>
                                        </table>

                                    </div>

                                )
                            }
                            <ButtonContainer>
                                <Button submit
                                        type={ButtonType.Orange}
                                        label={Localizer.rentaTasksControllerStepsTitleApprove}
                                        onClick={async () => await this.approveWorkOrderAsync()}
                                        icon={{name: "thumbs-up"}}/>
                            </ButtonContainer>
                        </>
                    )
                }

            </Modal>
        )
    }
}