context("(Desktop) Forgot Password", () => {
    it("Should display success message after sending dummy email input", () => {
        cy.init("Desktop");
        cy.navigateToLoginPage();
        testForgotPassword("dummyemail1@test.com");
    });

    it("Should display success message after sending valid user's email", () => {
        cy.init("Desktop");
        cy.navigateToLoginPage();
        testForgotPassword("Renta.OperatorContract.Admin@automatic.test.rentatask.fi");
    });
});

context("(Mobile) Forgot Password", () => {
    it("Should display success message after sending dummy email input", () => {
        cy.init("Mobile");
        cy.navigateToLoginPage();
        testForgotPassword("dummyemail1@test.com");
    });

    it("Should display success message after sending valid user's email", () => {
        cy.init("Mobile");
        cy.navigateToLoginPage();
        testForgotPassword("Renta.OperatorContract.Admin@automatic.test.rentatask.fi");
    });
});

function testForgotPassword(email: string): void {
    describe("Get success message after entering dummy email to forgotten password form", () => {
        cy.contains("Forgot password").click();
        cy.get('#input_username').type(email);
        cy.contains("Send").click();

        cy.get(".athenaeum-alert-alert").should('have.class', 'alert-success').contains("Notification has been sent. Please check your SMS or mailbox.")
    });
}
