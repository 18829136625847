import React from "react";
import {
    Button,
    ButtonType, CellAction,
    CellModel,
    Checkbox,
    ColumnDefinition, ColumnType,
    Grid,
    GridHoveringType, GridModel,
    GridOddType,
    IconSize,
    Inline,
    JustifyContent,
    RowModel,
    ToolbarContainer
} from "@renta-apps/athenaeum-react-components";
import {ActionType, BaseComponent, ch, IBaseComponent, TextAlign} from "@renta-apps/athenaeum-react-common";
import CostPool from "@/models/server/CostPool";
import User from "@/models/server/User";
import {IPagedList, SortDirection, Utility} from "@renta-apps/athenaeum-toolkit";
import ListCostPoolsRequest from "@/models/server/requests/ListCostPoolsRequest";
import AddCostPoolRequest from "@/models/server/requests/AddCostPoolRequest";
import SaveCostPoolResponse from "@/models/server/responses/SaveCostPoolResponse";
import SaveCostPoolRequest from "@/models/server/requests/SaveCostPoolRequest";
import Localizer from "@/localization/Localizer";
import UserInteractionDataStorage from "@/providers/UserInteractionDataStorage";

import styles from "@/pages/Warehouses/CostPoolsData/CostPoolsData.module.scss";


interface ICostPoolsDataProps  {
    reloadWarehouses(sender: IBaseComponent): Promise<void>;
}

interface ICostPoolsDataState {
    showDeleted: boolean
}

export default class CostPoolsData extends BaseComponent<ICostPoolsDataProps, ICostPoolsDataState> {

    state: ICostPoolsDataState = {
        showDeleted: false
    };

    public getTitle(): string {
        return Localizer.topNavWarehouse;
    }    private readonly _costPoolsGridRef: React.RefObject<Grid<CostPool>> = React.createRef();

    private readonly _costPoolsColumns: ColumnDefinition[] = [
        {
            header: "#",
            accessor: "#",
            minWidth: 50,
            noWrap: true,
            className: "grey",
            textAlign: TextAlign.Center
        },
        {
            header: Localizer.genericCostPoolLanguageItemName,
            accessor: nameof<CostPool>(d => d.name),
            minWidth: 150,
            type: ColumnType.Text,
            editable: true,
            sorting: true,
            noWrap: true,
            settings: {
                required: true
            }
        },
        {
            header: Localizer.genericDescriptionLanguageItemName,
            accessor: nameof<CostPool>(d => d.description),
            minWidth: 700,
            type: ColumnType.Text,
            editable: true,
            sorting: true,
            noWrap: true,
            settings: {
                required: true
            }
        },
        {
            stretch: true,
            minWidth: "6rem",
            removable: false,
            init: (cell) => this.initCostPoolOperationsAsync(cell),
            actions: [
                {
                    name: "save",
                    title: Localizer.genericActionSaveLanguageItemName,
                    icon: "far save",
                    type: ActionType.Create,
                    callback: async (cell, action) => await this.processCostPoolOperationAsync(cell, action)
                },
                {
                    name: "cancel",
                    title: Localizer.genericActionCancelLanguageItemName,
                    icon: "far ban",
                    type: ActionType.Delete,
                    right: true,
                    callback: async (cell, action) => await this.processCostPoolOperationAsync(cell, action)
                },
                {
                    name: "delete",
                    title: Localizer.genericActionDeleteLanguageItemName,
                    icon: "far trash-alt",
                    type: ActionType.Delete,
                    right: true,
                    confirm: (cell) => this.getDeleteConfirmation(cell),
                    callback: async (cell, action) => await this.processCostPoolOperationAsync(cell, action)
                },
                {
                    name: "restore",
                    title: Localizer.genericActionRestoreLanguageItemName,
                    icon: "far undo-alt",
                    type: ActionType.Create,
                    right: true,
                    callback: async (cell, action) => await this.processCostPoolOperationAsync(cell, action)
                }
            ]
        }
    ];

    private get costPoolsGrid(): GridModel<CostPool> {
        return this._costPoolsGridRef.current!.model;
    }

    private get newRowAlreadyExists(): boolean {
        return (this.costPoolsGrid.rows.some(row => !row.deleted && !row.model.id));
    }

    private initRow(row: RowModel<CostPool>): void {
        const model: CostPool = row.model;

        const isNew: boolean = ((model.id == "") || (model.id == null));
        const isValid: boolean = this.isValid(model);
        row.deleted = model.deleted;
        row.className = (!isValid)
            ? "danger"
            : (isNew)
                ? "bg-processed"
                : "";
    }

    private isValid(costPool: CostPool): boolean {
        return (!!costPool.name);
    }

    private getDeleteConfirmation(cell: CellModel<CostPool>): string {
        const model: CostPool = cell.model;
        const isNew: boolean = !model.id;
        return (isNew)
            // Grid types do not allow the returned value to be null, but it has been like this forever.
            ? null as any
            : Utility.format(Localizer.warehousesCostPoolsDataDeleteConfirmation, model.name);
    }

    private async getCostPoolsAsync(
        pageNumber: number,
        pageSize: number,
        sortColumnName: string | null,
        sortDirection: SortDirection | null): Promise<IPagedList<CostPool>> {

        UserInteractionDataStorage.setFilters(sortColumnName, "CostPoolsData.SortColumn");
        UserInteractionDataStorage.setFilters(sortDirection, "CostPoolsData.SortDirection");

        const request = new ListCostPoolsRequest();
        request.pageNumber = pageNumber;
        request.pageSize = pageSize;
        request.showDeleted = this.state.showDeleted;
        request.sortColumnName = sortColumnName;
        request.sortDirection = sortDirection;

        return await this.costPoolsGrid.postAsync("api/admin/listCostPools", request);
    }

    private async initCostPoolOperationsAsync(cell: CellModel<CostPool>): Promise<void> {

        const model: CostPool = cell.row.model;

        const modified: boolean = cell.row.modified;
        const deleted: boolean = cell.row.deleted;
        const isValid: boolean = this.isValid(model);
        const isNew: boolean = !model.id;

        const saveAction: CellAction<CostPool> = cell.actions[0];
        const cancelAction: CellAction<CostPool> = cell.actions[1];
        const deleteAction: CellAction<CostPool> = cell.actions[2];
        const restoreAction: CellAction<CostPool> = cell.actions[3];

        saveAction.visible = (modified) && (isValid);
        cancelAction.visible = (modified) && (!isNew);
        deleteAction.visible = (!deleted) && ((!modified) || (isNew));
        restoreAction.visible = (deleted);
    }

    private async processCostPoolOperationAsync(cell: CellModel<CostPool>, action: CellAction<CostPool>): Promise<void> {

        await ch.hideAlertAsync();

        const model: CostPool = cell.model;
        const isNew: boolean = (!model.id);

        if (action.action.name === "save") {

            if (isNew) {

                const request = new AddCostPoolRequest();
                request.name = model.name;
                request.description = model.description;

                const response: SaveCostPoolResponse = await cell.grid.postAsync("api/admin/addCostPool", request);

                if (response.costPoolAlreadyExists) {
                    await ch.alertErrorAsync(Utility.format(Localizer.warehousesCostPoolsDataAlertCostPoolAlreadyExists, model.name), true);
                    return;
                }

                cell.row.model = response.costPool!;

                await cell.row.saveAsync();

            } else {

                const request = new SaveCostPoolRequest();
                request.costPoolId = model.id;
                request.name = model.name;
                request.description = model.description;

                const response: SaveCostPoolResponse = await cell.grid.postAsync("api/admin/saveCostPool", request);

                if (response.costPoolAlreadyExists) {
                    await ch.alertErrorAsync(Utility.format(Localizer.warehousesCostPoolsDataAlertCostPoolAlreadyExists, model.name), true);
                    return;
                }

                cell.row.model = response.costPool!;

                await cell.row.bindAsync();
            }

            await cell.row.bindAsync();

        } else if (action.action.name === "cancel") {

            await cell.row.cancelAsync();

        } else if (action.action.name === "delete") {

            model.deleted = true;
            model.deletedAt = new Date();
            model.deletedBy = ch.getUser<User>();

            if (isNew) {
                await cell.grid.deleteAsync(cell.row.index);
            } else {

                await cell.grid.postAsync("api/admin/deleteCostPool", model.id);

                await cell.row.setDeletedAsync(true);
            }

        } else if (action.action.name === "restore") {

            const restoreOnServer: boolean = !isNew;

            if (restoreOnServer) {
                await cell.grid.postAsync("api/admin/restoreCostPool", model.id);

                model.deleted = false;
                model.deletedAt = null;
                model.deletedBy = null;
            }

            await cell.row.setDeletedAsync(false);
        }

        await this.props.reloadWarehouses(this);
    }

    private async addCostPoolAsync(): Promise<void> {
        if (!this.newRowAlreadyExists) {
            const costPool = new CostPool();

            const newRows: RowModel<CostPool>[] = await this.costPoolsGrid.insertAsync(0, costPool);

            const newRow: RowModel<CostPool> = newRows[0];
            const nameCell: CellModel<CostPool> = newRow.get("name");

            await nameCell.editAsync(true);
        }
    }

    private async setShowDeletedAsync(showDeleted: boolean): Promise<void> {
        await this.setState({showDeleted});
        await this.reloadAsync();
    }

    private async reloadAsync(): Promise<void> {
        await this.costPoolsGrid.reloadAsync();
    }

    private get sortColumn(): string {
        return UserInteractionDataStorage.getFilters("name", "CostPoolsData.SortColumn");
    }

    private get sortDirection(): SortDirection {
        return UserInteractionDataStorage.getFilters(SortDirection.Asc, "CostPoolsData.SortDirection");
    }

    public render(): React.ReactNode {
        return (
            <div className={styles.container}>

                <ToolbarContainer className={styles.toolbar}>


                    <Inline justify={JustifyContent.End}>

                        <Checkbox inline
                                  id="showDeletedCostPools"
                                  label={Localizer.genericShowDeleted}
                                  value={this.state.showDeleted}
                                  onChange={async (_, value) => await this.setShowDeletedAsync(value)}
                        />

                        <Button id="reloadCostPools"
                                className="ml-1"
                                title={Localizer.genericActionReload}
                                icon={{name: "far history", size: IconSize.Large}}
                                type={ButtonType.Info}
                                onClick={async () => await this.reloadAsync()}
                        />

                        <Button id="addCostPool"
                                icon={{name: "plus", size: IconSize.Large}}
                                type={ButtonType.Orange}
                                title={Localizer.warehousesCostPoolsDataAddNewCostPool}
                                onClick={async () => await this.addCostPoolAsync()}
                        />

                    </Inline>

                </ToolbarContainer>

                <Grid ref={this._costPoolsGridRef}
                      id="costPoolsGrid"
                      pagination={10}
                      hovering={GridHoveringType.EditableCell}
                      odd={GridOddType.None}
                      minWidth="auto"
                      columns={this._costPoolsColumns}
                      initRow={(row) => this.initRow(row)}
                      fetchData={async (_, pageNumber, pageSize, sortColumnName, sortDirection) => await this.getCostPoolsAsync(pageNumber, pageSize, sortColumnName, sortDirection)}
                      defaultSortColumn={this.sortColumn}
                      defaultSortDirection={this.sortDirection}
                />

            </div>
        );
    }
}