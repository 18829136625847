import React from "react";
import {BaseComponent, ch} from "@renta-apps/athenaeum-react-common";
import {ButtonType, ImageInput, TextAreaInput} from "@renta-apps/athenaeum-react-components";
import FormItem from "@/models/server/forms/FormItem";
import {FileModel} from "@renta-apps/athenaeum-toolkit";
import FormButton from "@/components/Form/FormButton/FormButton";
import FormPageRow from "@/components/Form/FormPageRow/FormPageRow";
import Localizer from "@/localization/Localizer";
import imageHelper from "@/helpers/ImageHelper";
import {FormItemType} from "@/models/Enums";
import RentaTaskConstants from "@/helpers/RentaTaskConstants";

import styles from "./FormQuestionInput.module.scss";

export interface IFormQuestionInputProps {
    className?: string;
    model: FormItem;

    preview?: boolean;
    onChange?(sender: FormQuestionInput, model: FormItem): Promise<void>;
    rotateImageLeft?(file: FileModel): Promise<FileModel>;
    rotateImageRight?(file: FileModel): Promise<FileModel>;
    convertImage?(file: FileModel): Promise<FileModel>;
}

/** @private */
interface IFormQuestionInputState {
    model: FormItem,
    imageInputKey: string,
}

export default class FormQuestionInput extends BaseComponent<IFormQuestionInputProps, IFormQuestionInputState> {

    private readonly _commentRef: React.RefObject<TextAreaInput> = React.createRef();

    public state: IFormQuestionInputState = {
        model: this.props.model,
        imageInputKey: this.id,
    };

    private async invokeOnChangeAsync(): Promise<void> {
        if (this.props.onChange) {
            await this.props.onChange(this, this.model);
        }
    }

    private async setOk(ok: boolean): Promise<void> {
        this.model.ok = ok;

        const commented: boolean = !ok || !!this.model.comment;

        await this.setCommentedAsync(commented);

        await this.setState({model: this.model});

        await this.invokeOnChangeAsync();

        this.focusComment();
    }

    private async setComment(comment: string): Promise<void> {
        this.model.comment = comment;

        await this.setState({model: this.model});

        await this.invokeOnChangeAsync();
    }

    private focusComment(): void {
        if ((this.model.commented) && (this._commentRef.current)) {
            if (this.model.commented) {
                this._commentRef.current!.focus();
            }
        }
    }

    private async setCommentedAsync(commented: boolean): Promise<void> {
        if (this.model.commented != commented) {
            this.model.commented = commented;

            if (!commented) {
                this.model.picture = null;
                this.model.comment = "";
            }

            await this.setState({model: this.model});

            this.focusComment();

            await this.invokeOnChangeAsync();
        }
    }

    private async toggleCommented(): Promise<void> {
        if (this.model.ok != false) {
            const commented: boolean = !this.model.commented;
            await this.setCommentedAsync(commented);
        }

        this.focusComment();
    }

    private async onFileInputChangeHandlerAsync(file: FileModel | undefined): Promise<void> {
        if ((file) && (!imageHelper.isSupportedImage(file))) {
            await ch.alertErrorAsync(Localizer.genericUnsupportedImageType, true, true);

            /**
             * Force the image input to clear its state if invalid images are uploaded to it.
             */
            await this.setState({
                imageInputKey: ch.getComponentId(),
            })

            return;
        }

        this.model.picture = file ?? null;

        await this.setState({model: this.model});

        await this.invokeOnChangeAsync();
    }

    public get model(): FormItem {
        return this.state.model;
    }

    public async componentWillReceiveProps(nextProps: IFormQuestionInputProps): Promise<void> {
        await super.componentWillReceiveProps(nextProps);

        await this.setState({model: nextProps.model});
    }

    public render(): React.ReactNode {
        const {preview} = this.props;
        const {comment, commented, name, ok, picture, type} = this.model;

        const commentLabel: string | undefined = (preview)
            ? undefined
            : (ok === false)
                ? Localizer.auditQuestionInputCommentRequired
                : Localizer.auditQuestionInputInsertComment;

        const imageInputLabel: string | undefined = (preview)
            ? undefined
            : (type === FormItemType.QuestionPictures)
                ? Localizer.auditQuestionInputPictureRequired
                : Localizer.auditQuestionInputPictureOptional;

        return (
            <FormPageRow className={this.css(styles.formQuestionInput, this.props.className)}>
                <span className={styles.description}>
                    {name}
                </span>

                <FormButton big
                            id="okButton"
                            className={styles.questionStepButtons}
                            label={Localizer.auditQuestionInputStepOk}
                            icon={{name: "fas check-circle"}}
                            disabled={preview}
                            type={ok === true ? ButtonType.Success : ButtonType.Unset}
                            onClick={async () => await this.setOk(true)}
                />

                <FormButton big
                            className={styles.questionStepButtons}
                            id="notOkButton"
                            label={Localizer.auditQuestionInputNeedRepairs}
                            icon={{name: "fas times-circle"}}
                            disabled={preview}
                            type={ok === false ? ButtonType.Danger : ButtonType.Unset}
                            onClick={async () => await this.setOk(false)}
                />

                <FormButton big
                            className={styles.questionStepButtons}
                            id="commentButton"
                            label={Localizer.genericComment.toUpperCase()}
                            icon={{name: "fas pencil-alt"}}
                            disabled={preview || (ok === false)}
                            type={commented ? ButtonType.Blue : ButtonType.Unset}
                            onClick={async () => await this.toggleCommented()}
                />

                {
                    (commented) &&
                    (
                        <TextAreaInput id="comment"
                                       ref={this._commentRef}
                                       className={this.css(styles.comment)}
                                       rows={3}
                                       readonly={preview}
                                       value={comment || ""}
                                       label={commentLabel}
                                       onChange={async (_, value) => await this.setComment(value)}
                        />
                    )
                }

                {
                    ((commented) || (type === FormItemType.QuestionPictures)) && (
                        <>
                            <span>
                                {imageInputLabel}
                            </span>

                            <ImageInput key={this.state.imageInputKey}
                                        className={styles.imageInput}
                                        multi={false}
                                        readonly={preview}
                                        pictures={picture ? [picture] : []}
                                        onChange={async (_, pictures) => await this.onFileInputChangeHandlerAsync(pictures[0])}
                                        convertImage={this.props.convertImage}
                                        fileTypes={[...RentaTaskConstants.supportedImageFileExtensions]}
                                        previewToolbar={{
                                            downloadButton: true,
                                        }}
                                        editToolbar={{
                                            downloadButton: true,
                                        }}
                                        selectionToolbar={{
                                            downloadButton: true,
                                        }}
                            />
                        </>
                    )
                }
            </FormPageRow>
        );
    }
}