import {ProductUnit, RentalItemActionType} from "@/models/Enums";
import RentalItem from "@/models/connect/RentalItem";

export default class WorkOrderRentalItemModel {
    
    public constructor(rentalItem: RentalItem) {
        const {externalId, name} = rentalItem;

        this.rentalItemExternalId = externalId ?? "";
        this.rentalItemName = name;
    }

    public id: string | null = null;

    public workOrderId: string = "" ;

    public rentalItemExternalId: string = "";

    public rentalItemName: string | null = null;

    public rentDate: Date = new Date();

    public actionType: RentalItemActionType = RentalItemActionType.Leave;

    public comment: string | null = null;

    public unit: ProductUnit | null = null;
}