import {executeWithIntercept, pageData} from "@/helpers/CypressHelper";

const workOrderName = `Test mobile work order - ReceiversListed ${new Date().toISOString()}`;

const createWorkOrder = (workOrderName: string): void  => {
    cy.init("Desktop");
    cy.loginAsAdmin();
    cy.addWorkOrder({
        name: workOrderName,
        workOrderType: "Enabled",
        managers: ["Renta.OperatorContract.Manager@automatic.test.rentatask.fi"],
        status: "New",
        ordererFullName: "ContactPerson Tester",
        approverFullName: "ContactPerson Tester",
        customerAndSite: "Customer.SubCompanyContractNoExternalId.ConstructionSite",
    });
    cy.logout();
}
const clean = (): void => {
    goToForms();

    cy.get('#activeForms').within(() => {
        cy.contains("ANYTIME Enabled").parent("tr").within(() => {
            cy.get('button[title=Cancel]').click();
        })
    });
}
const test = (): void => {
    goToFormSummaryStep();
    describe("Emails are listed after selecting whom to share the form with", () => {
        cy.get('#formReceivers ul li')
            .contains("Renta.OperatorContract.Manager@automatic.test.rentatask.fi")
            .should('have.length', 1);
    });
}

const goToForms = (): void => {
    cy.navigateToMobileWorkOrdersPage();

    cy.get('#workOrdersList').within(() => {
        cy.contains(workOrderName).click();
    });

    cy.get('#workOrderFormsButton').click();
}

const goToFormSummaryStep = (): void => {
    goToForms();
    cy.get('#activeForms').within(() => {
        cy.contains("ANYTIME Enabled").parent("tr").within(() => {
            cy.get('button[title=Fill]').click();
        })
    });

    executeWithIntercept(
        () => cy.contains('Are you sure you want to activate and sign in?').should('be.visible').parent().within(() => {
            cy.contains('Confirm').should('be.visible').trigger('click')
        }),
        [pageData().workOrder.routes.signIn]
    );

    pageData().mobileFormSteps.questionStep.okButton().click();
    pageData().mobileFormSteps.nextButton().click();
    cy.contains('Pictures').should('be.visible');
    pageData().mobileFormSteps.nextButton().click();

    cy.get('#choose-receivers').within(() => {
        cy.contains("Manager Tester").parent().parent().within(() => {
            cy.get('.athenaeum-checkbox-checkbox').click();
        })
    });
}

context("(Mobile) Forms", () => {
    beforeEach(() => {
        createWorkOrder(workOrderName);
        cy.init("Mobile");
    });

    it("Should work correctly for Mounter", () => {
        cy.loginAsMounter("Renta.OperatorContract.Mounter-2@automatic.test.rentatask.fi");
        test();
        clean();
    });
});