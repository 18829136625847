import {pageData} from "@/helpers/CypressHelper";

const formName = `Not required form item check ${new Date()}`
const workOrderName = "WorkOrderWithAFormWithANotRequiredFormItemCheck";

context("(Desktop) Forms", () => {

    beforeEach(() => {
        cy.init("Desktop");
    });

    it("Should work correctly for Admin", () => {
        cy.loginAsAdmin();
        test();
    });

    const addFormDefinitionSettings: AddFormDefinitionSettings = {
        formName: formName,
        customType: 'Undefined',
        description: `Test Not Required Form Item Check ${new Date()} description`,
        reportingHeaders: ['Customer name'],
        workOrders: ['Enabled'],
        timeslot: 'Anytime',
        required: false,
        multiple: false,
        approvable: false,
        mounterSignaturesRequired: false,
        steps: [
            {
                name: "Test step name",
                typeDropdown: 'Checks',
                stepTypeSettings: {
                    checkStepItems: [
                        { checkStepName: "Test Not Required", checkStepRequired: false },
                        { checkStepName: "Test Required", checkStepRequired: true }
                    ]
                }
            }
        ]
    }

    function test(): void {
        describe("Create form definition with not required check step", () => {
            cy.addFormDefinition(addFormDefinitionSettings);
        });

        describe("Create work order with a form with not required check step", () => {
            const addWorkOrderSettings: AddWorkOrderSettings = {
                customerAndSite: "Customer.SubCompanyContractNoExternalId.ConstructionSite",
                status: "New",
                name: workOrderName,
                workOrderType: "Enabled",
                startDate: new Date(),
                managers: ["Renta.OperatorContract.Manager@automatic.test.rentatask.fi"],
                mounters: [
                    "Renta.OperatorContract.Mounter@automatic.test.rentatask.fi",
                    "Renta.OperatorContract.Mounter-1@automatic.test.rentatask.fi",
                    "Renta.OperatorContract.Mounter-2@automatic.test.rentatask.fi"
                ],
                ordererFullName: "ContactPerson Tester",
                approverFullName: "ContactPerson Tester",
            }

            cy.addWorkOrder(addWorkOrderSettings);
        });
    }
});


context("(Mobile) Forms", () => {

    beforeEach(() => {
        cy.init("Mobile");
    });

    it("Should work correctly for Mounter", () => {
        cy.loginAsMounter();
        test();
    });

    function test(): void {
        goChecksStep();

        // go to form summary
        pageData().mobileFormSteps.nextButton().click();

        // assert that the form cannot be completed without setting the required check
        pageData().mobileFormSteps.summaryStep.completeButton().should('be.disabled');

        // set the required check, ignore the one that is not required
        pageData().mobileFormSteps.previousButton().click();
        cy.contains('Test Required').parent().parent().within(() => {
            cy.get('.athenaeum-switch-nullableSwitch').find('.athenaeum-switch-zone').eq(0).click();
        });

        pageData().mobileFormSteps.nextButton().click();
        pageData().mobileFormSteps.summaryStep.completeButton().click();
    }

    function goChecksStep(): void {
        cy.navigateToMobileWorkOrdersPage();

        cy.get('#workOrdersList').within(() => {
            cy.contains(workOrderName).click();
        });

        cy.get('#workOrderFormsButton').click();

        cy.get('#activeForms').within(() => {
            cy.contains(formName).parent("tr").within(() => {
                cy.get('button[title=Fill]').click();
            })
        });
    }
});