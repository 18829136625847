import React from "react";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import {Button, ButtonType, Dropdown, DropdownOrderBy, Form, IconSize, JustifyContent, SelectListItem, Spinner, TextInput, ToolbarContainer, ToolbarRow} from "@renta-apps/athenaeum-react-components";
import ToolbarModel from "./ToolbarModel";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "../../../localization/Localizer";
import FeatureFlags from "@/helpers/FeatureFlags";
import UnleashHelper from "@/helpers/UnleashHelper";

import styles from "./Toolbar.module.scss";

interface IToolbarProps  {
    model?: ToolbarModel;
    onChange?(model: ToolbarModel): Promise<void>;
}

interface IToolbarState {
    model: ToolbarModel
}

export default class Toolbar extends BaseComponent<IToolbarProps, IToolbarState> {

    state: IToolbarState = {
        model: this.props.model || new ToolbarModel()
    };

    private async processOnChange(invoke: boolean = false): Promise<void> {
        await this.setState({ model: this.state.model });
        if ((invoke) && (this.props.onChange)) {
            await this.props.onChange(this.state.model);
        }
    }

    private async setSearchTermAsync(searchTerm: string): Promise<void> {
        this.state.model.searchTerm = searchTerm;
        await this.processOnChange();
    }

    private async setStatusAsync(item: SelectListItem | null): Promise<void> {
        this.state.model.status = (item) ? parseInt(item.value) : null;
        await this.processOnChange(true);
    }

    private async clearAsync(): Promise<void> {
        this.state.model = new ToolbarModel();
        await this.processOnChange(true);
    }

    private renderSearchInput() {
        const isProjectNumberEnabled: boolean = UnleashHelper.isEnabled(FeatureFlags.ProjectNumberInConstructionSites);

        return (
            <TextInput id="searchTerm" inline small
                       placeholder={isProjectNumberEnabled ? Localizer.constructionSitesToolBarSearchTermFilterWithProjectNumber : Localizer.constructionSitesToolBarSearchTermFilterWithReference}
                       value={this.state.model.searchTerm}
                       width="22rem"
                       onChange={async (_, value) => await this.setSearchTermAsync(value)}
            />
        )
    }

    public render(): React.ReactNode {

        return (
            <ToolbarContainer className={styles.toolbar}>

                <ToolbarRow justify={JustifyContent.SpaceBetween}>

                    <Form inline onSubmit={async () => await this.processOnChange(true)}>

                        {this.renderSearchInput()}

                        <Dropdown inline small noValidate noSubtext noWrap autoCollapse
                                  id="constructionSitesStatus"
                                  nothingSelectedText={Localizer.addConstructionsiteToolbarAnyStatus}
                                  minWidth="7rem"
                                  orderBy={DropdownOrderBy.None}
                                  items={EnumProvider.getConstructionSiteStatusItems()}
                                  selectedItem={(this.state.model.status != null) ? EnumProvider.getConstructionSiteStatusItem(this.state.model.status) : undefined}
                                  onChange={async (_, item) => await this.setStatusAsync(item)}
                        />

                        <Button small submit
                                id="filterConstructionSites"
                                label={Localizer.addConstructionsiteToolbarFilter}
                                icon={{name : "fas search"}}
                                type={ButtonType.Orange}
                        />

                        <Button small title={Localizer.addConstructionsiteToolbarClearFilter}
                                id="clearConstructionSitesFilters"
                                icon={{name: "far history", size: IconSize.Large }}
                                type={ButtonType.Info}
                                onClick={async () => await this.clearAsync()}
                        />

                    </Form>

                </ToolbarRow>

                {
                    (this.isSpinning()) && <Spinner />
                }

            </ToolbarContainer>
        );
    }
};