export class SendWorkOrderExternalNotificationRequest {
    
    public workOrderId: string = "";

    public email: string = "";

    public comment: string = "";

    public userId: string = "";

    public requestDate: Date = new Date();

    public selectedMountersIds: string[] = [];
}