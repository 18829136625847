import AuthorizedPage from "@/models/base/AuthorizedPage";
import {
    Button,
    ButtonType,
    CellAction,
    CellModel, ColumnActionType,
    ColumnDefinition,
    ColumnType,
    Grid,
    GridHoveringType, GridModel,
    GridOddType,
    IconSize,
    Inline,
    JustifyContent,
    PageContainer,
    PageHeader,
    PageRow,
    RowModel,
    ToolbarContainer
} from "@renta-apps/athenaeum-react-components";
import React from "react";
import WorkOrderType from "@/models/server/WorkOrderType";
import CreateWorkOrderTypeRequest from "@/models/server/requests/CreateWorkOrderTypeRequest";
import {ActionType, TextAlign} from "@renta-apps/athenaeum-react-common";
import SaveWorkOrderTypeRequest from "@/models/server/requests/SaveWorkOrderTypeRequest";
import RentaTaskConstants from "@/helpers/RentaTaskConstants";
import Localizer from "@/localization/Localizer";
import {getCellAction} from "@/helpers/GridHelpers";
import TypeRegionMapper from "@/pages/WorkOrderTypeManagement/TypeRegionMapper/TypeRegionMapper";

import styles from "./WorkOrderTypeManagement.module.scss"

interface IWorkOrderTypeManagementProps {
}

interface IWorkOrderTypeManagementState {
    types: WorkOrderType[];
}

export default class WorkOrderTypeManagement extends AuthorizedPage<IWorkOrderTypeManagementProps, IWorkOrderTypeManagementState> {

    state: IWorkOrderTypeManagementState = {
        types: [],
    }

    private readonly _gridRef: React.RefObject<Grid<WorkOrderType>> = React.createRef();

    private readonly _columns: ColumnDefinition[] = [
        {
            header: "#",
            accessor: "#",
            className: "grey",
            textAlign: TextAlign.Center,
            minWidth: 40,
            noWrap: true
        },
        {
            header: Localizer.genericName,
            accessor: nameof(WorkOrderType.name),
            type: ColumnType.Text,
            minWidth: 600,
            editable: true,
            init: (cell: CellModel<WorkOrderType>) => this.initNameCell(cell),
            settings: {
                maxLength: RentaTaskConstants.dbKeyLength,
            },
            callback: async (cell: CellModel<WorkOrderType>, _) => await this.onNameChangeAsync(cell),
            actions: [{
                    title: Localizer.genericRegionWorkNumbers,
                    type: ColumnActionType.Details,
                    callback: async (cell: CellModel<WorkOrderType>) => await this.toggleDetailsAsync(cell),
            }],
        },
        {
            init: (cell) => this.initTypeOperations(cell),
            minWidth: 70,
            maxWidth: 70,
            actions: [
                {
                    name: "save",
                    title: Localizer.genericActionSaveLanguageItemName,
                    icon: "far save",
                    type: ActionType.Create,
                    callback: async (cell: CellModel<WorkOrderType>, _) => await this.saveTypeAsync(cell)
                },
                {
                    name: "cancel",
                    title: Localizer.genericActionCancelLanguageItemName,
                    icon: "far ban",
                    type: ActionType.Delete,
                    callback: async (cell) => await cell.row.cancelAsync()
                },
                {
                    name: "delete",
                    title: Localizer.genericActionDeleteLanguageItemName,
                    icon: "far trash-alt",
                    type: ActionType.Delete,
                    callback: async (cell) => await cell.grid.deleteAsync(cell.row.index)
                },
                {
                    name: "enable",
                    title: Localizer.genericActionEnableLanguageItemName,
                    icon: "far toggle-off",
                    type: ActionType.Default,
                    callback: async (cell: CellModel<WorkOrderType>, _) => await this.toggleTypeAsync(cell, false)
                },
                {
                    name: "disable",
                    title: Localizer.genericActionDisableLanguageItemName,
                    icon: "far toggle-on",
                    type: ActionType.Default,
                    callback: async (cell: CellModel<WorkOrderType>, _) => await this.toggleTypeAsync(cell, true)
                },
            ]
        }
    ];

    private get grid(): GridModel<WorkOrderType> {
        return this._gridRef.current!.model;
    }

    private get newRowAlreadyExists(): boolean {
        return (this.grid.rows.some(row => !row.deleted && !row.model.id));
    }

    private initRow(row: RowModel<WorkOrderType>): void {
        const model: WorkOrderType = row.model;

        const isNew: boolean = ((model.id == "") || (model.id == null));
        const isValid: boolean = this.isNameValid(model);

        row.className = (!isValid)
            ? "danger"
            : (isNew)
                ? "bg-processed"
                : "";
    }

    private initNameCell(cell: CellModel<WorkOrderType>): void {
        if (cell.model.disabled) {
            cell.className = styles.disabled;
        }
        
        const creatingNew: boolean = !cell.model.id;
        const detailsAction: CellAction<WorkOrderType> = getCellAction(cell.actions, "details");
        detailsAction.visible = !creatingNew;
    }

    private isNameValid(model: WorkOrderType): boolean {
        return (model.name.trim().length > 1);
    }

    private initTypeOperations(cell: CellModel<WorkOrderType>) {
        const model: WorkOrderType = cell.row.model;
        const isNew: boolean = !model.id;
        const modified: boolean = cell.row.modified;
        const isValid: boolean = this.isNameValid(model);

        const saveAction: CellAction<WorkOrderType> = getCellAction(cell.actions, "save");
        saveAction.visible = (modified) && (isValid);

        const cancelAction: CellAction<WorkOrderType> = getCellAction(cell.actions, "cancel");
        cancelAction.visible = (modified) && (!isNew);

        const deleteAction: CellAction<WorkOrderType> = getCellAction(cell.actions, "delete");
        deleteAction.visible = (isNew);

        const enableAction: CellAction<WorkOrderType> = getCellAction(cell.actions, "enable");
        enableAction.visible = (model.disabled) && (!isNew) && (!modified);

        const disableAction: CellAction<WorkOrderType> = getCellAction(cell.actions, "disable");
        disableAction.visible = (!model.disabled) && (!isNew) && (!modified);
    }

    private async toggleDetailsAsync(cell: CellModel<WorkOrderType>): Promise<void> {
        const spannedRows: RowModel<WorkOrderType>[] = cell.spannedRows;
        const rowToExpand: RowModel<WorkOrderType> = spannedRows[spannedRows.length - 1];
        await rowToExpand.toggleAsync();
    }

    private async getWorkOrderTypesAsync(): Promise<WorkOrderType[]> {
        const types: WorkOrderType[] = await this.getAsync("api/workOrderType/getWorkOrderTypes");
        return types
            .sort((a: WorkOrderType, b: WorkOrderType) => {
                if (a.disabled === b.disabled) {
                    // Sort by name
                    return a.name.localeCompare(b.name);
                }

                // ... and put enabled on top.
                return a.disabled ? 1 : -1;
            });
    }

    private async onNameChangeAsync(cell: CellModel<WorkOrderType>): Promise<void> {
        const isValid: boolean = this.isNameValid(cell.model);

        await cell.setValidAsync(isValid);
    }

    private async saveTypeAsync(cell: CellModel<WorkOrderType>): Promise<void> {
        const types: WorkOrderType[] = await this.getWorkOrderTypesAsync();
        if (types.some((type: WorkOrderType) : boolean => type.name === cell.model.name)) {
            await this.alertErrorAsync(Localizer.workOrderTypeManagementTypeExistsAlert.format(cell.model.name));
            return;
        }
        
        if (cell.model.id === "") {
            // Create
            const request: CreateWorkOrderTypeRequest = {
                name: cell.model.name,
            };

            await this.postAsync("api/admin/createWorkOrderType", request);
        } else {
            // Update
            const request: SaveWorkOrderTypeRequest = {
                workOrderTypeId: cell.model.id,
                name: cell.model.name,
                disabled: cell.model.disabled,
            };

            await this.postAsync("api/admin/saveWorkOrderType", request);
        }

        await this.refreshDataAsync();
    }

    private async toggleTypeAsync(cell: CellModel<WorkOrderType>, disabled: boolean): Promise<void> {
        const request: SaveWorkOrderTypeRequest = {
            workOrderTypeId: cell.model.id,
            name: cell.model.name,
            disabled: disabled,
        };

        await this.postAsync("api/admin/saveWorkOrderType", request);

        await this.refreshDataAsync();
    }

    private async onAddClickAsync(): Promise<void> {
        if (!this.newRowAlreadyExists) {
            const newType: WorkOrderType = new WorkOrderType();
            newType.id = "";
            newType.name = "";
            newType.disabled = false;

            // Add to grid
            const rows: RowModel<WorkOrderType>[] = await this.grid.insertAsync(0, newType);
            // Set focus to new type's name column
            await rows[0].get(nameof(WorkOrderType.name)).editAsync();
        }
    }

    public getTitle(): string {
        return Localizer.workOrderTypeManagementPageTitle;
    }

    public async componentDidMount(): Promise<void> {
        await this.refreshDataAsync();
    }

    private async refreshDataAsync(): Promise<void> {
        const types: WorkOrderType[] = await this.getWorkOrderTypesAsync();
        await this.setState({types});
    }

    private renderDetails(row: RowModel<WorkOrderType>): React.JSX.Element {
        return (<TypeRegionMapper workOrderType={row.model} />);
    }

    public render(): React.ReactNode {
        return (
            <PageContainer className={styles.workOrderTypeManagement}>
                <PageHeader title={Localizer.workOrderTypeManagementPageTitle}/>

                <PageRow>
                    <div className="col">

                        <div className={styles.container}>

                            <ToolbarContainer className={styles.toolbar}>

                                <Inline justify={JustifyContent.End}>

                                    <Button id="addWorkOrderType"
                                            icon={{name: "plus", size: IconSize.Large}}
                                            title={Localizer.genericActionAdd}
                                            type={ButtonType.Orange}
                                            onClick={async (_, __) => await this.onAddClickAsync()}
                                    />

                                </Inline>

                            </ToolbarContainer>

                            <Grid responsive
                                  id="workOrderTypesGrid"
                                  ref={this._gridRef}
                                  className={styles.grid}
                                  minWidth="auto"
                                  hovering={GridHoveringType.EditableCell}
                                  odd={GridOddType.None}
                                  columns={this._columns}
                                  data={this.state.types}
                                  initRow={(row: RowModel<WorkOrderType>) => this.initRow(row)}
                                  noDataText={Localizer.genericNoData}
                                  renderDetails={(row: RowModel<WorkOrderType>) => this.renderDetails(row)}
                            />

                        </div>

                    </div>
                </PageRow>
            </PageContainer>
        );
    }
}