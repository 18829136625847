import React from "react";
import {GeoLocation, IPagedList, SortDirection, Utility} from "@renta-apps/athenaeum-toolkit";
import {AddressHelper, CellAction, CellModel, ColumnDefinition, ColumnType, Grid, GridHoveringType, GridModel, PageContainer, PageHeader, PageRow, RowModel} from "@renta-apps/athenaeum-react-components";
import {ch, PageRoute, PageRouteProvider, TextAlign} from "@renta-apps/athenaeum-react-common";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import ConstructionSite from "../../models/server/ConstructionSite";
import GetConstructionSitesRequest from "../../models/server/requests/GetConstructionSitesRequest";
import {ActionType, ConstructionSiteStatus} from "@/models/Enums";
import Toolbar from "./Toolbar/Toolbar";
import ToolbarModel from "./Toolbar/ToolbarModel";
import SaveConstructionSiteRequest from "../../models/server/requests/SaveConstructionSiteRequest";
import PageDefinitions from "../../providers/PageDefinitions";
import ConstructionSiteNavigator from "@/pages/ConstructionSiteManagement/ConstructionSiteNavigator";
import UserContext from "@/models/server/UserContext";
import UserInteractionDataStorage from "@/providers/UserInteractionDataStorage";
import Localizer from "../../localization/Localizer";
import FeatureFlags from "@/helpers/FeatureFlags";
import UnleashHelper from "@/helpers/UnleashHelper";

interface IConstructionSitesProps {
}

interface IConstructionSitesState {
    filters: ToolbarModel
}

export default class ConstructionSites extends AuthorizedPage<IConstructionSitesProps, IConstructionSitesState> {

    state: IConstructionSitesState = {
        filters: ConstructionSites.initializeToolbar()
    };

    private static initializeToolbar(): ToolbarModel {
        return UserInteractionDataStorage.getFilters(new ToolbarModel());
    }

    private readonly _sitesRef: React.RefObject<Grid<ConstructionSite>> = React.createRef();

    private columns: ColumnDefinition[] = [
        {
            header: Localizer.constructionSitesToolbarCustomerLanguageItemName,
            accessor: "organizationContract.name",
            sorting: true,
            minWidth: "20rem",
            settings: {
                infoAccessor: "organization.vatId"
            }
        },
        {
            header: Localizer.constructionSitesNameLanguageItemName,
            accessor: "name",
            sorting: true,
            minWidth: "20rem",
            transform: (cell, value) => (value != "") ? cell.value : Localizer.genericNoName,
            route: (cell: CellModel<ConstructionSite>) => PageDefinitions.constructionSiteManagement(cell.model.id),
            actions: [
                {
                    name: nameof<ConstructionSite>(o => o.name),
                    title: Localizer.genericOpen,
                    icon: "fal external-link-square",
                    type: ActionType.Default,
                    callback: async (cell) => await this.triggerRouteAsync(cell.route)
                },
            ]
        },
        {
            header: Localizer.constructionSitesReferenceLanguageItemName,
            accessor: "externalReference",
            sorting: true,
            textAlign: TextAlign.Left,
            minWidth: "20rem",
            visible: (!UnleashHelper.isEnabled(FeatureFlags.ProjectNumberInConstructionSites)),
        },
        {
            header: Localizer.genericProjectNumberLanguageItemName,
            accessor: "projectNumber",
            sorting: true,
            textAlign: TextAlign.Left,
            minWidth: "20rem",
            visible: UnleashHelper.isEnabled(FeatureFlags.ProjectNumberInConstructionSites),
        },
        {
            header: Localizer.formInputAddressLanguageItemName,
            accessor: "location.formattedAddress",
            type: ColumnType.Address,
            sorting: true,
            noWrap: true,
            minWidth: "20rem",
        },
        {
            header: Localizer.constructionSitesStatusLanguageItemName,
            accessor: "status",
            sorting: true,
            format: "ConstructionSiteStatus",
            type: ColumnType.Custom,
            editable: false,
            minWidth: 100,
        },
        {
            minWidth: 99,
            init: (cell) => this.initConstructionSiteOperationsAsync(cell),
            actions: [
                {
                    name: "save",
                    title: Localizer.constructionSitesCommitChangesLanguageItemName,
                    icon: "far save",
                    type: ActionType.Create,
                    callback: async (cell, action) => await this.processConstructionSiteOperationAsync(cell, action)
                },
                {
                    name: "cancel",
                    title: Localizer.constructionSitesCancelChangesLanguageItemName,
                    icon: "far ban",
                    type: ActionType.Delete,
                    callback: async (cell, action) => await this.processConstructionSiteOperationAsync(cell, action)
                },
                {
                    name: "activate",
                    title: Localizer.constructionSitesActivateLanguageItemName,
                    icon: "far play-circle",
                    type: ActionType.Create,
                    confirm: (cell: CellModel<ConstructionSite>) => Utility.format(Localizer.constructionSitesConfirmationActivateSite, cell.model.name),
                    callback: async (cell, action) => await this.processConstructionSiteOperationAsync(cell, action)
                },
                {
                    name: "deactivate",
                    title: Localizer.constructionSitesDeactivateLanguageItemName,
                    icon: "far stop-circle",
                    type: ActionType.Default,
                    confirm: (cell: CellModel<ConstructionSite>) => Utility.format(Localizer.constructionSitesConfirmationDeactivateSite, cell.model.name),
                    callback: async (cell, action) => await this.processConstructionSiteOperationAsync(cell, action)
                }
            ]
        }
    ];
    private async processConstructionSiteOperationAsync(cell: CellModel<ConstructionSite>, action: CellAction<ConstructionSite>): Promise<void> {
        const model: ConstructionSite = cell.row.cells[0].model;

        if (action.action.name === "save") {

            const request = new SaveConstructionSiteRequest();
            request.constructionSiteId = model.id;
            request.organizationContractId = (model.organizationContract) ? model.organizationContract.id : "";
            request.location = (model.location) ? AddressHelper.toLocation(model.location!.formattedAddress)! : new GeoLocation();
            request.name = model.name;
            request.status = model.status;
            request.externalId = model.externalId;

            await cell.grid.postAsync("api/constructionSites/saveConstructionSite", request);
            await cell.row.saveAsync();

        } else if (action.action.name === "cancel") {

            await cell.row.cancelAsync();

        } else if (action.action.name === "activate") {

            model.status = ConstructionSiteStatus.Active;

            await cell.grid.postAsync("api/constructionSites/activateConstructionSite", model.id);
            await cell.row.bindAsync();

        } else if (action.action.name === "deactivate") {

            model.status = ConstructionSiteStatus.Inactive;

            await cell.grid.postAsync("api/constructionSites/deactivateConstructionSite", model.id);
            await cell.row.bindAsync();
        }
    }

    private initRow(row: RowModel<ConstructionSite>): void {
        const model: ConstructionSite = row.model;
        if (model.location == null) {
            model.location = new GeoLocation();
        }
    }

    private async initConstructionSiteOperationsAsync(cell: CellModel<ConstructionSite>): Promise<void> {

        const model: ConstructionSite = cell.row.model;
        const modified: boolean = cell.row.modified;
        const isActive: boolean = (model.status == ConstructionSiteStatus.Active);
        const isClosed: boolean = (model.status == ConstructionSiteStatus.Closed);

        const hasAddress: boolean = (model.location != null && model.location!.formattedAddress != "");

        const saveAction: CellAction<ConstructionSite> = cell.actions[0];
        const cancelAction: CellAction<ConstructionSite> = cell.actions[1];
        const activateAction: CellAction<ConstructionSite> = cell.actions[2];
        const disableAction: CellAction<ConstructionSite> = cell.actions[3];

        saveAction.visible = (modified) && ((hasAddress) || (!isActive));
        cancelAction.visible = (modified);
        activateAction.visible = (!isActive) && (!isClosed) && (!modified) && (hasAddress);
        disableAction.visible = (isActive) && (!isClosed) && (!modified);
    }

    public get userContext(): UserContext {
        return (ch.getContext() as UserContext);
    }

    private async onFilterAsync(filters: ToolbarModel): Promise<void> {
        await this.setState({filters});

        UserInteractionDataStorage.setFilters(filters);

        await this.sitesGrid.reloadAsync();
    }

    private async getConstructionSitesAsync(sender: Grid<ConstructionSite>, pageNumber: number, pageSize: number, sortColumnName: string | null, sortDirection: SortDirection | null): Promise<IPagedList<ConstructionSite>> {

        UserInteractionDataStorage.setFilters(sortColumnName, "ConstructionSites.SortColumn");
        UserInteractionDataStorage.setFilters(sortDirection, "ConstructionSites.SortDirection");
        
        const request = new GetConstructionSitesRequest();
        request.pageNumber = pageNumber;
        request.pageSize = pageSize;
        request.sortColumnName = sortColumnName;
        request.sortDirection = sortDirection;
        request.status = this.state.filters.status;
        request.searchTerm = this.state.filters.searchTerm;

        ConstructionSiteNavigator.filters = request;

        return await sender.postAsync("api/constructionSites/getConstructionSites", request);
    }

    private get sitesGrid(): GridModel<ConstructionSite> {
        return this._sitesRef.current!.model;
    }

    private async triggerRouteAsync(route: PageRoute | null): Promise<void> {
        if (route) {
            await PageRouteProvider.redirectAsync(route);
        }
    }

    private get sortColumn(): string {
        return UserInteractionDataStorage.getFilters("organizationContract.name", "ConstructionSites.SortColumn");
    }

    private get sortDirection(): SortDirection {
        return UserInteractionDataStorage.getFilters(SortDirection.Asc, "ConstructionSites.SortDirection");
    }

    public getTitle(): string {
        return Localizer.topNavConstructionSites;
    }

    public render(): React.ReactNode {
        return (
            <PageContainer scale>
                <PageHeader title={Localizer.constructionSitesSites} subtitle={Localizer.constructionSitesSitesInfo}/>

                <PageRow>

                    <div className="col">

                        <div className="d-inline-block">

                            <Toolbar model={this.state.filters}
                                     onChange={async (model) => this.onFilterAsync(model)}
                            />

                            <Grid id="sites" ref={this._sitesRef} autoToggle pagination
                                  minWidth="auto"
                                  hovering={GridHoveringType.Row}
                                  headerMinHeight={80}
                                  columns={this.columns}
                                  initRow={(row) => this.initRow(row)}
                                  fetchData={async (sender, pageNumber, pageSize, sortColumnName, sortDirection) => await this.getConstructionSitesAsync(sender, pageNumber, pageSize, sortColumnName, sortDirection)}
                                  defaultSortColumn={this.sortColumn}
                                  defaultSortDirection={this.sortDirection}
                            />
                        </div>

                    </div>

                </PageRow>

            </PageContainer>
        );
    }
}