import User from "./User";
import {FileModel, Utility} from "@renta-apps/athenaeum-toolkit";
import UserSalaryHour from "@/models/server/UserSalaryHour";
import WorkOrderEquipment from "@/models/server/WorkOrderEquipment";
import ConstructionSiteOrWarehouse from "@/models/server/ConstructionSiteOrWarehouse";
import {CustomerApprovalType, TaskStatusFilter, WorkOrderContractType, WorkOrderStatus} from "@/models/Enums";
import WorkOrderDistance from "@/models/server/WorkOrderDistance";
import {DefaultPrices} from "@/models/server/DefaultPrices";
import {IIconProps} from "@renta-apps/athenaeum-react-components";
import WorkReportAttachment from "@/models/server/WorkReportAttachment";
import UserContext from "@/models/server/UserContext";
import {ch} from "@renta-apps/athenaeum-react-common";
import RentaTaskConstants from "@/helpers/RentaTaskConstants";
import WorkOrderType from "@/models/server/WorkOrderType";
import WorkOrderExtraCharge from "@/models/server/WorkOrderExtraCharge";
import WorkOrderRentalItemModel from "@/models/server/WorkOrderRentalItemModel";
import TransformProvider from "@/providers/TransformProvider";
import Localizer from "@/localization/Localizer";
import isNullOrDefaultGuid from "@/functions/IsNullOrDefaultGuid";
import UnleashHelper from "@/helpers/UnleashHelper";
import FeatureFlags from "@/helpers/FeatureFlags";

export type WorkOrderAction = "activate" | "approveSent" | "cancel" | "cancelInvoice" | "complete" | "copy" | "delete" | "lock" | "invoice" | "preview" | "notification" | "restore" | "save" | "send" | "unlock" | "forms" | "pause" | "unpause";

export interface IWorkOrderEditability {

    /**
     * Is the Work Order overall editable.
     *
     * @see pricesEditable
     */
    editable: boolean;

    /**
     * Are the Work Orders {@link WorkOrderModel.mileagePrice} and related entities prices editable.
     *
     * @see editable
     */
    pricesEditable: boolean;

    /**
     * Is the Work Order deletable.
     */
    deletable: boolean;
}

export default class WorkOrderModel {

    public code: string = "";

    public id: string = "";

    public number: number = 0;

    public name: string | null = null;

    public createdBy: User | null = null;

    public hoursPrice: number | null = null;

    public mileagePrice: number | null = null;

    public alarmJobPrice: number | null = null;

    public description: string | null = null;

    public invoiceReference: string | null = null;

    public jobSummary: string | null = null;

    /**
     * On the calendar page field contains date AND time.
     * On task grid and edit page it only contains date.
     */
    public activationDate: Date | null = null;

    /**
     * On the calendar page field contains date AND time.
     * On task grid and edit page it only contains date.
     */
    public completionDate: Date | null = null;

    /**
     * On the calendar page field contains date AND time.
     * On task grid and edit page it only contains date.
     */
    public plannedCompletionDate: Date | null = null;

    public pauseStartDate: Date | null = null;

    public pauseEndDate: Date | null = null;

    public constructionSiteId: string | null = null;

    public warehouseId: string | null = null;

    public owner: ConstructionSiteOrWarehouse | null = null;

    public locked: boolean = false;

    public approvalType: CustomerApprovalType = CustomerApprovalType.Email;

    public deleted: boolean = false;

    public sent: boolean = false;

    public sentAt: Date | null = null;

    public approved: boolean = false;

    public approvedAt: Date | null = null;

    public approvedBy: User | null = null;

    public declined: boolean = false;

    public declinedAt: Date | null = null;

    public declinedBy: User | null = null;

    public completed: boolean = false;

    public completedBy: User | null = null;

    public readyForInvoicing: boolean = false;

    public invoiced: boolean = false;

    public autoHours: number | null = null;

    public normalHours: number | null = null;

    public overtime50Hours: number | null = null;

    public overtime100Hours: number | null = null;

    public overtimeTotalHours: number | null = null;

    public hasSalaryHours: boolean = false;

    public hasDistances: boolean = false;

    public cost: number = 0;

    public distance: number = 0;

    public mountersIds: string[] = [];

    public mounters: User[] = [];

    public managerId: string | null = null;

    public manager: User | null = null;

    public customerOrdererId: string | null = null;

    public customerOrderer: User | null = null;

    public customerApproverId: string | null = null;

    public customerApprover: User | null = null;

    public userSalaryHours: UserSalaryHour[] | null = null;

    public equipments: WorkOrderEquipment[] | null = null;

    public extraCharges: WorkOrderExtraCharge[] | null = null;

    public rentalEquipments: WorkOrderRentalItemModel[] | null = null;

    public distances: WorkOrderDistance[] | null = null;

    public currentStatus: WorkOrderStatus = WorkOrderStatus.Created;

    public currentStatusComment: string | null = null;

    public previousStatus: WorkOrderStatus  | null = null;

    /**
     * Id of {@link signatureFile}.
     */
    public signatureFileId: string | null = null;

    /**
     * File containing customers approval signature.
     *
     * @see signatureFileId
     * @see signatureNameClarification
     */
    public signatureFile: FileModel | null = null;

    /**
     * Name clarification of {@link signatureFile}. Required if the Work Order is approved by signature.
     *
     * NOTE: Old signature-approved Work Orders might not have this.
     */
    public signatureNameClarification: string | null = null;

    public modifiedAt: Date = new Date();

    public attachments: WorkReportAttachment[] = [];

    /** Type of the work order. */
    public type: WorkOrderType = new WorkOrderType();

    /**
     * Does the WorkOrder have one or more Required {@link FormModel}s which have not been submitted or approved, blocking the WorkOrder starting and completion.
     */
    public hasBlockingForms: boolean = false;

    /** Type of the work order's contract. */
    public contractType: WorkOrderContractType = WorkOrderContractType.Normal;

    /**
     * Total cost if contractType is FixedPrice or Warranty.
     * Not used if contractType is Normal.
     */
    public fixedCost: number | null = null;
    
    /**
     * Products' cost if contractType is FixedPrice or Warranty.
     * Not used if contractType is Normal.
     */
    public fixedProductCost: number | null = null;

    /**
     * Transportation cost if contractType is FixedPrice or Warranty.
     * Not used if contractType is Normal.
     */
    public fixedTransportationCost: number | null = null;

    /**
     * Hours' cost if contractType is FixedPrice or Warranty.
     * Not used if contractType is Normal.
     */
    public fixedHoursCost: number | null = null;

    public isWorkOrderModel: boolean = true;

    public static isNew(workOrder: Readonly<WorkOrderModel>): boolean {
        return (isNullOrDefaultGuid(workOrder.id));
    }

    public static isActive(workOrder: WorkOrderModel): boolean {
        return (!workOrder.deleted) && (workOrder.currentStatus === WorkOrderStatus.InProgress);
    }

    public static isCompleted(workOrder: WorkOrderModel): boolean {
        return (!workOrder.deleted) && (workOrder.completed);
    }

    public static isApproved(workOrder: WorkOrderModel): boolean {
        return (!workOrder.deleted) && (workOrder.approved);
    }

    public static isReadyForInvoicing(workOrder: WorkOrderModel): boolean {
        return (!workOrder.deleted) && (workOrder.readyForInvoicing);
    }

    public static isUnscheduled(workOrder: WorkOrderModel): boolean {
        return (!workOrder.activationDate);
    }

    public static isCreated(workOrder: WorkOrderModel): boolean {
        return (!workOrder.deleted)
            && (!!workOrder.activationDate)
            && (workOrder.activationDate > Utility.now());
    }

    public static isReactivated(workOrder: WorkOrderModel): boolean {
        return (workOrder.currentStatus == WorkOrderStatus.InProgress) &&
            (
                workOrder.previousStatus == WorkOrderStatus.Completed ||
                workOrder.previousStatus == WorkOrderStatus.SentToCustomer ||
                workOrder.previousStatus == WorkOrderStatus.ApprovedByCustomer ||
                workOrder.previousStatus == WorkOrderStatus.DeclinedByCustomer
            );
    }

    public static hasBlockingForms(workOrder: WorkOrderModel): boolean {
        return (!workOrder.deleted) && (workOrder.hasBlockingForms);
    }

    /**
     * Gets icon based on the work order's state OR blocked icon if the work order is blocked.
     * @param workOrder the model.
     * @param customIconsForBlockedByForms if true will include a wider range of blocked icons.
     */
    public static getStateWithBlockingIcon(workOrder: WorkOrderModel, customIconsForBlockedByForms: boolean = false): IIconProps {
        return WorkOrderModel.buildModelIcon(workOrder, true, customIconsForBlockedByForms);
    }

    /**
     * Gets icon based on _only_ the work order's state. Doesn't return a separate icon for blocked work orders.
     * @param workOrder the model.
     */
    public static getStateIcon(workOrder: WorkOrderModel): IIconProps {
        return WorkOrderModel.buildModelIcon(workOrder, false, false);
    }

    public static getBlockingIcon(workOrder: WorkOrderModel, customIconsForBlockedByForms: boolean): IIconProps {
        const hasBlockingForms: boolean = this.hasBlockingForms(workOrder);
        if (!hasBlockingForms) {
            return {name: "", className: "", tooltip: ""};
        }


        if (customIconsForBlockedByForms) {
            switch (workOrder.currentStatus) {
                case WorkOrderStatus.Created:
                    return {name: "fas hourglass-start", className: "light-gray bg-blue", tooltip: Localizer.taskTooltipsTaskWaitingActivation};
                case WorkOrderStatus.Completed:
                    return {name: "fas clipboard-check", className: "light-gray bg-blue", tooltip: Localizer.taskTooltipsTaskWaitingApproval};
                case WorkOrderStatus.ApprovedByCustomer:
                    return {name: "fas thumbs-up", className: "light-gray bg-blue", tooltip: Localizer.taskTooltipsTaskApproved};
                case WorkOrderStatus.SentToCustomer:
                    return {name: "fas mail-bulk", className: "light-gray bg-blue", tooltip: Localizer.taskTooltipsTaskWaitingApproval};
            }
        }

        return {name: "fas ban", className: "text-danger", tooltip: Localizer.taskTooltipsTaskBlockedByForm};
    }

    public static getStateDescription(workOrder: WorkOrderModel): string {
        const unscheduled: boolean = !workOrder.activationDate;
        const hasBlockingForms: boolean = this.hasBlockingForms(workOrder);

        if (hasBlockingForms) {
            return Localizer.workOrderStatusBlockedByForm;
        }

        return (unscheduled)
            ? Localizer.workOrderStatusUnscheduled
            : "{0:WorkOrderStatus}".format(workOrder.currentStatus);
    }

    public static getBackgroundStyle(workOrder: WorkOrderModel): string {
        if (workOrder.invoiced) {
            return "bg-processed";
        }
        if (workOrder.approved) {
            return "bg-approved";
        }
        if (workOrder.completed) {
            return "bg-completed";
        }
        return "";
    }

    public static getHoursPrice(workOrder: WorkOrderModel, defaultPrices: DefaultPrices | null = null): number {
        return (workOrder.hoursPrice != null)
            ? workOrder.hoursPrice
            : ConstructionSiteOrWarehouse.getHoursPrice(workOrder.owner, defaultPrices);
    }

    public static getAlarmJobPrice(workOrder: WorkOrderModel, defaultPrices: DefaultPrices | null = null): number {
        return (workOrder.alarmJobPrice != null)
            ? workOrder.alarmJobPrice
            : ConstructionSiteOrWarehouse.getAlarmJobPrice(workOrder.owner, defaultPrices);
    }

    public static getDistancesPrice(workOrder: WorkOrderModel, defaultPrices: DefaultPrices | null = null): number {
        return (workOrder.mileagePrice != null)
            ? workOrder.mileagePrice
            : ConstructionSiteOrWarehouse.getDistancesPrice(workOrder.owner, defaultPrices);
    }

    public static calcCost(workOrder: WorkOrderModel, defaultPrices: DefaultPrices | null = null): number {
        const salaryHoursCost: number = this.calcSalaryHoursCost(workOrder.userSalaryHours);
        const equipmentCost: number = this.calcEquipmentCost(workOrder.equipments);
        const extraChargesCost: number = this.calcExtraChargesCost(workOrder.extraCharges)
        const distancesPrice: number = this.getDistancesPrice(workOrder, defaultPrices);
        const distancesCost: number = this.calcDistancesCost(workOrder.distances, distancesPrice);
        return salaryHoursCost + equipmentCost + distancesCost + extraChargesCost;
    }

    public static calcEquipmentCost(equipments: WorkOrderEquipment[] | null): number {
        return equipments ? equipments.sum(item => item.cost) : 0;
    }

    public static calcExtraChargesCost(extraCharges: WorkOrderExtraCharge[] | null): number {
        return extraCharges ? extraCharges.sum(item => item.cost) : 0;
    }

    public static calcSalaryHoursCost(salaryHours: UserSalaryHour[] | null): number {
        return salaryHours ? salaryHours.sum(item => item.cost) : 0;
    }

    public static calcDistancesCost(distances: WorkOrderDistance[] | null, price: number): number {
        return distances ? distances.sum(item => item.value * price) : 0;
    }

    public static isApproverOrOrdererValid(user: User | null, required: boolean = false): boolean {
        return (!required) && (!user)
            || (!!user) && (User.isContactPersonValid(user));
    }

    public static hasSalaryHours(workOrder: WorkOrderModel): boolean {
        return (workOrder.userSalaryHours != null && workOrder.userSalaryHours.length > 0);
    }

    public static canReactivate(workOrder: WorkOrderModel): boolean {
        return (workOrder.currentStatus > WorkOrderStatus.InProgress && workOrder.currentStatus < WorkOrderStatus.ReadyForInvoicing);
    }

    public static plannedCompletionDateValid(workOrder: Readonly<WorkOrderModel>): boolean {
        return (workOrder.activationDate) && (workOrder.plannedCompletionDate)
            ? (workOrder.activationDate <= workOrder.plannedCompletionDate)
            : true;
    }

    public static isPaused(workOrder: Readonly<WorkOrderModel>): boolean {
        return (workOrder.pauseStartDate != null) && Utility.inInterval(
            Utility.now(),
            workOrder.pauseStartDate,
            workOrder.pauseEndDate
        );
    }
    
    public static isValid(workOrder: Readonly<WorkOrderModel>, isNew: boolean): boolean {
        const hasName: boolean = (!!workOrder.name)
            && (workOrder.name.length > 0);

        const hasConstructionSiteOrWarehouse: boolean = (!!workOrder.owner);

        const hasManager: boolean = (!!workOrder.manager);

        const hasApprover: boolean = (!!workOrder.customerApprover)
            && (User.isContactPersonValid(workOrder.customerApprover));

        const hasOrderer: boolean = (!!workOrder.customerOrderer)
            && (User.isContactPersonValid(workOrder.customerOrderer));

        const isPauseValid = (
            activation: Date | null,
            plannedCompletion: Date | null,
            completion: Date | null,
            pauseStart: Date | null,
            pauseEnd: Date | null
        ): boolean => {
            if (pauseStart !== null && activation == null) {
                return false;
            }
            if (pauseStart !== null && activation !== null && pauseStart < activation) {
                return false;
            }
            if (pauseStart !== null && plannedCompletion !== null && pauseStart > plannedCompletion) {
                return false;
            }

            if (pauseEnd !== null) {
                if (pauseStart === null) {
                    return false;
                }

                if (plannedCompletion !== null && pauseEnd > plannedCompletion) {
                    return false;
                }

                if (completion !== null && pauseEnd > completion) {
                    return false;
                }

                if (pauseEnd <= pauseStart) {
                    return false;
                }
            }

            return true;
        };

        const validPause: boolean = isPauseValid(
            workOrder.activationDate,
            workOrder.plannedCompletionDate,
            workOrder.completionDate,
            workOrder.pauseStartDate,
            workOrder.pauseEndDate)

        const validDates: boolean = (!workOrder.completionDate) || (!!workOrder.activationDate) &&
            (workOrder.completionDate.valueOf() >= workOrder.activationDate.valueOf()) &&
            (workOrder.completionDate.valueOf() <= Utility.now().valueOf());

        // Type is required only for new items.
        const hasType: boolean = (!isNew) || (!!workOrder.type.id);

        return (hasName) &&
            (hasConstructionSiteOrWarehouse) &&
            (hasManager) &&
            (hasApprover) &&
            (hasOrderer) &&
            (validDates) &&
            (validPause) &&
            (hasType) &&
            (this.plannedCompletionDateValid(workOrder));
    }

    /**
     * @param workOrder WorkOrder which actions to get
     * @param modified Is the {@link workOrder} modified in the UI
     * @param deleted Is the {@link workOrder} deleted in the UI
     * @returns Allowed actions for the {@link workOrder}
     */
    public static getActions(
        workOrder: Readonly<WorkOrderModel>,
        modified: boolean,
        deleted: boolean
    ): Record<WorkOrderAction, boolean> {
        const context: Readonly<UserContext> = ch.getContext() as UserContext;
        const editability: IWorkOrderEditability = WorkOrderModel.getEditability(workOrder);

        const isNew: boolean = (this.isNew(workOrder));
        const blockedByForm: boolean = workOrder.hasBlockingForms;
        const completed: boolean = (workOrder.currentStatus == WorkOrderStatus.Completed);
        const inProgress: boolean = (workOrder.currentStatus === WorkOrderStatus.InProgress);
        const active: boolean = (inProgress) || ((!!workOrder.activationDate) && Utility.inInterval(
            Utility.now(),
            workOrder.activationDate,
            workOrder.completionDate
        ));
        const invoiced: boolean = (workOrder.currentStatus === WorkOrderStatus.Invoiced);
        const approvedByCustomer: boolean = WorkOrderModel.isApproved(workOrder);
        const readyForInvoicing: boolean = WorkOrderModel.isReadyForInvoicing(workOrder);
        const isAdmin: boolean = context.isAdmin;
        const isBusinessManager: boolean = (isAdmin) || (context.isBusinessManager);
        const isManager: boolean = (isBusinessManager) || (context.isManager);
        const valid: boolean = this.isValid(workOrder, isNew);
        const paused: boolean = WorkOrderModel.isPaused(workOrder);
        const pauseAfterActivation = workOrder.activationDate != null && (Utility.now() >= workOrder.activationDate);
        const canPause: boolean = isManager || isBusinessManager || isAdmin;
        const notificationsEnabled: boolean = UnleashHelper.isEnabled(FeatureFlags.ExternalNotifications);

        return {
            approveSent:               (!deleted) && (!modified) && (valid) && (isManager) && (workOrder.sent === true) && (workOrder.approved === false),
            save:                      (!deleted) &&                       (!invoiced) &&  (modified)   && (valid),
            cancel:                    (!deleted) &&                       (!invoiced) &&  (modified),
            copy:                      (!deleted) &&   (!isNew) &&                         (!modified)  && (valid),
            preview:                   (!deleted) &&   (!isNew) &&                         (!modified),
            notification:              (!deleted) &&   (!isNew) &&                         (!modified)  &&  (notificationsEnabled),
            send:                      (!deleted) &&   (!isNew) &&                         (!modified)  &&                          (!approvedByCustomer) && (completed)  &&   (!blockedByForm),
            activate:                  (!deleted) &&   (!isNew) &&         (!invoiced) &&  (!modified)  &&  (!readyForInvoicing) &&                             (!active),
            complete:                  (!deleted) &&   (!isNew) &&         (!invoiced) &&  (!modified)  &&                                                      (active) && (!completed) && (!blockedByForm),
            lock:                      (!deleted) &&   (!isNew) &&         (!invoiced) &&  (!modified)  &&  (!readyForInvoicing) && (approvedByCustomer) &&   (!blockedByForm),
            unlock:                    (!deleted) &&   (!isNew) &&         (!invoiced) &&  (!modified)  &&  (readyForInvoicing)  && (approvedByCustomer)  &&                                (isBusinessManager),
            delete:                    (!deleted) &&                                       (!modified)  &&  (editability.deletable),
            restore:                   (deleted)  &&   (!isNew) &&                         (!modified),
            invoice:                   (!deleted) &&   (!isNew) &&         (!invoiced) &&  (!modified) &&  (readyForInvoicing)  &&   (!blockedByForm) &&                                    (isBusinessManager),
            cancelInvoice:                             (!isNew) &&         (invoiced)  &&  (!modified) &&                                                                                   (isAdmin),
            forms:                     (!deleted) &&   (!isNew)                        &&  (!modified),
            pause:                     (!deleted) &&   (inProgress) &&     (!paused)   &&  (!modified) && (canPause)  &&  (pauseAfterActivation),
            unpause:                   (!deleted) &&   (inProgress) &&     (paused)    &&  (!modified) && (canPause)  &&  (pauseAfterActivation),
        };
    }

    public static getEditability(workOrder: Readonly<WorkOrderModel>): IWorkOrderEditability {
        // If editing this, also update BE WorkOrderDbEntity's helper methods 'Editable', 'MileagePriceEditable' and 'Deletable'

        const {currentStatus} = workOrder;

        return {
            editable: (currentStatus <= WorkOrderStatus.Completed),
            pricesEditable: (currentStatus <= WorkOrderStatus.ReadyForInvoicing),
            deletable: (currentStatus <= WorkOrderStatus.InProgress),
        };
    }

    public static constructionSiteOrWarehouseId(workOrder: Readonly<WorkOrderModel>): string | null {
        return workOrder.owner?.id
            || workOrder.constructionSiteId
            || workOrder.warehouseId;
    }

    public static assignedMountersFullNames(workOrder: Readonly<WorkOrderModel>): string {
        return workOrder.mounters.map(mounter => TransformProvider.userToString(mounter)).join(",\n");
    }

    public static getAssignableMounters(workOrder: Readonly<WorkOrderModel>, mounters: User[]): User[] {
        mounters = mounters
            .filter(mounter => (workOrder.mountersIds.includes(mounter.id)) || (mounter.isEmployee) ||
                ((mounter.isSubcontractorMounter) &&
                workOrder.owner!.subcontractorsOrganizationContractIds.includes(mounter.role.organizationContractId))
            );

        return mounters;
    }

    /**
     * Set empty ids as default Guids to prevent errors when the {@link WorkOrderModel} is deserialized in FE controllers.
     */
    public static initializeEmptyGuids(workOrder: WorkOrderModel): void {
        if (!workOrder.id) {
            workOrder.id = RentaTaskConstants.defaultGuid;
        }

        if (workOrder.customerApprover) {
            User.initializeEmptyGuids(workOrder.customerApprover)
        }

        if (workOrder.customerOrderer) {
            User.initializeEmptyGuids(workOrder.customerOrderer);
        }
    }

    /**
     * @returns {@link workOrder}s {@link WorkOrderModel.currentStatus} as {@link TaskStatusFilter}.
     */
    public static getCurrentStatusAsTaskStatusFilter(workOrder: Readonly<WorkOrderModel>): TaskStatusFilter {
        switch (workOrder.currentStatus) {
            case WorkOrderStatus.Created:
                return (workOrder.activationDate)
                    ? TaskStatusFilter.Upcoming
                    : TaskStatusFilter.Unscheduled;
            case WorkOrderStatus.InProgress:            return TaskStatusFilter.InProgress;
            case WorkOrderStatus.Completed:             return TaskStatusFilter.Completed;
            case WorkOrderStatus.SentToCustomer:        return TaskStatusFilter.SentToCustomer;
            case WorkOrderStatus.ApprovedByCustomer:    return TaskStatusFilter.ApprovedByCustomer;
            case WorkOrderStatus.DeclinedByCustomer:    return TaskStatusFilter.DeclinedByCustomer;
            case WorkOrderStatus.ReadyForInvoicing:     return TaskStatusFilter.ReadyForInvoicing;
            case WorkOrderStatus.Invoiced:              return TaskStatusFilter.Invoiced;
            // Other statuses are unsupported.
            case WorkOrderStatus.Unknown:
            case WorkOrderStatus.Deleted:
            default:                                    throw new Error(`Not supported status ${workOrder.currentStatus}`);
        }
    }

    /**
     * Private builder method to include all scenarios of work order icons.
     * @param workOrder the model.
     * @param includeBlockingIcon if true will return separate icon if model is blocked.
     * @param customIconsForBlockedByForms if true will include a wider range of blocked icons.
     * @private
     */
    private static buildModelIcon(
        workOrder: WorkOrderModel,
        includeBlockingIcon: boolean,
        customIconsForBlockedByForms: boolean): IIconProps {

        const currentStatus: WorkOrderStatus = workOrder.currentStatus;

        const isNew: boolean = this.isNew(workOrder);
        const unscheduled: boolean = !workOrder.activationDate;
        const hasBlockingForms: boolean = this.hasBlockingForms(workOrder);

        if (isNew) {
            return {name: "far pen", className: "text-primary", tooltip: Localizer.taskTooltipsNewTask};
        }

        if (hasBlockingForms && includeBlockingIcon) {
            return WorkOrderModel.getBlockingIcon(workOrder, customIconsForBlockedByForms);
        }

        if (unscheduled) {
            return {name: "fas clock", className: "grey", tooltip: Localizer.taskTooltipsTaskUnscheduled};
        }

        switch (currentStatus) {
            case WorkOrderStatus.Completed:
                return {name: "fas clipboard-check", className: "orange", tooltip: Localizer.taskTooltipsTaskWaitingApproval};
            case WorkOrderStatus.InProgress:
                return {name: "fas running", className: "text-primary", tooltip: Localizer.taskTooltipsTaskInProgress};
            case WorkOrderStatus.Created:
                return {name: "fas hourglass-start", className: "orange", tooltip: Localizer.taskTooltipsTaskWaitingActivation};
            case WorkOrderStatus.SentToCustomer:
                return {name: "fas mail-bulk", className: "text-primary", tooltip: Localizer.taskTooltipsTaskWaitingApproval};
            case WorkOrderStatus.DeclinedByCustomer:
                return {name: "far thumbs-down", className: "text-danger", tooltip: Localizer.taskTooltipsTaskDeclined};
            case WorkOrderStatus.ApprovedByCustomer:
                return {name: "far thumbs-up", className: "text-success", tooltip: Localizer.taskTooltipsTaskApproved};
            case WorkOrderStatus.ReadyForInvoicing:
                return {name: "fas check-circle", className: "green", tooltip: Localizer.taskTooltipsTaskReadyForInvoicing};
            case WorkOrderStatus.Invoiced:
                return {name: "far file-invoice", className: "green", tooltip: Localizer.taskTooltipsTaskInvoiced};
        }

        return {name: "far fa-thumbs-up", className: "text-danger", tooltip: Localizer.taskTooltipsTaskReadyForInvoicing};
    }

    /**
     * Is the work order price fixed or invoiced per logged items like hours and equipment?
     */
    public static isFixedPrice(workOrder: WorkOrderModel): boolean {
        return (workOrder.contractType === WorkOrderContractType.FixedPrice) || (workOrder.contractType === WorkOrderContractType.Warranty);
    }

    /**
     * Is customer approval allowed for the work order or can it be approved only internally?
     * Namely, customer approval is not allowed for fixed price work orders.
     * @param workOrder
     */
    public static allowCustomerApproval(workOrder: WorkOrderModel): boolean {
        return !WorkOrderModel.isFixedPrice(workOrder);
    }
}