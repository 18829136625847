import CostPool from "@/models/server/CostPool";
import {PostAsync} from "@/types/PostAsync";

/**
 * {@link CostPool} provider.
 */
class CostPoolProvider {

    public async getCostPoolsAsync(sender: PostAsync<void, CostPool[]>): Promise<CostPool[]> {
        return await sender.postAsync("api/admin/getCostPools");
    }
}

/**
 * {@link CostPoolProvider} singleton.
 */
export default new CostPoolProvider();