import {GeoLocation} from "@renta-apps/athenaeum-toolkit";
import {ConstructionSiteStatus} from "../Enums"
import OrganizationContract from "./OrganizationContract";
import Organization from "@/models/server/Organization";
import ConstructionSiteAttachment from "@/models/server/ConstructionSiteAttachment";

export default class ConstructionSite {
    public id: string = "";

    public location: GeoLocation | null = null;

    public externalAddress: string = "";

    public name: string = "";

    public externalName: string = "";

    public externalId: string = "";

    public readonly projectNumber: string | null = null;

    public externalReference: string = "";

    public customerInvoiceReference: string = "";

    public hoursPrice: number | null = null;

    public mileagePrice: number | null = null;

    /**
     * Overrides system default AlarmJobPrice.
     */
    public alarmJobPrice: number | null = null;

    public status: ConstructionSiteStatus = ConstructionSiteStatus.Active;

    public organization: Organization | null = null;

    public organizationContractId: string = "";

    public organizationContract: OrganizationContract | null = null;

    public attachments: ConstructionSiteAttachment[] = [];

    public createdAt: Date | null = null;

    public invoiceReference: string = "";

    public isConstructionSite: boolean = true;
}