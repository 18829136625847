import {pageData} from "@/helpers/CypressHelper";

const formName = `Test form definition ${new Date()}`
const basicFormName = `Test basic form definition ${new Date()}`
const workOrderName = `Test work order for form definition ${new Date()}`

context("(Desktop) Forms", () => {

    beforeEach(() => {
        cy.init("Desktop");
    });

    it("Should work correctly for Admin", () => {
        cy.loginAsAdmin();
        test();
    });

    const basicAddFormDefinitionSettings: AddFormDefinitionSettings = {
        formName: basicFormName,
        customType: 'Undefined',
        description: `Test form definition ${new Date()} description`,
        reportingHeaders: ['Customer name'],
        workOrders: ['Enabled'],
        timeslot: 'Anytime',
        required: false,
        multiple: false,
        approvable: false,
        mounterSignaturesRequired: false,
        steps: [
            {
                name: "Test Question step name",
                typeDropdown: 'Question',
                stepTypeSettings: null
            }
        ]
    }

    const addFormDefinitionSettings: AddFormDefinitionSettings = {
        formName: formName,
        customType: 'Undefined',
        description: `Test form definition ${new Date()} description`,
        reportingHeaders: ['Customer name'],
        workOrders: ['Enabled'],
        timeslot: 'Anytime',
        required: false,
        multiple: false,
        approvable: false,
        mounterSignaturesRequired: true,
        steps: [
            {
                name: "Test Question Pictures step name",
                typeDropdown: 'Question Pictures',
                stepTypeSettings: null
            },
            {
                name: "Test Question step name",
                typeDropdown: 'Question',
                stepTypeSettings: null
            },
            {
                name: "Test Pictures step name",
                typeDropdown: 'Pictures',
                stepTypeSettings: null
            },
            {
                name: "Test Checks step name",
                typeDropdown: 'Checks',
                stepTypeSettings: {
                    checkStepItems: [{ checkStepName: "checkStepName", checkStepRequired: true}]
                }
            },
            {
                name: "Test Options step name",
                typeDropdown: 'Options',
                stepTypeSettings: {
                    required: false,
                    multiple: false,
                    comment: false,
                    optionStepNames: ["optionStepName"]
                }
            },
            {
                name: "Test Resource step name",
                typeDropdown: 'Resource',
                stepTypeSettings: {
                    min: 5,
                    max: 10,
                    unlimited: false,
                    step: 7,
                    default: 6
                }
            },
            {
                name: "Test Text step name",
                typeDropdown: 'Text',
                stepTypeSettings: {
                    required: false,
                    rows: 2,
                    minLength: 5,
                    maxLength: 12
                }
            }
        ]
    }

    function test(): void {
        describe("Create and validate form definition", () => {
            cy.addFormDefinition(addFormDefinitionSettings);
            cy.validateFormDefinition(addFormDefinitionSettings);
        });

        describe("Verify if removing step works", () => {
            pageData().addFormDefinitionModal.backButton().click();
            pageData().formDefinitions.formDefinitionsList().children().contains(formName).click();
            pageData().addFormDefinitionModal.steps.stepsContainer().find("tr").should('have.length', addFormDefinitionSettings.steps.length)

            pageData().addFormDefinitionModal.steps.navigation.removeStep(addFormDefinitionSettings.steps.length - 1).click();
            pageData().addFormDefinitionModal.saveButton().click();
            pageData().addFormDefinitionModal.backButton().click();
            pageData().formDefinitions.formDefinitionsList().children().contains(formName).click();

            pageData().addFormDefinitionModal.steps.stepsContainer().find("tr").should('have.length', addFormDefinitionSettings.steps.length - 1);
        });

        describe("Verify if moving step works", () => {
            pageData().addFormDefinitionModal.steps.navigation.moveStepDown(0).click();
            pageData().addFormDefinitionModal.saveButton().click();
            pageData().addFormDefinitionModal.backButton().click();
            pageData().formDefinitions.formDefinitionsList().children().contains(formName).click();

            pageData().addFormDefinitionModal.steps.name(1)
                .should('be.visible')
                .and('have.value', addFormDefinitionSettings.steps[0].name);

            pageData().addFormDefinitionModal.steps.navigation.moveStepUp(3).click();
            pageData().addFormDefinitionModal.saveButton().click();
            pageData().addFormDefinitionModal.backButton().click();
            pageData().formDefinitions.formDefinitionsList().children().contains(formName).click();

            pageData().addFormDefinitionModal.steps.name(2)
                .should('be.visible')
                .and('have.value', addFormDefinitionSettings.steps[3].name);
        });

        describe("Verify if updating form definition works", () => {
            const updatedDescription = `Updated description text  ${new Date()}`;
            const addedReportingHeaders = ["Customer number", "Project name", "Project number"];
            pageData().addFormDefinitionModal.description().clear().type(updatedDescription);
            pageData().addFormDefinitionModal.reportingHeadersDropdown().selectMultiselectDropdownValue(addedReportingHeaders);

            pageData().addFormDefinitionModal.saveButton().click();
            pageData().addFormDefinitionModal.backButton().click();
            pageData().formDefinitions.formDefinitionsList().children().contains(formName).click();

            pageData().addFormDefinitionModal.description()
                .should('be.visible')
                .and('have.value', updatedDescription);

            pageData().addFormDefinitionModal.reportingHeadersDropdown()
                .contains(`${addFormDefinitionSettings.reportingHeaders.length + addedReportingHeaders.length} selected`)
                .should('be.visible');
        });

        describe("Verify if deleting form definition permanently works", () => {
            pageData().addFormDefinitionModal.deleteButton().trigger('click');

            pageData().common.confirmationDialog.dialog()

            cy.get('.athenaeum-confirmation-dialog-opened button.athenaeum-button-color_orange').first().trigger('click', {force: true});
            pageData().formDefinitions.formDefinitionsList().children().contains(formName).should('not.exist');
        });

        describe("Verify if restoring form definition works", () => {
            cy.addFormDefinition(basicAddFormDefinitionSettings);
            cy.validateFormDefinition(basicAddFormDefinitionSettings);

            const addWorkOrderSettings: AddWorkOrderSettings = {
                customerAndSite: "Customer.SubCompanyContractNoExternalId.ConstructionSite",
                status: "New",
                name: workOrderName,
                workOrderType: "Enabled",
                managers: ["Renta.OperatorContract.Manager@automatic.test.rentatask.fi"],
                ordererFullName: "ContactPerson Tester",
                approverFullName: "ContactPerson Tester",
            }

            cy.addWorkOrder(addWorkOrderSettings);

            pageData().workOrder.formsButton().click();

            pageData().formModal.modal().within(() => {
                pageData().formModal.formsListDropdown().selectDropdownValue(basicFormName);
                pageData().formModal.fillButton().click();
                cy.get('#okButton').click();
                cy.get('#cancelFormChangesButton').trigger('click');
            });
            cy.get('.athenaeum-confirmation-dialog-opened button.athenaeum-button-color_orange').first().trigger('click', {force: true});

            cy.navigateToAdminFormDefinitionsPage();
            pageData().formDefinitions.formDefinitionsList().children().contains(basicFormName).click();

            pageData().addFormDefinitionModal.deleteButton().trigger('click');
            cy.get('.athenaeum-confirmation-dialog-opened button.athenaeum-button-color_orange').first().trigger('click', {force: true});

            pageData().addFormDefinitionModal.backButton().click();
            pageData().formDefinitions.topItems.showDeletedCheckbox().click();
            pageData().formDefinitions.formDefinitionsList().find('.form-definition-deleted').contains(basicFormName).should('exist');
            pageData().formDefinitions.formDefinitionsList().children().contains(basicFormName).click();
            pageData().addFormDefinitionModal.restoreButton().click();
            pageData().addFormDefinitionModal.backButton().click();
            pageData().formDefinitions.formDefinitionsList().children().contains(basicFormName).should('exist');
        });

        describe("Verify if copying form definition works", () => {
            pageData().formDefinitions.formDefinitionsList().children().contains(basicFormName)
                .parent('span').siblings('.copy-form-definition').click();

            // wait required for input element to become focused otherwise types only part of string
            cy.wait(1000);
            pageData().addFormDefinitionModal.nameInput().type(addFormDefinitionSettings.formName);
            
            pageData().addFormDefinitionModal.nameInput().type(`Copy of ${basicFormName}`);
            pageData().addFormDefinitionModal.saveButton().click();
            pageData().addFormDefinitionModal.backButton().click();
            pageData().formDefinitions.formDefinitionsList().children().contains(`Copy of ${basicFormName}`).should('exist');
        });
    }
});