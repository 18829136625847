import {ConstructionSiteStatus} from "../../Enums";
import {GeoLocation} from "@renta-apps/athenaeum-toolkit";

export default class SaveConstructionSiteRequest {

    public constructionSiteId: string = "";

    public organizationContractId: string = "";

    public invoiceReference: string = "";

    public mileagePrice: number | null = null;

    public hoursPrice: number | null = null;

    /** @see constructionSite.alarmJobPrice */
    public alarmJobPrice: number | null = null;

    public name: string = "";

    /** Location and address of the construction site.
     *  If null, will remove location from construction site */
    public location: GeoLocation | null = null;

    public externalId: string = "";

    public status: ConstructionSiteStatus = ConstructionSiteStatus.Inactive;
}